import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { PlusCircleFilled, EditFilled, DeleteFilled } from '@ant-design/icons';
import _ from 'lodash';
import {
  Table,
  Infotip,
} from '../../../styleguide';
import CookieForm from './edit/wizard/AddCookies/CookieForm'
import { handleDurationSort, modifiyCookieLifeSpan, calculateCookieAddedVia, reduceCookiesForTableDate } from './edit/wizard/helper';

function CookieConfigTab({
  initialCookies,
  cookieCategories,
  saveCookiesToVl,
  vendorId,
  vendor,
  isNonIab,
  readOnly
}) {
  const [intermediateCookies, setIntermediateCookies] = useState([])
  const [form] = Form.useForm();
  const [purposesTreeData, setPurposesTreeData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [cookies, setCookies] = useState(
    initialCookies.map((c) => ({
      ...c,
      vendorId,
      added_via: calculateCookieAddedVia(c)
    }))
  );
  const [isEditFlow,setIsEditFlow] = useState(false);
  const [cookiesToEdit,setCookiesToEdit] = useState([]);
  const [submitBtnType, setSubmitBtnType] = useState('submit');
  const [cachedCookies, setCachedCookies] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [purposeOptions, setPurposeOptions] = useState([]);

  const initialFormValues = { 
    cookieName: '',
    vendor: vendorId,
    domain: '',
    purposes: [],
    duration : {
      number: '',
      suffix: 'day'
    }
  }

  useEffect(()=>{
    const data = reduceCookiesForTableDate(cookies);
    const updatedInitialCookies = Object.keys(data).map(key => {
      return data[key];
    })

    const treeData = {
      title: 'All',
      value: 'all',
      children: []
    }
    cookieCategories.forEach((purpose) => {
      treeData.children.push({'title': purpose.name, 'value': purpose.name, 'type': purpose.type, 'iabPurposeRef': purpose.iabPurposeRef})
    });

    const modifyCookieCategories = cookieCategories.length === 0 ? cookieCategories : [treeData];
    if(initialCookies.length > 0) {
      const filtersInfo = cookieCategories.map((category) => {
        return {text: category.name, value: category.name}
      })
      setFilters(filtersInfo);
    }
    setPurposesTreeData(modifyCookieCategories);
    setIntermediateCookies(updatedInitialCookies);
  },[cookies, cookieCategories,initialCookies])  



  const showModal = (record, row, index) => {
    setCachedCookies(cookies);
    const cookiesToDelete = cookies.filter(cookie => cookie.name === row.name && row.categories.includes(cookie.category));
    const updatedCookies = cookies.filter(cookie =>  !(cookie.name === row.name && row.categories.includes(cookie.category)))
    setCookies(updatedCookies)
    form.setFieldsValue({ 
      cookieName: row.name,
      vendor: vendorId,
      domain: row.domain,
      purposes: row.categories,
      duration : {
        number: row.cookieLifeSpan.split(' ')[1],
        suffix:  row.cookieLifeSpan.split(' ')[0]
      }
    })
    setIsEditFlow(true);
    setCookiesToEdit(cookiesToDelete);
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setCookies(cachedCookies);
    form.setFieldsValue(initialFormValues);
  };


  const addCookie = () => {
    setIsEditFlow(false);
    setIsModalVisible(true);
    setCachedCookies([...cookies]);
  };

  const deleteCookie = (value,row,index) => () => {
    const cookiesToDelete = cookies.filter(cookie => cookie.name === row.name && cookie.added_via === row.added_via && row.categories.includes(cookie.category));
    const updatedCookies = cookies.filter(cookie =>  !(cookie.name === row.name && cookie.added_via === row.added_via && row.categories.includes(cookie.category)))

    setCookies(updatedCookies);
    saveCookiesToVl(updatedCookies, cookiesToDelete);
  };

  const injectProps = (Component, field) => (text, row, index) => {
    return (
      <Component
        value={text || ''}
        deleteCookie={deleteCookie(text,row,index)}
        showModal={showModal}
        row={row}
        index={index}
      />
    )
  }
  const handleAlphabeticalSort = (a, b, key) =>{
    const first = a[key].toUpperCase();
    const second = b[key].toUpperCase();

    if (first < second) return -1;
    if (first > second) return 1;
    return 0;
  }
  const columns = [
    {
      title: 'Cookie',
      dataIndex: 'name',
      width: '15%',
      sorter: (a, b) => handleAlphabeticalSort(a, b, 'name'),
      render: injectProps(CookieInput, 'name'),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      width: '19%',
      render: injectProps(CookieInput, 'domain'),
    },
    {
      title: 'Purpose',
      dataIndex: 'categories',
      width: '26%',
      filters: filters,
      onFilter: (value, record) => record.categories.includes(value),
      render: injectProps(Category, 'categories'),
    },
    {
      title: 'Duration',
      dataIndex: 'cookieLifeSpan',
      width: '15%',
      sorter: (a, b) => handleDurationSort(a, b, 'cookieLifeSpan'),
      render: injectProps(Duration, 'cookieLifeSpan'),
    },
    {
      title: 'Added Via',
      dataIndex: 'added_via',
      width: '17%',
      align: 'left',
      filters: [
        {
          text: 'Manual',
          value: 'Manual',
        },
        {
          text: 'Scan Results',
          value: 'Scan Results',
        },
        {
          text: 'IAB Declared',
          value: 'IAB Declared',
        },
      ].filter( f => !(isNonIab && f.value === 'IAB Declared')),
      onFilter: (value, record) => {return record ? record.added_via === value : false;},
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: '',
      dataIndex: 'update',
      width: '8%',
      render: !readOnly ? injectProps(Update) : null,
    }
  ]

  const submitHandler = (values) => {
    let cookiesData = cookies;
    let newCookiesData = [];
    let combinedCookies = [];
    if(isEditFlow) {
      saveCookiesToVl(cookiesData,cookiesToEdit);
    } 
    const purposes = values.purposes.includes('all') ? cookieCategories.filter( c => purposeOptions[0].children.map(purpose => purpose.value).includes(c.name)) : cookieCategories.filter((purpose) => 
     values.purposes.includes(purpose.name) 
    )
  
    purposes.forEach(purpose => {
      newCookiesData.push({
        // key: Math.random(),
        // id: Math.random(),
        category: purpose.name,
        name: values.cookieName,
        cookieLifeSpan: values.duration.suffix === 'session' ? 'session' : `${values.duration.suffix} ${values.duration.number}`,
        domain: values.domain,
        vendorId: vendorId,
        added_via: 'Manual',
        gvlDefined: false,
        fromScan: false,
        manuallyAdded: true,
      })
    })

    combinedCookies = [
      ...cookiesData,
      ...newCookiesData
    ]
    
    saveCookiesToVl(combinedCookies)
    setCookies(combinedCookies);
    setIsModalVisible(false);
    form.setFieldsValue(initialFormValues);
  }
  return (
    <div className="cookies-config-table-container">
      <Table
        locale={{ emptyText: "You haven't added any cookies" }}
        pagination={false}
        scroll={{ y: 300 }}
        dataSource={intermediateCookies}
        columns={columns}
        id='cookies-config-table'
      />
      <div className="addremove-item-container">
      {!readOnly ? (
          <div
            className={`icon`}
            onClick={addCookie}
            style={{ color: "#411F90" }}
          >
            <PlusCircleFilled style={{ color: "#411F90" }} />
            <div>Add Cookie</div>
          </div>
        ) : null}
      </div>
      {isModalVisible && 
      <Modal
        visible={isModalVisible}
        getContainer={document.getElementById('cookies-config-table')}
        footer={null}
      >
        <CookieForm 
          form={form} 
          cancel
          buttonTitle='Save'
          isDisabled 
          type='primary'
          setSubmitBtnType={setSubmitBtnType}
          onFinish={submitHandler} 
          onCancel={handleCancel} 
          initialValues={initialFormValues}
          selectedVendors={[vendor]}
          purposes={purposesTreeData}
          selectedCookies={cookies}  
          purposeOptions={purposeOptions}
          setPurposeOptions={setPurposeOptions}
        />
      </Modal>}
    </div>
  );
}

function Category({
  value,
}) {
    return (
      <div className='cookie-category'>
        <div>
          <span>{value[0]}</span>&nbsp;
          <Infotip
            content={() => value.map(item => <><p key={item}>{item}</p></>)}
            trigger="click"
            placement="bottomRight"
            className='vendor-list-wizard'
          >
            {value.length > 1 && <span className='display-count'>{value.length > 1 ? `+${value.length - 1}` : ''}</span>}
          </Infotip>
        </div>
      </div>
    );
}

function CookieInput({
  value,
}) {
  return (
    <div className="input-container">
      <span>{value}</span>
    </div>
  )
}

function Duration({
  value,
}) {
  const updatedValue = value && modifiyCookieLifeSpan(value)
  return <span>{updatedValue}</span>
}

function Update({
  deleteCookie,
  showModal,
  value,
  row,
  index
}) {
  return (
    <div className="update-container">
      {row.added_via !== 'IAB Declared' && row.added_via !== 'Scan Results' && <EditFilled className="cookie-table-edit-icon" onClick={()=> showModal(value,row,index)} />}&nbsp;
      {row.added_via !== 'IAB Declared' && <DeleteFilled className="cookie-table-edit-icon" onClick={deleteCookie} />}
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
  }
}
export default connect(mapStateToProps)(CookieConfigTab);
