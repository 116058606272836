import React,{useState} from 'react';
import { Tag, Modal } from 'antd';
import { Table } from '../../../../styleguide'
import moment from 'moment';
import {
    BACKEND_STATUS_TYPES,
  } from '../../../../constants';
import { browserHistory } from 'react-router';
import { Map } from 'immutable';
import { ViewMessage } from '../../../common/ViewMessage';
import { keyByVal, getParameterByName } from '../../../utils.js';

function MessageDetails({ messages, currentUser, propertieIdsUnderPg }) {

const [showPreview,setShowPreview] = useState(false)
const [previewMessage,setPreviewMessage] = useState(Map())

const siteId = parseInt(getParameterByName('site_id', window.location));

const showPreviewMessage = async (e, index) => {
    setShowPreview(true)
    setPreviewMessage(Map(messages[index]));
}
  
  const dataSource = messages && messages.length ? messages.map(msg => {
    return {
            name: msg?.description,
            id: msg?.id,
            status: keyByVal(BACKEND_STATUS_TYPES, msg?.status),
            createdDate:moment(msg.created_at).format('YYYY-MM-DD'),
        };
    }) : [];

  const columns = [
    {
      title: 'Message name',
      dataIndex: 'name',
      key: 'name',
      render: (text,message,index) => <span><a onClick={(e) => showPreviewMessage(e, index)}>{text}</a></span>
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const realStatus = status.toUpperCase();
        const statusTag = realStatus === "PUBLIC" || realStatus === "STAGE" ? (
          <Tag color="#3FCC97">{realStatus}</Tag>
        ) : realStatus === 'DRAFT'? (
          <Tag>{realStatus}</Tag>
        ) : (<Tag color="#FFC107">{realStatus}</Tag>);

        return (
            (<div className="status-container">
                <div>
                {statusTag}
                </div>
            </div>)
          );
    }
    },
    {
      title: 'Date created',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text) => <span>{text}</span>
    },
];

  return <React.Fragment>
         <Table 
           className="campaigns-partition-details" 
           title={() => 'Message Details'} 
           pagination={false} 
           dataSource={dataSource} 
           columns={columns} />
           {showPreview && (
            <Modal
              centered
              onCancel={() => setShowPreview(false)}
              title={<h3>{previewMessage.get('description')}<span className='meesage-title-verticle-line'> | </span><span className='message-id'>ID: {previewMessage?.get("id")}</span></h3>}
              footer={null}
              visible={showPreview}
              wrapClassName="scenario-modal-wrapper"
            >
              <ViewMessage
                viewMsg={previewMessage}
                siteId={siteId}
                propertieIdsUnderPg={propertieIdsUnderPg}
              />
            </Modal>
          )}
    </React.Fragment>
}

export default MessageDetails;