import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from 'react-router';

import { 
  getAccountList,
  cloneRegulationList,
  deleteRegulationList,
  activateDeactivateRegulationList,
  clearRegulationError
} from '../../../../actions/us_privacy_regulation_actions';
import { useActions } from "../../../../hooks";
import RegulationListGeneric from "../../vendor_list_v2/RegulationListGeneric";
import { hasFeature } from "../../../utils";
import FrameworkSelectionModal from "./FrameworkSelectionModal";

const USPrivacyRegulationList = (props) => {
  const [openFrameworkSelectionModal, setOpenFrameworkSelectionModal] = useState(false);
  const getUSPrivacyAccountsList = useActions(getAccountList);
  const cloneRegulation = useActions(cloneRegulationList);
  const deleteRegulation = useActions(deleteRegulationList);
  const activateDeactivateRegulation = useActions(activateDeactivateRegulationList);
  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value'])) ?? {};
  const sites = useSelector(state => state.siteState.getIn(['sites', 'value']))?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-'));
  const siteGroups = useSelector(state => state.siteState.getIn(['siteGroups', 'value']))
  const error = useSelector(state => state.usPrivacyReducerState.getIn(['vendorList', 'error']));
  const clearRegulationErrorAction = useActions(clearRegulationError);
  const accountsList = useSelector(state => state.usPrivacyReducerState.getIn(['vendorList', 'value']))?.filter((reg) => !(reg?.legislation === "state" && !reg?.nonIab));
  const vendorListsPending = useSelector( state => state.usPrivacyReducerState.getIn(['vendorList', 'pending']))
  const editUSPRegulation = (regulationId) => {
    browserHistory.push(`/us_privacy/regulations/edit?regulationId=${regulationId}`);
  };

  const [tableStates, setTableStates] = useState({
    sorter: {},
    filters: {},
    pagination: {}
  });

  const calculateAccess = (sites, vlSites) => {
    return false;
    // if (currentUser?.allSiteAccess) return false;
    // const siteSet = new Set(sites);
    // let viewOnly = false;
    // vlSites.forEach(s => {
    //   if (!siteSet.has(s)) viewOnly = true;
    // });
    // return viewOnly;
  };

  
  useEffect(() => {
    getUSPrivacyAccountsList()
  },[])

  const handleCreate = (framework) => {
    browserHistory.push(`/us_privacy/regulations/create?framework=${framework}`);
  }

  let readOnly = false;
  if (currentUser.featureAccess &&
    !currentUser.featureAccess.includes('Vendor List - U.S. Multi-State')) {
    readOnly = true;
  }

  const isAccountPartOfWave1 = true;

  return (
    <>
      <RegulationListGeneric
        currentUser={currentUser}
        vendorLists={accountsList}
        vendorListError={error}
        sites={sites}
        siteGroups={siteGroups}
        vendorListsPending={vendorListsPending}
        usnat={props.route.usnat}
        nonIab={props.route.nonIab}
        readOnly={readOnly}
        calculateAccess={calculateAccess}
        entity={"Regulation"}
        handleCreate={() => {setOpenFrameworkSelectionModal(true)}}
        handleEdit={editUSPRegulation}
        cloneVendorList={cloneRegulation}
        deleteVendorList={deleteRegulation}
        clearVendorListError={clearRegulationErrorAction}
        activateDeactivateVendorList={activateDeactivateRegulation}
        isAccountPartOfWave1={isAccountPartOfWave1}
        tableStates={tableStates}
        setTableStates={setTableStates}
      />
      {openFrameworkSelectionModal ? (
        <FrameworkSelectionModal
          isModalOpen={openFrameworkSelectionModal}
          handleCreate={handleCreate}
          onCancel={() => setOpenFrameworkSelectionModal(false)}
        />
       ): null}
    </>
  )
}

export default USPrivacyRegulationList
