import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from "react-router";
import { Typography } from 'antd'
import { Input, Headline, Button, Infotip, Radio, message, SelectDropdown, Tabs } from '../../../styleguide';
import { InfoCircleFilled, InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { getAccountPermissions } from '../../../actions/account_actions';
import { getScheduledJobs, getJobDefinitions, createScheduledJob, updateScheduledJob,getJobDoneOnceReport } from '../../../api/custom_report';
import Loading from '../../common/Loading.js.jsx';
import { GEO_FILTER_LIST_DETAILS, OS_FAMILY_LIST, BROWSERS_LIST, DEVICES_LIST,US_STATES } from '../../../constants';
import moment from 'moment';
import PropertySelection from '../../common/PropertySelection';
import RegionSelector from '../../common/RegionSelector/RegionSelector.jsx';
import EntitySelector from '../../../styleguide/components/EntitySelector';
import AntdDatePicker from '../../common/AntdDatePicker.jsx';

const { TabPane } = Tabs;


const report_count_monthly_limit = 5;


const dayMap = {
  WEEKLY: [...Array(8).keys()].slice(1),
  MONTHLY: [...Array(32).keys()].slice(1),
  QUARTERLY: [...Array(93).keys()].slice(1)
};

const filterKeys = [
  {"US Privacy Performance (CCPA)":"US Privacy (Legacy)"},
  {"GDPR Performance (TCF v2)":"GDPR"},
  {"US Privacy Performance (US NAT)" : "US Multi State Privacy"}
]

const { RangePicker } = AntdDatePicker;

export class CreateReport extends React.Component {
  state = {
    loading: true,
    saving: false,
    type: 'create',
    originalFrequency: null,
    form: {
      report_name: '',
      user_email: [],
      user_name: 'rtdp_svc_user',
      job_def_id: null,
      day_limit:1,
      inclusion_filters: {
        geo:[],
        site_id:[],
        ua_device:[],
        ua_browser:[],
        ua_os:[],
        state_code:[],
        country_code:[],
      },
      group_by: [],
      frequency: {
        type: 'ONCE',
        lookback: null,
        day: null
      },
      args: {
        start_date: null,
        end_date: null
      }
    },
    errors: {
      report_name: null,
      user_email: null,
      job_def_id: null,
      frequency: null
    },
    report: {},
    definitions: {},
    error:null,
    report_count:0,
    dateRangeType: null,
    pickerFormat: "date",
    indexSiteTypeMap: this.props.sites.toJS().reduce((map, obj) => (map[obj.id] = obj.type, map), {}),
  }
  componentDidMount = async () => {
    const accountId = this.props.currentUser?.accountId;
    const userName = this.props.currentUser?.name;
    this.props.getAccountPermissions();
    const definitions = await getJobDefinitions(accountId);
    const count = await getJobDoneOnceReport(accountId);
    
    let report = this.state.form;
    report.user_name = this.props.currentUser?.name || 'rtdp_svc_user';
    if (this.props.params?.reportId !== 'new') {
      report = await this.loadReportInfo();
      report.user_email = Array.isArray(report.user_email) ? report.user_email : report.user_email?.split(',');
      report.inclusion_filters = report.inclusion_filters || this.state.form.inclusion_filters;
      report.frequency = report.frequency || this.state.form.frequency;
      report.group_by = (report.group_by || this.state.form.group_by).map(g => g.replace(/pv\_status\./ig, ''));
      report.day_limit = report.day_limit || this.state.form.day_limit
      if(!report.inclusion_filters.hasOwnProperty('site_id')){
        report.inclusion_filters.site_id = this.props.sites.toJS().map((s) => s.id);
      }
    }
    if (report.args) {
      report.args.account_id = accountId;    
    } else {
      report.args = { account_id: accountId };    
    }
    this.setState({ 
      definitions, form: report, loading: false, 
      type: this.props.params?.reportId !== 'new' ? 'edit' : 'create',
      originalFrequency: report?.frequency?.type,
      report_count:count,
      dateRangeType: report.frequency.type === 'ONCE' ? 'custom' : 'dynamic'
    });
  }
  loadReportInfo = async (reportId) => {
    const accountId = this.props.currentUser.accountId;
    const report = await getScheduledJobs(accountId, this.props.params?.reportId);
    return report;
  }
  onChange = (field, value) => {
    const form = {...this.state.form};
    form[field] = value;

    const {errors} = this.state;
    errors[field] = null;
    this.setState({ form, errors });

  }
  onChangeFrequency = (field, value, dayLimit) => {
    const form = {...this.state.form};
    form.frequency[field] = value;
    if(field === 'type'){
      form.frequency.lookback = null;
      form.frequency.day = null;
    }
    if(value === 'WEEKLY'){
      form.frequency.lookback = "PRIOR_7_DAYS";
      form.frequency.day = 2;
    }else if(value === 'MONTHLY'){
      form.frequency.lookback = "PRIOR_MONTH";
      form.frequency.day = 1;
    }
    form['day_limit'] = dayLimit;
    this.setState({ form });
  };
  onChangeInclusion = (field, value) => {
    const form = {...this.state.form};
    form.inclusion_filters[field] = value;  
    this.setState({ form });
  };
  createReport = async () => {
    this.setState({ saving: true });
    try {
      if (!this.validation()) {
        this.setState({ saving: false })
        return;
      }
      const formData =  JSON.parse(JSON.stringify(this.state.form));
      formData.user_email = formData.user_email.join(',');
      const actualDefinitions = this.state.definitions[formData.job_def_id];
      const group_by_keys = actualDefinitions.group_by.map(d => d.key)
      const inclusion_filter_keys = actualDefinitions.inclusion_filters.map(i => i.key);
      const groupByCampaigntype = formData.group_by.filter(f => group_by_keys.includes(f))
      const inclusionFiltersByCampaigntype = Object.keys(formData.inclusion_filters).filter((key) => inclusion_filter_keys.includes(key)).reduce((cur, key) => { return Object.assign(cur, { [key]: formData.inclusion_filters[key] })}, {});
      formData.group_by = groupByCampaigntype;
      formData.inclusion_filters = inclusionFiltersByCampaigntype;
      if(formData.inclusion_filters.site_id.length === this.props.sites?.size){
        delete formData.inclusion_filters.site_id
      }
      const creating = await createScheduledJob(formData);
      if (creating?.error || !creating) throw new Error(creating.msg);
      message.success('Scheduled job successfully created');
      browserHistory.push('/manage_reports')
    } catch (err) {
      message.error(err?.message || 'Error creating report, try again');
      this.setState({ saving: false });
    }
  }
  saveReport = async () => {
    this.setState({ saving: true });
    try {
      if (!this.validation()) {
        this.setState({ saving: false })
        return;
      }
      const formData =  JSON.parse(JSON.stringify(this.state.form));
      const actualDefinitions = this.state.definitions[formData.job_def_id];
      const group_by_keys = actualDefinitions.group_by.map(d => d.key)
      const inclusion_filter_keys = actualDefinitions.inclusion_filters.map(i => i.key);
      const groupByCampaigntype = formData.group_by.filter(f => group_by_keys.includes(f))
      const inclusionFiltersByCampaigntype = Object.keys(formData.inclusion_filters).filter((key) => inclusion_filter_keys.includes(key)).reduce((cur, key) => { return Object.assign(cur, { [key]: formData.inclusion_filters[key] })}, {});
      formData.group_by = groupByCampaigntype;
      formData.inclusion_filters = inclusionFiltersByCampaigntype;
      formData.user_email = formData.user_email.join(',');
      if(formData.inclusion_filters.site_id.length === this.props.sites?.size){
        delete formData.inclusion_filters.site_id
      }
      const saving = await updateScheduledJob(formData);
      if (saving?.error || !saving) throw new Error(saving.msg);
      message.success('Scheduled job successfully saved');
      this.setState({ saving: false });
    } catch (err) {
      message.error(err?.message || 'Error saving report, try again');
      this.setState({ saving: false });
    }
  }
  validation = () => {
    const { form } = this.state;
    let errors = {};
    // let errors = null;
    if(form.frequency.type && form.frequency.type ==='ONCE' && (form.args.end_date === null || form.args.start_date === null)) errors.frequency = 'Start and End date are required'; 
    let diffInRange = moment(form.args.end_date).diff(moment(form.args.start_date), 'days');

    if (!form.job_def_id) errors.job_def_id = 'Campaign Type is required';
    if (form.user_email?.length <= 0) errors.user_email = 'Must select at least 1 recipient';
    if (form.report_name?.length > 60) errors.report_name = 'Report name cannot be more than 60 characters';
    if (!form.report_name) errors.report_name = 'Report name is required';
    if(!form.frequency.type) errors.frequency = 'Date Range is required';
    if (form.report_name?.match(/^[^a-zA-Z0-9]+$/)) errors.report_name = 'Report must contain more than special characters';
    if (this.state.type === 'edit' && this.state.originalFrequency !== 'ONCE' && form?.frequency?.type === 'ONCE') {
      errors.frequency = 'Cannot change a report scheduled to Once Only from a different frequency';
    }
    if(form.frequency.type && form.frequency.type ==='ONCE' && diffInRange > form.day_limit) errors.frequency = '31 days is the maximum date range for this type of report'
    if(form.frequency.type && !form.frequency.lookback && form.frequency.type!=='ONCE') errors.frequency = 'Relative Date is required';
    if(form.frequency.lookback && !form.frequency.day && form.frequency.type!=='DAILY') errors.frequency = 'ScheduleDate is required '
    if(form.frequency.type && form.frequency.type ==='ONCE' && this.state.report_count >= report_count_monthly_limit ) errors.frequency = 'You ran into your account level limit for reporting this month. For more details please contact your Sourcepoint Account Manager.'
    // if (errors) message.error(errors);
    this.setState({errors})
    if(Object.keys(errors).length === 0){
      return true;
    } else {
      message.error("Please resolve all errors before saving")
      return false;
    }
  }
  rangeChange = (dates, dayLimit) => {
    const form = {...this.state.form};
    let startDate = null;
    let endDate = null;
    if(this.state.pickerFormat === 'month'){
      startDate = dates ? moment.utc(dates[0]).startOf('month').format('YYYY-MM-DD') : null;
      endDate = dates ? moment.utc(dates[1]).endOf('month') : null;
      endDate = endDate?.isAfter(moment.utc()) ? moment.utc().format('YYYY-MM-DD') : endDate?.format('YYYY-MM-DD');
    } else {
      startDate = dates ? moment(moment.utc(dates[0])).format('YYYY-MM-DD'): null;
      endDate =  dates ? moment(moment.utc(dates[1])).format('YYYY-MM-DD') : null;
    }
    form.args.start_date = startDate;
    form.args.end_date = endDate;
    let diffInRange = moment(form.args.end_date).diff(moment(form.args.start_date), 'days');
    this.setState({error:(diffInRange> 31)? 'Please select a time range lower than 31 days to run this report': null })
    form['day_limit'] = dayLimit;

    const {errors} = this.state;
    errors.frequency = null;
    if(!this.state.error){
      this.setState({ form, errors });
    }
  }
  renderToolTip = type => {
    let messageObj = {
      'ONCE': <>Please note that the date range limit for this report type is up to 31 days</>,
      'WEEKLY': <>A report will be sent to the supplied email/s on <b>Monday of each week</b> with a lookback window of the previous 7 days from Monday to Sunday</>,
      'MONTHLY': <>A report will be sent to the supplied email/s on the <b>1st day of the month</b> containing data corresponding to the previous month.</>,
    }
    return(
      <div className="tip">
        {messageObj[type]}
      </div>
    )
  }

  disabledDate = (current) => {
    return (current && current > moment.utc().subtract(1,'days')) || (current && current < moment("2022-10-1").utc());
  };

  onChangeRadio = (e) => {
    this.setState({...this.state, pickerFormat: e.target.value});
  };

  extraFooter = () => {
    return (
      <div>
        <Radio.Group
          onChange={this.onChangeRadio}
          defaultValue={this.state.pickerFormat}
          size="small"
        >
          <Radio.Button value="date">Date</Radio.Button>
          <Radio.Button value="month">Month</Radio.Button>
        </Radio.Group>
      </div>
    );
  };

  customPanelRender = (originalPanel) => {
    return <div className="picker-diagnose">{originalPanel}</div>;
  };

  handleDateRangeTypeChange = (value, dayLimit) => {
    this.setState({...this.state, dateRangeType : value});
    this.onChangeFrequency('type', value === 'custom' ? 'ONCE' : 'WEEKLY', dayLimit)
  }

   /**
   * to fetch only object for GDPR TCFv2 and US Privacy
   */
   filterBasedOnCampaignType =  (definitions) => {
    const fixedFilters = filterKeys.map((val) =>  Object.keys(val)).flat()
    const filteredDefinitions = Object.keys(definitions)
    .filter(key => fixedFilters.includes(definitions[key].campaign_type))
    .reduce((obj,key) => {
      obj[key] = definitions[key];
      return obj;
    },{})
    return filteredDefinitions;
  }

  getFilterLabel = (campType) => {
    const filterDetails = filterKeys.find(val => val[campType])
    if(filterDetails[campType]){
      return filterDetails[campType];
    }else{
      return campType
    }
  }

  getFilterOptions = (key) => {
    const { sites } = this.props;
    const siteList = sites?.toJS() || [];
    if(key === 'site_id'){
      return siteList.map(l => {
        return { name: l.domain, value: l.id };
      })
    }else if(key === 'geo'){
      return GEO_FILTER_LIST_DETAILS.filter(cnt => cnt.type === 'country').map(cnt => {
        return { name:cnt.name , value: cnt.code };
      }).toJS()
    }else if(key === 'country_code'){
      return GEO_FILTER_LIST_DETAILS.filter(cnt => cnt.type === 'country').map(cnt => {
        return { title:cnt.name , code: cnt.code, key: cnt.code };
      }).toJS()
    }else if(key === "state_code"){
      return US_STATES.filter(cnt => cnt.type === 'state').map(cnt => {
        return { title:cnt.name , code: cnt.code, key: cnt.code };
      }).toJS()
    }else if(key === "ua_device"){
      return DEVICES_LIST.map(cnt => {
        return { title: cnt , code: cnt, key: cnt };
      }).toJS()
    }else if(key === "ua_browser"){
      return BROWSERS_LIST.map(cnt => {
        return { title: cnt , code: cnt, key: cnt };
      }).toJS()
    }else if(key === "ua_os"){
      return OS_FAMILY_LIST.map(cnt => {
        return { title: cnt , code: cnt, key: cnt };
      }).toJS()
    }
  }

  createFilters = (form,actualDefinitions) => {
    return actualDefinitions?.inclusion_filters.map(f => {
      const key = f?.key;
      const display_name = f?.display_name;
      const options = this.getFilterOptions(key);
      const key_pair = ['site_id','geo','country_code','state_code']
      let content = null;
      if(key === 'site_id') {
        content = (
          <PropertySelection
            selectedPropertiesKeys={form.inclusion_filters['site_id'] ?? []}
            setSelectedPropertiesKeys={val => this.onChangeInclusion('site_id', val)}
            sites={this.props.sites}
            siteGroups={this.props.siteGroups}
          />
        );
      } else if(key === 'geo') {
        content = (
          <RegionSelector
            isIncludeExcludeStructure={false}
            setGeoScope={() => {}}
            appliesGeos={[]}
            exclusionGeos={[]}
            updatedCodes={(val) => this.onChangeInclusion('geo', val)}
            codes={form.inclusion_filters['geo'] ?? []}
            entity={display_name}
          />
        );
      } else {
        content = (
          <EntitySelector
            options={options}
            updatedCodes={(val) => this.onChangeInclusion(key, val)}
            codes={form.inclusion_filters[key] ?? []}
            entity={display_name}
          />
        )
      }
      return( 
        <TabPane tab={display_name} key={key}>
          {content}
        </TabPane>
      )
    })
  }

  handleCampaignTypeChange = (val) => {
    const form = {...this.state.form};
    form["job_def_id"] = val;
    form["group_by"] = [];
    this.setState({ form });
  };

  render() {
    const { definitions, loading, form, saving } = this.state;
    const { users, sites, currentUser } = this.props;
    const filteredDefinitions = this.filterBasedOnCampaignType(definitions)
    if(location.pathname== "/manage_reports/new" && !form.job_def_id && Object.keys(filteredDefinitions)[0]){
      this.handleCampaignTypeChange(parseInt(Object.keys(filteredDefinitions)[0]))
    }
    const userList = currentUser?.isAdmin ? users?.toJS() : [currentUser];
    const actualDefinitions = definitions[form.job_def_id];
    const selectedFrequency = actualDefinitions?.frequencies?.find(f => f.type === form.frequency?.type);
    const lookbacks = selectedFrequency?.lookbacks || [];
    const days = dayMap[selectedFrequency?.type] || [];
    const isEditing = this.state.type === 'edit';
    const frequencyType = form.frequency?.type
    const dayLimit = actualDefinitions?.day_limit;
    const tooltipTextFilter = "Filter your report by dimension by updating the selection in the fields below. By default, we have selected 'All' options for each field."
    const tooltipTextReport = "Select one or more options below to define the desired output for the report. Each box you check will breakdown the rows in the report by that attribute. Keeping all boxes unchecked will produce a simple summary report across your selected filters.";
    const remainging_reports = Math.max(report_count_monthly_limit - this.state.report_count, 0)
    const disableReportRun = form.frequency.type && form.frequency.type ==='ONCE' && (this.state.error || remainging_reports <= 0);

    if(form?.inclusion_filters?.site_id?.some(siteId => this.state.indexSiteTypeMap[siteId] == 'app')){
      //Remove the breakdown for devices if any app properties are added to the report filter for property.
      form.group_by = form.group_by?.filter(groupBy => groupBy !== "ua_device");
      actualDefinitions.group_by = actualDefinitions?.group_by?.filter( groupBy => groupBy.key !== "ua_device");
    } else if(actualDefinitions && !actualDefinitions?.group_by.some(groupBy => groupBy.key === "ua_device")){
      actualDefinitions.group_by?.push({key: 'ua_device', display_name: 'Devices'})
    }

    return loading ? (
      <Loading />
    ) : (
      <div className="request-v2">
        <div className="request-actions">
          <Link to="/manage_reports">
            <Button type="secondary">Cancel</Button>
          </Link>
          <Button
            type="primary"
            loading={saving}
            onClick={
              this.state.type === "create" ? this.createReport : this.saveReport
            }
            disabled={disableReportRun}
          >
            Run this report
          </Button>
        </div>

        <Link to="/manage_reports" className="back-link">
          <LeftOutlined /> Back to Manager Reports{" "}
        </Link>
        <Headline type="oswald">SCHEDULED REPORTING</Headline>
        <p>Setup a custom report to have sent to your inbox</p>

        {/* MAIN REPORT */}
        <div className="main-report">
          {/* REPORT NAME & RECIPIENTS */}
          <div className="report-section">
            <div className="headline">Basic Settings:</div>
            <div>
              <div className="section-item">
                <div className="title">
                  Report Name: <span className="error">*</span>
                </div>
                <Input
                  value={form.report_name}
                  onChange={(e) => this.onChange("report_name", e.target.value)}
                  placeholder="Report name..."
                  maxLength={255}
                  showCount={true}
                  error={this.state.errors.report_name}
                />
              </div>
              <div className="section-item">
                <div className="title">
                  Recipient(s): <span className="error">*</span>
                </div>
                <SelectDropdown
                  selectAll
                  multiple
                  showSearch
                  showArrow
                  directUpdate
                  entityName="Recipients(s)"
                  value={form.user_email}
                  onChange={(value) => this.onChange("user_email", value)}
                  options={userList?.map((u) => ({
                    label: u.email,
                    value: u.email,
                  })) ?? []}
                  error={this.state.errors.user_email}
                />
              </div>
            </div>
            <div>
              <div className="section-item">
                <div className="title">
                  Campaign Type: <span className="error">*</span>
                </div>
                <div className="sub-title">Select campaign type</div>
                <Radio.Group
                  value={form.job_def_id}
                  onChange={({ target: { value } }) => {this.handleCampaignTypeChange(value);}}
                  disabled={this.props.params?.reportId !== "new"}
                >
                  {Object.keys(filteredDefinitions).map((d) => {
                    const campType = definitions[d].campaign_type;
                    return (
                      <Radio value={parseInt(d)}>
                        {this.getFilterLabel(campType)}
                      </Radio>
                    );
                  })}
                  </Radio.Group>
                  <Typography.Text type='danger'>{this.state.errors.job_def_id}</Typography.Text>
              </div>
            </div>
          </div>
          {this.state.form.job_def_id ? (
            <>
              <div className="report-section">
                <div className="headline">
                  Report Filters{" "}
                  <Infotip content={tooltipTextFilter} placement="right" className="maxWidth">
                    <InfoCircleFilled style={{ marginLeft: "5px" }} />
                  </Infotip>
                </div>
                <div className="report-filters">
                  <Tabs defaultActiveKey="1" className="spsg-normal ">
                    {this.createFilters(form, actualDefinitions)}
                  </Tabs>
                </div>
              </div>
              <div className="report-section">
                <div className="headline">
                  Break Down Report By{" "}
                  <Infotip content={tooltipTextReport} placement="right" className="maxWidth">
                    <InfoCircleFilled style={{ marginLeft: "5px" }} />
                  </Infotip>
                </div>
                <div>
                  <SelectDropdown
                    selectAll
                    multiple
                    directUpdate
                    value={Object.values(form.group_by)}
                    onChange={(value) => {
                      let groupBy = [...new Set(value)];
                      this.onChange("group_by", groupBy);
                    }}
                    options={
                      actualDefinitions?.group_by.map((g) => ({
                        label: g.display_name,
                        value: g.key,
                      })) ?? []
                    }
                  />
                </div>
              </div>{" "}
            </>
          ) : null}
          <div className="report-section">
            <div className="headline">Date Range: <span className="error">*</span></div>
            {
              <div className="frequency">
                <Radio.Group
                  className="spsg-radio-group"
                  value={this.state.dateRangeType}
                  onChange={({ target: { value } }) =>
                    this.handleDateRangeTypeChange(value, dayLimit)
                  }
                >
                  <Radio.Button key="custom" value="custom">
                    Custom
                  </Radio.Button>
                  <Radio.Button key="dynamic" value="dynamic">
                    Dynamic
                  </Radio.Button>
                </Radio.Group>
              </div>
            }

            {actualDefinitions?.frequencies &&
            this.state.dateRangeType === "dynamic" ? (
              <div className="frequency">
                <Radio.Group
                  className="spsg-radio-group"
                  value={form.frequency.type}
                  onChange={(e) =>
                    this.onChangeFrequency("type", e.target.value, dayLimit)
                  }
                  optionType="default"
                >
                  {actualDefinitions?.frequencies
                    .filter(
                      (f) =>
                        f.type !== "DAILY" &&
                        f.type !== "QUARTERLY" &&
                        f.type !== "ONCE"
                    )
                    .map((f) => (
                      <Radio.Button key={f.type} value={f.type}>
                        {f.display_name}
                      </Radio.Button>
                    ))}
                </Radio.Group>
              </div>
            ) : null}
            <div className="relative-tip">
              {this.state.dateRangeType === "custom" ? (
                <React.Fragment>
                  <div className="date-picker-container">
                    <RangePicker
                      value={[
                        form?.args?.start_date
                          ? moment.utc(form?.args?.start_date)
                          : undefined,
                        form?.args?.end_date
                          ? moment.utc(form?.args?.end_date)
                          : undefined,
                      ]}
                      disabledDate={this.disabledDate}
                      format="YYYY/MM/DD"
                      className="range-picker"
                      picker={this.state.pickerFormat}
                      onChange={(dates) => this.rangeChange(dates, dayLimit)}
                      panelRender={this.customPanelRender}
                      renderExtraFooter={this.extraFooter}
                      status={this.state.error ? "error" : null}
                    />
                    <div
                      className={`report-quota-warning ${
                        remainging_reports ? "" : "error"
                      }`}
                    >
                      <InfoCircleOutlined />{" "}
                      Monthly Reporting Quota:{" "}
                      <span className="bold">
                        {remainging_reports} Reports(s) Remaining Out of{" "}
                        {report_count_monthly_limit}
                      </span>
                    </div>
                    </div>
                    <Typography.Text type='danger'>{this.state.error || this.state.errors.frequency }</Typography.Text>
                </React.Fragment>
              ) : null}
              {frequencyType && this.renderToolTip(frequencyType)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountId: store.accountState.getIn(['accountId', 'value']),
    users: store.accountState.getIn(['allUsers', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
  };
};

export default connect(
  mapStateToProps, {
    getAccountPermissions,
  },
)(CreateReport);
