import React, { useState, useEffect } from "react";
import { Col, Form, Row, Space, Typography, Button, Modal, Alert, Select, Input, Checkbox, theme, Flex } from 'antd';
import { Category } from "../../../../../records/preferences_records";
import { LeftOutlined } from "@ant-design/icons";
import { channelOptions } from '../../helper';
import SelectWithSelectAll from "../../../../../styleguide/components/SelectWithSelectAll";
import { catTypeToLabelMap, defaultUserStateOptions } from "../../helper";
import { useSelector } from "react-redux";
import { renderInfoTip } from "../../../us_privacy_regulation/helper";
import { withConfigProvider } from "../../../../../hooks";
import { hasFeature } from "../../../../utils";

const AddCategoryModal = (props) => {
  const {
    showAddCategoryModal,
    handleCancel,
    addCategory,
    mode,
    currentCategory,
    existingCategories
  } = props

  const [form] = Form.useForm();
  const [selectedChannel, setSelectedChannel] = useState([])
  const [confirmMsgBeforeSave, setConfirmMsgBeforeSave] = useState(null);
  const latestDocs = useSelector((store) => store.preferencesState.getIn(["latestLegalDocs", "value"]))?.sort((a,b) => a.documentName.localeCompare(b.documentName)) ?? [];
  const latestDocsPending = useSelector((store) => store.preferencesState.getIn(["latestLegalDocs", "pending"]));
  const categorySubTypes = useSelector((store) => store.preferencesState.getIn(["campaignTypeList", "value"]));
  const categorySubTypesPending = useSelector((store) => store.preferencesState.getIn(["campaignTypeList", "pending"]));
  const currentUser = useSelector((store) => store.accountState.getIn(['userDetails', 'value']));
  const hasMarkertingPreferenceAccess = hasFeature(currentUser, "marketing_preferences");
  const hasLegalPreferenceAccess = hasFeature(currentUser, "legal_preferences");

  function generateRandom10DigitNumber() {
    const min = 1000000000; // Minimum 10-digit number
    const max = 9999999999; // Maximum 10-digit number
    // Generate a random number between min and max (inclusive)
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
  }

  useEffect(() => {
    const initialValues =
      mode === "Add"
        ? {
            name: "",
            channel: [],
            description: "",
            type: props.catType,
            descriptionBool: false,
            defaultState: "OPT-OUT"
          }
        : {
          ...currentCategory?.toJS(),
          descriptionBool: Boolean(currentCategory.get("description")),
          subType: Boolean(currentCategory.get("subType")) ? currentCategory.get("subType") : "none"
        };
    form.setFieldsValue(initialValues); // Populate the form with initial values
  }, [mode, currentCategory]);

  const handleAddUpdateCategory = async (showConfirmatin) => {
    if(mode == 'Edit' && showConfirmatin){
      setConfirmMsgBeforeSave(<>If you change the default text for the category title and/or description here, you will need to update the translation text for additional languages in the <b>Language and Translations</b> step as well</>)
    } else {
      const categoryValues = await form.validateFields();
      categoryValues.subType = categoryValues.subType === "none" ? undefined : categoryValues.subType;
      if(categoryValues.descriptionBool) {
        categoryValues.description = categoryValues.description.trim();
      } else {
        categoryValues.description = undefined;
      }
      let updatedCategory;
      if(mode == "Edit"){
        updatedCategory = currentCategory.set("name", categoryValues.name).set("channels", categoryValues.channels).set("description", categoryValues.description).set("subType", categoryValues.subType).set("legalDocRef", categoryValues.legalDocRef).set("defaultState", categoryValues.defaultState)
        addCategory(updatedCategory);
      } else {
        const categoryValuesWithId = {
          ...categoryValues,
          type: props.catType,
          categoryId: `temp_id_${generateRandom10DigitNumber()}`,  // Add a unique ID to the category
        };
        addCategory(new Category(categoryValuesWithId))
      }
      handleCancel();
      setConfirmMsgBeforeSave(null);
    }
  }

  const onFinish = (formData) => {
    if(props.onEditScreen && !confirmMsgBeforeSave) {
      handleAddUpdateCategory(true)
    } else {
      handleAddUpdateCategory(false)
    }
  }
  
  const onFinishFailed = (data) => {
    //if needed
  }
  const onValuesChange = (changedFields, allFields) => {
    if(changedFields.subType) {
      form.validateFields(["legalDocRef"])
    }
  }

  const btnTitle = mode === "Add" ? "Add Category" : confirmMsgBeforeSave ? "Confirm" : "Save";
  const subTypesOfExitingCats = existingCategories?.map((cat) => cat.subType)?.filter((subType) => subType !== "none");
  const descriptionBool = Form.useWatch("descriptionBool", form);
  const subTypeState = Form.useWatch("subType", form);

  const { token } = theme.useToken();
  const CustomForm = withConfigProvider(Form);

  return (
    <Modal
      open={showAddCategoryModal}
      title={
      <Space>
        <Button
          icon={<LeftOutlined />}
          type="text"
          size="middle"
          onClick={handleCancel}
        />
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {mode} {catTypeToLabelMap[props.catType]} Category
        </Typography.Title>
      </Space>
      }
      onOk={form.submit}
      okText={btnTitle}
      // okButtonProps={{ disabled: form.getFieldsError().some(({ errors }) => errors.length) }}
      onCancel={handleCancel}
      cancelButtonProps={{type: "link"}}
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex gap={token.margin} justify="flex-end" align="center">
          {!latestDocs?.length ? <Alert type="warning" showIcon message="You will need to upload a document in the legal document library to enable selection here."/> : null}
          <Space>
            <CancelBtn />
            <OkBtn />
          </Space>
        </Flex>
      )}
      closable={true}
      destroyOnClose={true}
      centered
      width={720}
    >
      <CustomForm
        form={form}
        layout={"vertical"}
        component={false}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        initialValues={{ name: "", channels: [], description: "" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        requiredMark={"optional"}
        componentTokens={{
          itemMarginBottom: 0
        }}
      >
        <Row gutter={[16,24]}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Category Name"
              rules={[
                { required: true, message: "Category Name Required" },
              ]}
            >
              <Input placeholder="Category Name" />
            </Form.Item>
          </Col>
          <Col span={props.catType !== "MARKETING" ? 0 : 12}>
            <Form.Item
              name="channels"
              label="Channels"
              rules={[{ required: props.catType === "MARKETING", message: "Select Channels" }]}
              hidden={props.catType !== "MARKETING"}
            >
              <SelectWithSelectAll
                options={channelOptions}
                value={selectedChannel}
                onChange={setSelectedChannel}
                listHeight={128}
                // width={"334px"}
              />
            </Form.Item>
          </Col>
          <Col span={props.catType !== "LEGAL-TRANSACTION" ? 0 :  12}>
            <Form.Item
              name="subType"
              label={<>Scenario Gate Mapping{renderInfoTip("Scenario Gate Mapping connects the selected gates to the scenario builder for targeting. Choose 'None' if targeting is not needed")}</>}
              rules={[{ required: props.catType === "LEGAL-TRANSACTION", message: "Select Gate Mapping" }]}
              hidden={props.catType !== "LEGAL-TRANSACTION"}
            >
              <Select
                options={categorySubTypes?.map((subType) => ({value: subType.type, label: subType.displayName, disabled: subTypesOfExitingCats?.includes(subType.type)}))}
                loading={categorySubTypesPending}
                width={"334px"}
                disabled={props.onEditScreen && mode == 'Edit'}
              />
            </Form.Item>
          </Col>
          {hasLegalPreferenceAccess ? (
            <Col span={12}>
              <Form.Item
                name="legalDocRef"
                label="Legal Document"
                rules={[{ required: props.catType === "LEGAL-TRANSACTION" && subTypeState !== "none", message: "Legal Document Required" }]}
                required={props.catType === "LEGAL-TRANSACTION" && subTypeState !== "none"}
              >
                <Select
                  options={latestDocs?.map((doc) => ({value: doc.containerId, label: doc.documentName}))}
                  loading={latestDocsPending}
                  width={"334px"}
                  showSearch
                  optionFilterProp="label"
                  listHeight={128}
                  allowClear
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col span={12}>
            <Form.Item
              name="defaultState"
              label="Default User State"
              rules={[{ required: true, message: "Select Default User State" }]}
            >
              <Select
                options={defaultUserStateOptions}
                width={"334px"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="descriptionBool" valuePropName="checked">
              <Checkbox >Description<Typography.Text type="secondary">(optional)</Typography.Text></Checkbox>
            </Form.Item>
            <Form.Item
              name="description"
              style={{ marginBottom: 0 }}
              hidden={!descriptionBool}
              required={false}
              noStyle
            >
              <Input.TextArea
                placeholder="Enter Description"
                autoSize={{
                  minRows: 2,
                  maxRows: 5,
                }}
              />
            </Form.Item>
          </Col>
          {props.catType === "LEGAL-TRANSACTION" && (props.onEditScreen && mode == 'Add') || (!props.onEditScreen && mode == 'Edit') ? (
            <Col span={24}>
              <Alert type={"warning"} showIcon message={"Scenario gate mapping cannot be edited once the configuration has been saved due to its dependency on the user consent status for a specific legal document."} />
            </Col>
          ) : null}
          {confirmMsgBeforeSave ? (
            <Col span={24}>
              <Alert type={"warning"} showIcon message={confirmMsgBeforeSave} />
            </Col>
          ) : null}
        </Row>
      </CustomForm>
    </Modal>
  );
}

export default AddCategoryModal
