import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Input } from '../../styleguide'
import { Button, Modal } from 'antd';

const CloneModal = (props) => {
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);

  useEffect(()=>{
    let cloneName = props.name + '_copy'
    let isDuplicate = props.getNameError("",cloneName);
    for(let i = 1; isDuplicate; i++) {
      cloneName = props.name + `_copy(${i})`;
      isDuplicate = props.getNameError("",cloneName);
    }
    setName(cloneName);
  },[]);

  function handleOnOk() {
    //Name validation
    let nameError = props.getNameError("",name);
    if(nameError){
      setNameError(nameError)
      return;
    }
    props.cloneMethod(name);
  }

  const footer = [
    <Button key="1" type='link'  onClick={props.closeModal}>
      Cancel
    </Button>,
    <Button key="2" onClick={handleOnOk} type="primary">
      Clone
    </Button>,
  ];
  return (
    <Modal
      onCancel={props.closeModal}
      className='clone-modal'
      open
      footer={footer}
      title={`${props.entity}  - Clone`} 
    >
      {props.type?(
        <div className='clone-modal-container'>
        <div className='clone-modal-name'>Clone {props.entity} Name</div>
        <Input
          value={name}
          className='clone-modal-input'
          onChange={(e) => setName(e.target.value)}
          disabled={props.readOnly}
          error={nameError}
          placeholder={`Enter ${props.entity} name`}
          maxLength={255}
          showCount={true}
        />
       <div className="warning-message"><ExclamationCircleFilled className="icon" />Note: This {props.entity} will be saved in the inactive mode since a property can only be mapped to one active {props.entity} at a time</div>
       <p>Are you sure you want to create a clone of this {props.entity}?</p>
      </div>
      ):(
      <div className='clone-modal-container'>
        <p><b>{props.name}</b> clone will be saved as a <b>{props.entityType ?? null} {props.entity}</b>. You can edit the name below.</p>
        <div className='clone-modal-name'>Name</div>
        <Input
          value={name}
          className='clone-modal-input'
          onChange={(e) => setName(e.target.value)}
          disabled={props.readOnly}
          error={nameError}
        />
        <hr/>
        <p>Are you sure you want to create a <b>CLONE</b>?</p>
      </div>
      )}
    </Modal>
  );
}
CloneModal.propTypes = {
  cloneMethod: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getNameError: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired 
}
export default CloneModal;
