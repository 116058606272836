import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Modal, Select, message } from '../../../../styleguide';
import { Flex, Space, Typography } from 'antd';

const CopyJsModal = ({
  visible,
  onClose,
  pms,
  messageSubcategory,
  site,
  userEmail
}) => {
  const [selectedPmId, setSelectedPmId] = useState('');

  useEffect(setToDefault, [pms.length]);

  function setToDefault() {
    if (pms.length) {
      setSelectedPmId(pms[0].id);
    };
  };

  const createSnippet = () => {
    switch(messageSubcategory) {
      case "us_pm":
        return `window._sp_usnat.loadPrivacyManagerModal(${selectedPmId})`;

      case "ccpa_pm":
        if(site?.multiCampaignEnabled) {
          return `window._sp_.ccpa.loadPrivacyManagerModal(${selectedPmId})`
        }
        return `window._sp_ccpa.loadPrivacyManagerModal(${site?.id}, ${selectedPmId})`;
      
      case "privacy-manager":
      case  "privacy_manager_non_iab":
        if(site?.multiCampaignEnabled) {
          return `window._sp_.gdpr.loadPrivacyManagerModal(${selectedPmId}, 'purposes')`;
        }
        return `window._sp_.loadPrivacyManagerModal(${selectedPmId}, 'purposes')`;
      case "preferences":
        return `window._sp_.preferences.loadPreferenceCenter(${selectedPmId}, '${userEmail}')`;

      default:
        return `window._sp_.loadPrivacyManagerModal(${selectedPmId})`;
    }
  }

  const copySnippetToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = createSnippet();
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success('Copied to clipboard');
  };

  return (
    <Modal
      title='Copy snippet to clipboard'
      visible={visible}
      onCancel={() => {
        onClose();
        setToDefault()
      }}
      footer={null}
    >
      <div className="url-modal-container">
        <Flex vertical gap={16}>
          <div>
            <label>Select Privacy Manager</label>
            <Select value={selectedPmId} onChange={setSelectedPmId}>
              {pms.map(({ id, description }) => (
                <Select.Option key={id} value={id}>
                  {description}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Space direction='vertical' size={5}>
            <label>Generated Snippet</label>
            <div className="url-container">
              <div>
                {pms.length ? createSnippet(selectedPmId) : ''}
              </div>
              <FontAwesomeIcon
                icon={faCopy}
                size="lg"
                style={{ cursor: 'pointer' }}
                onClick={copySnippetToClipboard}
              />
            </div>
            {["privacy-manager", "privacy_manager_non_iab"].includes(messageSubcategory) ? (
              <Typography.Text>The "purposes" argument opens the user consent tab under purposes upon resurfacing the privacy manager.</Typography.Text>
            ) : null}
          </Space>
        </Flex>
      </div>
    </Modal>
  );
};

export default CopyJsModal;