import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Space, Modal, Typography, Row, Col, Button, Select, Input, message, Divider, Checkbox, Alert, Flex, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LinkOutlined, StopOutlined } from '@ant-design/icons';
import { createVendorConnectionAjax, deleteVendorConnectionAjax, redirectForOAuth, saveOauthCodeWithVendorConnectionId, testVendorConnection } from '../../../../api/preferences';
import { VendorConnection } from '../../../../records/preferences_records';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const { Text, Title } = Typography;

const AddEditConnection = (props) => {
  const {
    showAddEditConnectionModal,
    closeModal,
    mode,
    addVendorConnection,
    accountId,
    selectedConnection,
  } = props;

  const [vendorConnection, setVendorConnection] = useState(mode === "edit" ? selectedConnection : new VendorConnection({}));
  const [testLoading, setTestLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [status, setStatus] = useState({ ok: false, msg: "" });
  const [oauthCode, setOauthCode] = useState(null);
  const [oauthStatus, setOauthStatus] = useState(mode === "edit" ? selectedConnection?.status : { ok: false, msg: null });
  const [continueToCategoriesModal, setContinueToCategoriesModal] = useState(true);
  const methodCallCount = useRef(0);

  const systemVendors = useSelector(state => state.preferencesState.getIn(['vendors', 'value']));
  const systemVendorsHash = useSelector(state => state.preferencesState.getIn(['vendorsHash', 'value']))
  const vendorConnectionsSavePending = useSelector(state => state.preferencesState.get('savePending'));
  
  const selectedVendorRecord = systemVendorsHash.get(vendorConnection?.vendorId);

  const handleMessage = useCallback((event) => {
    if (event.source.name === "redirect-for-oauth") {
      setOauthCode(event.data);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (oauthCode) {
      (async () => {
        try {
          const resp = await createVendorConnectionAjax(vendorConnection?.toJS());
          if (resp?.connectionId) {
            setVendorConnection(resp);
            try {
              await saveOauthCodeWithVendorConnectionId(resp.vendorId, accountId, oauthCode, resp.connectionId);
              setOauthStatus({ ok: true, msg: "Authenticated" });
            } catch (err) {
              setOauthStatus({ ok: false, msg: err.message });
            }
          }
        } catch (err) {
          setOauthStatus({ ok: false, msg: err.message });
        } finally {
          setTestLoading(false);
        }
      })();
    }
  }, [oauthCode]);

  useEffect(() => {
    setContinueToCategoriesModal(selectedVendorRecord?.canPullCategories)
    if(mode === "add") {
      setVendorConnection((connection) => new VendorConnection({
        name: connection.name,
        vendorId: connection.vendorId,
        authConfig: {authType: selectedVendorRecord?.authType}
      }))
    }
  }, [vendorConnection?.vendorId]);

  const handleCloseModal = useCallback(() => {
    if (mode === "add" && vendorConnection?.connectionId) {
      deleteVendorConnectionAjax(accountId, vendorConnection.connectionId)
        .then((resp) => {
          if (resp.dateDeleted) {
            closeModal();
          }
        })
        .catch(() => {
          alert("Something went wrong");
        });
    } else {
      closeModal();
    }
  }, [mode, vendorConnection, accountId, closeModal]);


  const title = (mode === 'add') ? 'New Connection' : 'Edit Connection';
  const connName = vendorConnection?.name?.trim()
  const connectionNameError = !Boolean(connName) ? <>Please enter a Connection name </>:  props.venConnNamesList.includes(connName) ? <>Connection with name <b>{connName}</b> already exists</> : null ;
  const vendorIdError = systemVendors?.find((vendor) => vendor?.id === vendorConnection?.vendorId) ? null : <>Please select an Integration</>;
  const clientIdError = Boolean(vendorConnection?.authConfig?.get("clientId")?.trim()) || selectedVendorRecord?.authType !== "oauth" ? null : <>Please enter a Client Id</>;
  const clientSecretError = Boolean(vendorConnection?.authConfig?.get("clientSecret")?.trim()) || selectedVendorRecord?.authType !== "oauth" ? null : <>Please enter Secret Code</>;
  const portalIdError = Boolean(vendorConnection?.portalId?.trim()) ? null : <>Please enter your {selectedVendorRecord?.name}' Portal ID</>;
  const authKeyError = Boolean(vendorConnection?.authConfig?.get("apiKey")?.trim()) || selectedVendorRecord?.authType !== "apiKey" ? null :  <>Please enter API Key </>
  const audienceIdError = Boolean(vendorConnection?.metadata?.get("audienceId")?.trim()) || selectedVendorRecord?.id !== "mailchimp" ? null :  <>Please enter Audience ID </>


  const handleVendorConnectionTest = () => {
    if(connectionNameError || vendorIdError  || portalIdError || authKeyError || audienceIdError) {
      setFormError(true);
    } else {
      methodCallCount.current += 1;
      setTestLoading(true);
      redirectForOAuth(vendorConnection?.vendorId, accountId)
        .then((resp) => {
          if (resp.url) {
            const oauthWindow = window.open(resp.url, "redirect-for-oauth", 'popup');
            const checkWindowClosed = setInterval(() => {
              if (oauthWindow.closed) {
                clearInterval(checkWindowClosed);
                setTestLoading(false);
              }
            }, 500); // Check every 500ms
          } else {
            setTestLoading(false);
          }
        })
        .catch((err) => {
          setOauthStatus({ ok: false, msg: err.message });
          setTestLoading(false);
        });
        setFormError(false);
    }
  }

  const handleAddEditConnection = (mode) => {
    if(connectionNameError || vendorIdError  || portalIdError || authKeyError || audienceIdError) {
      setFormError(true);
    } else {
      addVendorConnection(vendorConnection, mode, continueToCategoriesModal, selectedVendorRecord?.authType === "oauth")
      setFormError(false)
    }
  }

  let footer = [
    <Button type='link' onClick={handleCloseModal}>Cancel</Button>,
    <Button type='primary' loading={vendorConnectionsSavePending} onClick={() => handleAddEditConnection(mode)} disabled={mode == "add" && (selectedVendorRecord?.authType === "oauth" && !oauthStatus.ok)}>Save</Button>,
  ];

  if(props.linkedPrefConfigs) {
    footer = <Alert message={<Text> Cannot Edit this Connection as it is linked to the following Preferences Configurations : <b>{props.linkedPrefConfigs?.map((conn) => conn.name)?.toJS()?.join(", ")}</b></Text>} type="error" showIcon />
  }

  const renderConnectionDetailsBasedOnAuthType = (authType) => {
    let connDetailsNode = null;
    switch(authType) {
      case 'oauth': 
        connDetailsNode = (
          <>
            {/* <Col span={24}>
              <Title level={5}>OAuth Details</Title>
              <Row gutter={[32, 16]}>
                <Col span={12}>
                  <Text>Client ID</Text>
                  <Input
                    placeholder="Client Id"
                    className="api-input"
                    value={vendorConnection?.authConfig?.get("clientId")}
                    onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set('authConfig', venConn.get('authConfig').set('clientId', value)))}
                    disabled={oauthStatus.ok}
                    status={formError && clientIdError? 'error' : null}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  {formError && clientIdError ? <Text type="danger">{clientIdError}</Text> : null}

                </Col>
                <Col span={12}>
                  <Text>Secret Code</Text>
                  <Input.Password
                    type="password"
                    placeholder="Secret Code"
                    className="api-input"
                    value={vendorConnection?.authConfig?.get("clientSecret")}
                    onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set('authConfig', venConn.get('authConfig').set('clientSecret', value)))}
                    disabled={oauthStatus.ok}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    status={formError && clientSecretError ? "error" : null}
                    style={{height: "32px"}}
                    size='large'
                  />
                  {formError && clientSecretError ? <Text type="danger">{clientSecretError}</Text> : null}
                </Col>
              </Row>
            </Col> */}
            <Col span={24}>
              <Flex justify='flex-end' align='center'>
                {oauthStatus.ok && mode === "edit" ? (
                <Alert message={"Authenticated"} type="success" showIcon />
                ) : (
                <>
                  {methodCallCount.current && !testLoading ? oauthStatus.ok ? (
                    <Alert message={"Authenticated"} type="success" showIcon />
                  ) : (
                    <Text type='danger'><StopOutlined /> Error While Authenticating {" "}</Text>
                  ) : null}
                  {mode === "add" && !oauthStatus.ok ? (
                    <Button
                      type="default"
                      onClick={handleVendorConnectionTest}
                      loading={testLoading}
                      icon={<LinkOutlined />}
                      disabled={vendorIdError  || portalIdError || authKeyError || audienceIdError}
                    >
                      {testLoading ? "Waiting for authentication..." : "Authenticate with OAuth"}
                    </Button>
                  ) : null}
                </>
              )}
              </Flex>
            </Col>          
            <Col>
              <Text><b>Note:</b> If the connection fails the test, you won't be able to pull categories from the connection.</Text>
            </Col>
          </>
        )
        break;
      case 'apiKey':
        connDetailsNode = (
          <Col span={24}>
            <Title level={5}>API Details</Title>
            <Row gutter={[32, 16]}>
              <Col span={12}>
                <Text>API key</Text>
                <Input
                  placeholder="API Key"
                  className="api-input"
                  value={vendorConnection?.authConfig?.get("apiKey")}
                  onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set('authConfig', venConn.get('authConfig').set('apiKey', value)))}
                  disabled={oauthStatus.ok}
                  status={formError && authKeyError? 'error' : null}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
                {formError && authKeyError ? <Text type="danger">{authKeyError}</Text> : null}
              </Col>
            </Row>
          </Col>
        )
        break;
    }
    return connDetailsNode;
  }

  const renderAdditionalDetailsBasedOnVendorId = (vendorId) => {
    let renderNode = null;
    switch(vendorId) {
      case 'mailchimp' :
        renderNode = (
          <>
          <Text>Mailchimp Audience ID</Text>
          <Input
            placeholder="Mailchimp Audience ID"
            className="connection-search"
            disabled={oauthStatus.ok}
            value={ vendorConnection?.metadata?.get("audienceId")}
            onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set('metadata', venConn.get('metadata').set('audienceId', value)))}
            status={formError && audienceIdError ? 'error' : null}
          />
          {formError && audienceIdError ? <Text type="danger">{audienceIdError}</Text> : null}
          </>
        )
    }
    return renderNode;
  }

  const vendorPortalIdDescriptions = systemVendors.reduce((map, ele) => {
    if(ele.id === "braze") {
      map[ele.id] = "(instance_url)" 
    }
    return map
  },{})

  return (
    <Modal
      title={title}
      open={showAddEditConnectionModal}
      wrapClassName={classNames(`edit-add-connection`, {'footer-left' : props.linkedPrefConfigs})}
      width='40%'
      closable={true}
      destroyOnClose={true}
      onCancel={() => handleCloseModal()}
      confirmLoading={vendorConnectionsSavePending}
      maskClosable={false}
      footer={footer}
    >
      <Spin spinning={vendorConnectionsSavePending}>
      <Row gutter={[32, 16]} >
        <Col span={12} >
          <Text>Integration (Vendor)</Text>
          <Select
            style={{display:"block"}}
            className="connection-search"
            placeholder="Select Integration"
            options={systemVendors?.map((v, i) => ({
              label: v?.name,
              value: v?.id,
            }))?.toJS()}
            value={vendorConnection?.vendorId}
            onChange={(value) => setVendorConnection((venConn) => venConn.set("vendorId", value))}
            disabled={oauthStatus.ok}
            status={formError && vendorIdError ? 'error' : null}
          />
          {formError && vendorIdError ? <Text type="danger">{vendorIdError}</Text> : null}
        </Col>
        <Col span={12}>
          <Text>Connection Name</Text>
          <Input
            placeholder="Connection Name"
            className="connection-search"
            value={ vendorConnection?.name}
            onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set("name", value))}
            status={formError && connectionNameError ? 'error' : null}
          />
          {formError && connectionNameError ? <Text type="danger">{connectionNameError}</Text> : null}
        </Col>
        {vendorConnection?.vendorId ? ( 
        <>
          <Col span={12}>
            <Text>
              {selectedVendorRecord?.name}'s Portal ID{" "}
              {vendorPortalIdDescriptions[vendorConnection?.vendorId] ? (
                <Text type="secondary" italic>{vendorPortalIdDescriptions[vendorConnection?.vendorId]}</Text>
              ) : null}
            </Text>
            <Input
              placeholder={`Enter ${selectedVendorRecord?.name}'s Portal ID`}
              className="connection-search"
              value={ vendorConnection?.portalId}
              disabled={oauthStatus.ok}
              onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set("portalId", value))}
              status={formError && connectionNameError ? 'error' : null}
            />
            {formError && portalIdError ? <Text type="danger">{portalIdError}</Text> : null}
          </Col>
          <Col span={12}>
            {renderAdditionalDetailsBasedOnVendorId(vendorConnection?.vendorId)}
          </Col>
          {
            renderConnectionDetailsBasedOnAuthType(selectedVendorRecord?.authType)
          }
          {props.mode == "add" && selectedVendorRecord?.canPullCategories ? (
            <>
              <Divider style={{margin:0}}/>
              <Col>
                <Checkbox checked={continueToCategoriesModal} onChange={({target : {checked}}) => setContinueToCategoriesModal(checked)}>
                  {" "}
                  <Text>Add <b>Connection Categories</b> after saving the Connection</Text>
                </Checkbox>
              </Col>
            </>
          ) : null}
        </>
        ) : null}
      </Row>
      </Spin>
    </Modal>
  )
}

export default AddEditConnection
