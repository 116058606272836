import * as types from './action_types';
import { actionCreator } from './helpers';
import { getTransactionHistoryAjax } from '../api/consent_v2/transaction_reciept'



function getTransactionHistoryPending() {
    return {
        type: types.GET_ALL_TRANSACTION_HISTORY_PENDING
    }
}

function getTransactionHistoryLoaded(transactionList) {
    return {
        type: types.GET_ALL_TRANSACTION_HISTORY_LOADED,
        transactionList: transactionList?.transactions
    }
}

function getTransactionHistoryError(error) {
    return {
        type: types.GET_ALL_TRANSACTION_HISTORY_ERROR,
        error: error
    }
}

export const getTransactionHistory = actionCreator(
    getTransactionHistoryAjax,
    getTransactionHistoryPending,
    getTransactionHistoryLoaded,
    getTransactionHistoryError
)
