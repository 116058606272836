import React, { useEffect, useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  PauseOutlined,
  PercentageOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  Form,
  Tag,
  Table,
  Empty,
  Flex,
  Badge,
  Button,
  Tooltip,
  message,
  Modal,
  Typography,
  Space,
  Radio,
  Dropdown,
  Progress,
  Tabs,
  theme,
} from "antd";
import { columnDiagnose } from "../AccountFeatures";
import DiagnoseScanSetupEditorModal from "./ScanEditorModal";
import {
  getAccountBulkScans,
  getDiagnoseAccountScans,
  getDiagnoseAccountWebsites,
  getDiagnoseBulkScanFilter,
} from "../../../actions/diagnose_scan_setup";
import {
  deleteAccountScan,
  toggleAccountScan,
} from "../../../api/diagnose_dashboard/scan_setup";
import { text } from "@fortawesome/fontawesome-svg-core";
import DiagnoseBulkScanModal from "./BulkScanModal";
import UploadCsvFile from "./UploadCsv";
import UploadSiteMapModal from "./UploadSiteMapModal";

const { Option } = Select;
const { useToken } = theme;

function scanSetup(status) {
  if (status) {
    return <span className="scan-status-successful">Successful</span>;
  }
  if (status === false) {
    return <span className="scan-status-failed">Failed</span>;
  }
  return <span className="scan-status-pending">Pending</span>;
}

function scanBadge(status) {
  if (status) {
    return "success";
  }
  if (status === false) {
    return "error";
  }
  return "warning";
}

function DiagnoseScanSetup() {
  const [modal, contextHolder] = Modal.useModal();
  const accountState = useSelector((store) => store.accountState);
  const scanSetupState = useSelector((store) => store.diagnoseScanSetupState);
  const accountOwners = accountState.getIn(["accountOwners", "value"]);
  const accountScans = scanSetupState.getIn(["accountScans", "value"]);
  const accountBulkScans = scanSetupState.getIn(["accountBulkScans", "value"]);
  const websitePages = scanSetupState.getIn(["websitePages", "value"]) || [];
  const bulkScanFilter = scanSetupState.getIn(["bulkScanFilters", "value"]);
  const isLoadingBulkScans = scanSetupState.getIn([
    "accountBulkScans",
    "pending",
  ]);

  const isLoadingAccountScans = scanSetupState.getIn([
    "accountScans",
    "pending",
  ]);
  const errorMessage = scanSetupState.getIn(["accountScans", "error"]);
  const dispatch = useDispatch();
  const diagnoseAccount = accountOwners.filter((account) =>
    account.accountFeatures.some((feature) => columnDiagnose.includes(feature))
  );
  const [accountId, setAccountId] = useState(null);
  const [editScanId, setEditScanId] = useState(null);
  const [modalOpenType, setModalOpenType] = useState(null);
  const [bulkModalOpenType, setBulkModalOpenType] = useState(null);
  const [uploadCsvModalType, setUploadCsvModalType] = useState(null);
  const [bulkData, setBulkData] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const options = [
    {
      label: "Single Scan",
      value: "Single Scan",
    },
    {
      label: "Bulk Scan",
      value: "Bulk Scan",
    },
  ];
  const [tableFilter, setTableFilter] = useState({});
  const [paginationState, setPaginationState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sortingState, setSortingState] = useState({});

  const handleRowClick = (record) => {
    const isExpanded = expandedRowKeys.includes(record.website_id);
    setExpandedRowKeys(isExpanded ? [] : [record.website_id]);
  };

  const [successfulScan, setSuccessfulScan] = useState(null);
  const { token } = useToken();

  const refreshAccountScans = async (
    accountId,
    page = 1,
    pageSize = 10,
    sortOrder,
    sortField,
    filter = {}
  ) => {
    let sort_order = sortOrder === "ascend" ? 1 : 0;

    const payload = {
      params: {
        website_names: filter.website_name || [],
      },
      pagination: {
        page: page,
        page_size: pageSize,
        sort_order: sort_order,
        sort_field: sortField,
      },
    };

    try {
      const response = await dispatch(
        getDiagnoseAccountScans(
          { accId: accountId, accountId: accountId },
          payload
        )
      );
    } catch (error) {
      console.error("Failed to load scans:", error);
      message.error("Failed to load scans for the selected account.");
    }
  };

  const refreshBulkAccountScans = async (
    accountId,
    page = 1,
    pageSize = 10,
    sortOrder,
    sortField = "",
    filter = {}
  ) => {
    let sort_order = sortOrder === "ascend" ? 1 : 0;

    const payload = {
      page: page,
      page_size: pageSize,
      sort_order: sort_order,
      sort_field: sortField,
      properties: filter.display_name || [],
    };

    try {
      const res = await dispatch(
        getAccountBulkScans({ accId: accountId, accountId: accountId }, payload)
      );
    } catch (error) {
      console.error("Failed to load scans:", error);
      message.error("Failed to load scans for the selected account.");
    }
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    refreshAccountScans(
      accountId,
      pagination.current,
      pagination.pageSize,
      sorter.order,
      sorter.field,
      filters
    );
    setTableFilter(filters);
    setPaginationState((prevState) => ({
      ...prevState,
      page: pagination.current,
      pageSize: pagination.pageSize,
    }));
    setSortingState(sorter);
  };

  const handleBulkPaginationChange = (pagination, filters, sorter) => {
    refreshBulkAccountScans(
      accountId,
      pagination.current,
      pagination.pageSize,
      sorter.order,
      sorter.field,
      filters
    );
    setTableFilter(filters);
    setPaginationState((prevState) => ({
      ...prevState,
      page: pagination.current,
      pageSize: pagination.pageSize,
    }));
    setSortingState(sorter);
  };

  const handleAccountSelect = (value) => {
    setTableFilter({});
    setFilteredInfo({});
    setAccountId(value);
    websiteName(value);
    setPaginationState({ page: 1, pageSize: 10 });
    setSortingState({});
    refreshAccountScans(value);
    bulkScanningFilters(value);
    refreshBulkAccountScans(value);
  };

  const websiteName = (accountId) => {
    dispatch(getDiagnoseAccountWebsites({ accId: accountId }));
  };

  const bulkScanningFilters = (accountId) => {
    dispatch(getDiagnoseBulkScanFilter({ accId: accountId, accountId: accountId }));
  };

  const handleChange = (values, filters) => {
    setFilteredInfo(filters);
  };
  const websiteNameFilter =
    websitePages?.map((web) => ({
      label: web.url,
      value: web.name,
      text: web.name,
    })) ?? [];

  const bulkScanFilterOptions =
    bulkScanFilter?.properties?.map((fil) => ({
      text: fil,
      value: fil,
    })) ?? [];

  const DiagnosePropertyScanColumns = [
    {
      title: "Property Name",
      dataIndex: "website_name",
      key: "website_name",
      width: 270,
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sortOrder:
        sortingState.field === "website_name" ? sortingState.order : null,
      filters: websiteNameFilter,
      filterSearch: true,
      filteredValue: tableFilter.website_name || null,
      onFilter: (value, record) => value === record.website_name,
      sorter: (a, b) => a.website_name.localeCompare(b.website_name),
      render: (text, record) => {
        const failedScans = record.scans
          ? record.scans.filter((scan) => scan.successful == false).length
          : 0;
        return (
          <Space>
            <Typography.Paragraph
              style={{ maxWidth: 270 }}
              ellipsis={{ tooltip: { title: record.website_name }, rows: 1 }}
            >
              {record.website_name}
            </Typography.Paragraph>
            {failedScans > 0 && (
              <Tooltip title={`Failed Scans : ${failedScans}`}>
                <Button
                  type="link"
                  icon={
                    <ExclamationCircleOutlined
                      style={{ color: token.colorTextDisabled }}
                    />
                  }
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: "Total Scans",
      dataIndex: "total_scans",
      key: "total_scans",
      width: 270,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.total_scans - b.total_scans,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
    },
  ];

  const DiagnoseScanSetupColumns = (recordLine) => {
    const zoneFilters = [
      ...new Set(recordLine.scans.map((scan) => scan.zone)),
    ].map((zone) => ({ text: zone || "Not completed yet", value: zone }));

    const regionFilters = [
      ...new Set(recordLine.scans.map((scan) => scan.country)),
    ].map((country) => ({
      text: country || "Not completed yet",
      value: country,
    }));

    const consentFilters = [
      ...new Set(recordLine.scans.map((scan) => scan.consent_category)),
    ].map((consentCategory) => ({
      text: consentCategory,
      value: consentCategory,
    }));

    const scanIdFilters = recordLine.scans.map((scan) => ({
      text: scan.id,
      value: scan.id,
    }));

    return [
      {
        title: "Region",
        dataIndex: "zone",
        filters: zoneFilters,
        onFilter: (value, record) => value === record.zone,
        key: "zone",
        width: 95,
        render: (text) => text || "Not completed yet",
      },
      {
        title: "Country / state",
        dataIndex: "country",
        key: "country",
        onFilter: (value, record) => value === record.country,
        filters: regionFilters,
        width: 125,
        render: (text) =>
          text ? (
            <Tag bordered key={text}>
              {text}
            </Tag>
          ) : (
            "Not completed yet"
          ),
      },
      {
        title: "Scan ID",
        filterSearch: true,
        dataIndex: "id",
        filters: scanIdFilters,
        onFilter: (value, record) => value === record.id,
        key: "id",
        width: 125,
        render: (text) => {
          const handleClick = (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            navigator.clipboard.writeText(text);
            message.success("Scan ID copied");
          };

          return (
            <>
              <Flex justify="space-between" align="center">
                <Tooltip
                  overlayClassName="diagnose--tooltip"
                  title={
                    <div
                      onClick={(evt) => evt.stopPropagation()}
                      className="information-storage-tooltip"
                    >
                      {text}
                    </div>
                  }
                >
                  {text.split("-")[0]}...
                </Tooltip>
                <Tooltip
                  onClick={(evt) => evt.stopPropagation()}
                  overlayClassName="diagnose--tooltip"
                  title={
                    <div onClick={(evt) => evt.stopPropagation()}>
                      Copy Scan ID
                    </div>
                  }
                >
                  <Button type="link" onClick={handleClick}>
                    <CopyOutlined />
                  </Button>
                </Tooltip>
              </Flex>
            </>
          );
        },
      },
      {
        title: "Consent type",
        onFilter: (value, record) => value === record.consent_category,
        dataIndex: "consent_category",
        filters: consentFilters,
        key: "consent_category",
        width: 155,
      },
      {
        title: "Scan Frequency",
        dataIndex: "one_time",
        key: "one_time",
        width: 155,
        render: (text, record) => {
          return (
            <div key={record?.vendor_id}>{text ? "One Time" : "Recurring"}</div>
          );
        },
      },
      {
        title: "Last Scan",
        dataIndex: "successfull",
        key: "successfull",
        width: 125,
        filters: [
          { text: "Successfull", value: "successful" },
          { text: "Failed", value: "failed" },
          { text: "Pending", value: "pending" },
        ],
        onFilter: (value, record) => {
          if (value === "successful") {
            return record.successful === true;
          } else if (value === "failed") {
            return record.successful === false;
          } else if (value === "pending") {
            return record.pending === null;
          }
          return false;
        },
        render: (text, record) => {
          return (
            <div key={record?.vendor_id}>
              <Badge
                status={scanBadge(record.successful)}
                text={scanSetup(record.successful)}
              />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 125,
        render: (_, record) => {
          const onDuplicate = async (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            await editScan(record.id, "duplicate");
          };

          const onToggleState = async (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            await toggleAccountScan(
              { accId: accountId },
              { scan_id: record.id, enabled: !record.enabled }
            );
            refreshAccountScans(
              accountId,
              paginationState.current,
              paginationState.pageSize,
              sortingState.order,
              sortingState.field,
              tableFilter
            );
          };

          const onDelete = async (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            const confirm = await modal.confirm({
              title: "Delete Scan",
              icon: <ExclamationCircleOutlined />,
              content: "Are you sure you want to delete the scan?",
              okText: "Delete",
              cancelText: "Cancel",
            });
            if (confirm) {
              await deleteAccountScan({ accId: accountId, scan_id: record.id });
              setFilteredInfo({});
              refreshAccountScans(
                accountId,
                paginationState.current,
                paginationState.pageSize,
                sortingState.order,
                sortingState.field,
                tableFilter
              );
            } else {
              return;
            }
          };

          return (
            <Flex justify={"space-between"}>
              <Tooltip title={record.enabled ? "Pause" : "Play"}>
                <Button
                  type="link"
                  onClick={onToggleState}
                  icon={
                    record.enabled ? <PauseOutlined /> : <PlayCircleOutlined />
                  }
                />
              </Tooltip>
              <Tooltip title="Clone">
                <Button
                  type="link"
                  onClick={onDuplicate}
                  icon={<FileAddOutlined />}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  type="link"
                  onClick={onDelete}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Flex>
          );
        },
      },
    ];
  };

  const BulkScanColumn = [
    {
      title: "Property Name",
      dataIndex: "display_name",
      key: "display_name",
      width: 270,
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      filters: bulkScanFilterOptions,
      filteredValue: tableFilter.display_name || null,
      filterSearch: true,
      onFilter: (value, record) => value === record.display_name,
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      render: (text, record) => {
        return (
          <Space>
            <Typography.Paragraph
              style={{ maxWidth: 270 }}
              ellipsis={{ tooltip: { title: record.display_name }, rows: 1 }}
            >
              {text}
            </Typography.Paragraph>
            {record.failed_scans > 0 && (
              <Tooltip title={`Failed Scans : ${record.failed_scans}`}>
                <Button
                  type="link"
                  icon={
                    <ExclamationCircleOutlined
                      style={{ color: token.colorIcon }}
                    />
                  }
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: "Total Scans",
      dataIndex: "scan_count",
      key: "scan_count",
      width: 270,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.total_scans - b.total_scans,
    },
    {
      title: (
        <Space size={token.marginXS}>
          <Typography.Text>Progress</Typography.Text>
          <PercentageOutlined style={{ color: token.colorIcon}}/>
          <Tooltip
            title={() => {
              return (
                <span>
                  Indicates the number of scans completed out of the total scans
                  completed.
                </span>
              );
            }}
            placement="bottom"
          >
            <InfoCircleOutlined style={{ color: token.colorIcon }}/>
          </Tooltip>
        </Space>
      ),
      width: 270,
      render: (text, record) => {
        return (
          <Flex align="center" justify="space-evenly">
            <Tooltip
              title={`${record.progress_count} out of ${record.scan_count} scans completed`}
            >
              <Progress
                percent={record.progress_rate}
                strokeColor={token.colorSuccess}
                showInfo={false}
                style={{ width: "60%", marginBottom: 0 }}
              />
            </Tooltip>
            <Typography.Paragraph>{record.progress_rate}%</Typography.Paragraph>
          </Flex>
        );
      },
    },
    {
      title: (
        <Space size={token.marginXS}>
          <Typography.Text>Performance</Typography.Text>
          <PercentageOutlined style={{color: token.colorIcon}}/>
          <Tooltip
            title={() => {
              return (
                <span>
                  Indicates the number of successful scans out of the total
                  scans completed.
                </span>
              );
            }}
            placement="bottom"
          >
            <InfoCircleOutlined
             style={{ color: token.colorIcon }}
            />
          </Tooltip>
        </Space>
      ),
      width: 270,
      fixed: "left",
      render: (text, record) => {
        return (
          <Flex align="center" justify="space-evenly">
            <Tooltip
              title={`${record.successful_scans} successful scans,  ${record.failed_scans} failed scans`}
            >
              <Progress
                success={{
                  percent: record.success_rate,
                  strokeColor: token.colorSuccess,
                }}
                trailColor={record.failed_scans > 0 && token.red7}
                showInfo={false}
                style={{ width: "60%", marginBottom: 0 }}
              />
            </Tooltip>
            <Typography.Paragraph>{record.success_rate}%</Typography.Paragraph>
          </Flex>
        );
      },
    },
  ];

  async function editScan(scanId, type, successfulScan) {
    setEditScanId(scanId);
    setModalOpenType(type);
    setSuccessfulScan(successfulScan);
  }
  const openBulkModal = async (webisteId) => {
    setBulkModalOpenType(true);
    setBulkData(webisteId);
  };

  const openUploadCsvModal = (type) => {
    setUploadCsvModalType(type);
  };

  const tabItem = [
    {
      label: "Single Scan",
      key: "1",
      children: (
        <div>
          <Flex
            justify="space-between"
            align="center"
            style={{ padding: "12px 0" }}
          >
            <span>
              The scan summary page provides an overview of all the scans
              created for the respective properties under the selected account.
            </span>
            <Button
              style={{ marginBottom: 0 }}
              onClick={() => setModalOpenType("create")}
              type="primary"
            >
              Create New Scan
            </Button>
          </Flex>
          <Table
            scroll={{ x: "max-content", y: 630 }}
            columns={DiagnosePropertyScanColumns}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  onRow={(scanRecord) => ({
                    onClick: () =>
                      editScan(scanRecord.id, "edit", scanRecord.successful),
                  })}
                  rowClassName={"pointer"}
                  scroll={{ x: "max-content", y: 630 }}
                  columns={DiagnoseScanSetupColumns(record)}
                  dataSource={record.scans}
                  showSorterTooltip={false}
                  pagination={false}
                  rowKey={(subrecord) => subrecord?.id}
                  onChange={handleChange}
                />
              ),
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                const keys = expanded ? [record.website_id] : [];
                setExpandedRowKeys(keys);
              },
            }}
            dataSource={accountScans?.results}
            showSorterTooltip={false}
            rowKey={(record) => record?.website_id}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: tableFilter.website_name
                ? accountScans?.results.length
                : accountScans?.count,
              current: paginationState.page,
              pageSize: paginationState.pageSize,
              size: "small",
            }}
            loading={{
              className: "diagnose-spinning",
              spinning: isLoadingAccountScans,
              size: "large",
            }}
            onChange={handlePaginationChange}
          />
        </div>
      ),
    },
    {
      label: "Bulk Scan",
      key: "2",
      children: (
        <div>
          <Flex
            justify="space-between"
            align="center"
            style={{ padding: "12px 0" }}
          >
            <span>
              The scan summary page provides an overview of all the scans
              created for the respective properties under the selected account.
            </span>
            <Dropdown.Button
              menu={{
                items: [
                  {
                    label: "Upload Sitemap",
                    key: "1",
                    onClick: () => openUploadCsvModal("uploadSitemap"),
                  },
                ],
              }}
              htmlType="submit"
              type="primary"
              style={{ marginBottom: 0, width: "auto" }}
              icon={<DownOutlined />}
              getPopupContainer={(trigger) => trigger.parentElement}
              destroyPopupOnHide
              onClick={() => openUploadCsvModal("uploadCsv")}
            >
              Upload CSV File
            </Dropdown.Button>
          </Flex>
          <Table
            columns={BulkScanColumn}
            onRow={(scanRecord) => ({
              onClick: () => openBulkModal(scanRecord.website_id),
            })}
            dataSource={accountBulkScans?.results}
            showSorterTooltip={false}
            rowKey={(record) => record?.display_name}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: tableFilter.display_name
                ? accountBulkScans?.results.length
                : accountBulkScans?.total,
              current: paginationState.page,
              pageSize: paginationState.pageSize,
              size: "small",
            }}
            loading={{
              className: "diagnose-spinning",
              spinning: isLoadingBulkScans,
              size: "large",
            }}
            onChange={handleBulkPaginationChange}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="admin-settings-page-new">
        <Flex align="center" justify="space-between">
          <Form layout="vertical" autoComplete="off">
            <Form.Item name="account" label="Account">
              <Select
                showSearch
                virtual
                allowClear={false}
                menuItemSelectedIcon={null}
                value={[accountId]}
                style={{ width: "215px" }}
                onChange={handleAccountSelect}
                className={"spsg-select-multiple"}
                popupClassName={"spsg-select-multiple-drodown"}
                placeholder="Search account"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {diagnoseAccount.map((account) => (
                  <Option
                    key={`option-${account.accountId}`}
                    value={account.accountId}
                    title={account.accountName}
                    label={account.accountName}
                  >
                    <span
                      className="spsg-select-dropdown-label"
                      title={account.accountName}
                    >
                      {account.accountName}
                    </span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Flex>
        <Flex vertical>
          {!accountId && <Empty description="Select account to view data" />}
          {accountId &&
            (accountScans?.results?.length || isLoadingAccountScans ? (
              <Tabs
                defaultActiveKey="1"
                type="card"
                size={"medium"}
                style={{
                  marginBottom: 32,
                }}
                items={tabItem}
              />
            ) : (
              <Empty
                description={
                  errorMessage?.message ? (
                    <Typography.Text type="danger">
                      {errorMessage?.message}
                    </Typography.Text>
                  ) : (
                    "No scans"
                  )
                }
              />
            ))}
        </Flex>
        {contextHolder}
      </div>
      {modalOpenType && (
        <DiagnoseScanSetupEditorModal
          accountId={accountId}
          modalOpenType={modalOpenType}
          editScanId={editScanId}
          onClose={() => {
            setEditScanId(null);
            setModalOpenType(null);
          }}
          refreshAccountScans={() =>
            refreshAccountScans(
              accountId,
              paginationState.current,
              paginationState.pageSize,
              sortingState.order,
              sortingState.field,
              tableFilter
            )
          }
          successfulScan={successfulScan}
        />
      )}
      {bulkModalOpenType && (
        <DiagnoseBulkScanModal
          accountId={accountId}
          bulkModalOpenType={bulkModalOpenType}
          bulkData={bulkData}
          onClose={() => setBulkModalOpenType(false)}
        />
      )}
      <div>
        {uploadCsvModalType === "uploadCsv" && (
          <UploadCsvFile
            accountId={accountId}
            uploadCsvModalType={uploadCsvModalType}
            onClose={() => setUploadCsvModalType(null)}
            refreshBulkAccountScans={() => {
              refreshBulkAccountScans(
                accountId,
                paginationState.current,
                paginationState.pageSize,
                sortingState.order,
                sortingState.field,
                tableFilter
              );
            }}
          />
        )}
      </div>
      <div>
        {uploadCsvModalType === "uploadSitemap" && (
          <UploadSiteMapModal
            accountId={accountId}
            uploadCsvModalType={uploadCsvModalType}
            onClose={() => setUploadCsvModalType(null)}
            refreshBulkAccountScans={() => {
              refreshBulkAccountScans(
                accountId,
                paginationState.current,
                paginationState.pageSize,
                sortingState.order,
                sortingState.field,
                tableFilter
              );
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DiagnoseScanSetup;
