import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from 'react-router';

import {
  getAllPreferenceConfiguration,
  clearPreferenceConfigurationError,
  deletePreferenceConfiguration,
  clonePreferenceConfiguration,
  activateDeactivatePreferenceConfiguration,
  getAllVendorConnections,
  getAllLatestLegalDocuments,
} from '../../../actions/preferences_actions';
import { useActions } from "../../../hooks";
import RegulationListGeneric from "../vendor_list_v2/RegulationListGeneric";
import IntegrationsHub from './IntegrationHub';
import Loading from "../../common/Loading.js";
import { Button, Flex, Modal, Typography, Tabs } from "antd";
import { PlusOutlined, RightSquareOutlined } from "@ant-design/icons";
import LegalDocumentManagementHome from "./legal_docs_management/LegalDocumentManagementHome.jsx";
import { hasFeature } from "../../utils.js";

const { TabPane } = Tabs;

const PreferencesHomePage = (props) => {
  //redux actions
  const getAllPreferenceConfigurationAction = useActions(getAllPreferenceConfiguration);
  const cloneConfiguration = useActions(clonePreferenceConfiguration);
  const deleteConfiguration = useActions(deletePreferenceConfiguration);
  const activateDeactivateConfiguration = useActions(activateDeactivatePreferenceConfiguration);
  const getVendorConnections = useActions(getAllVendorConnections);
  const getAllLatestLegalDocs = useActions(getAllLatestLegalDocuments);

  //redux states
  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value']))
  const sites = useSelector(state => state.siteState.getIn(['sites', 'value']))?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-'));
  const siteGroups = useSelector(state => state.siteState.getIn(['siteGroups', 'value']))
  const preferenceConfigurationServerSideError =  useSelector(state => state.preferencesState.getIn(["preferenceConfigurationList", "error"]));

  const error = useSelector(state => state.usPrivacyReducerState.getIn(['preferenceConfigurationList', 'error']));
  const clearConfigurationError = useActions(clearPreferenceConfigurationError);
  const configurationsList = useSelector(state => state.preferencesState.getIn(['preferenceConfigurationList', 'value']));
  const configurationsListPending = useSelector(state => state.preferencesState.getIn(['preferenceConfigurationList', 'pending']));
  const vendorConnections = useSelector(state => state.preferencesState.getIn(['vendorConnections','value']))
  const vendorConnectionsPending = useSelector(state => state.preferencesState.getIn(['vendorConnections', 'pending']))
  const latestDocs = useSelector((store) => store.preferencesState.getIn(["latestLegalDocs", "value"]))?.sort((a,b) => a.documentName.localeCompare(b.documentName)) ?? [];

  const editUSPRegulation = (configuationId) => {
    browserHistory.push(`/preferences/edit?configurationId=${configuationId}`);
  };

  const [activeKey, setActiveKey] = useState("configurations");
  const [tableStates, setTableStates] = useState({
    sorter: {},
    filters: {},
    pagination: {}
  });

  const hasMarkertingPreferenceAccess = hasFeature(currentUser, "marketing_preferences");
  const hasLegalPreferenceAccess = hasFeature(currentUser, "legal_preferences");

  const calculateAccess = (sites, vlSites) => {
    return false;
    // if (currentUser?.allSiteAccess) return false;
    // const siteSet = new Set(sites);
    // let viewOnly = false;
    // vlSites.forEach(s => {
    //   if (!siteSet.has(s)) viewOnly = true;
    // });
    // return viewOnly;
  };

    useEffect(() => {
      if(preferenceConfigurationServerSideError && typeof preferenceConfigurationServerSideError !== "string"){
        let content = "Something went wrong"; 
        if(preferenceConfigurationServerSideError?.response?.status == 500){
          Modal.error({
            title: "Failed to save Configuration",
          })
          return;
        }
        const errors = preferenceConfigurationServerSideError?.get('errors');
        const validationError = preferenceConfigurationServerSideError?.get('err');
        if(errors?.size){
          content = <ul>{errors.map((error) => <li>{error.msg}</li>)}</ul>
        } else if(validationError){
          content = validationError
        }
        Modal.error({
          title: "Failed to save Configuration",
          content: content,
          onClick: () => clearConfigurationError()
        })
    }
    return ()=>{
      clearConfigurationError();
    }
  },[preferenceConfigurationServerSideError]);

  useEffect(() => {
    if(currentUser){
      getAllPreferenceConfigurationAction(currentUser.accountId);
      if(hasMarkertingPreferenceAccess) {
        getVendorConnections();
      }
      if(hasLegalPreferenceAccess) {
        getAllLatestLegalDocs();
      }
    }
  },[currentUser])

  const handleCreate = () => {
    browserHistory.push(`/preferences/create`);
  }

  let readOnly = false;
  const isAccountPartOfWave1 = true;

  const regulationList = configurationsListPending ? <Loading/> : (
    <Flex vertical={true} gap={20}>
      <Typography.Text type="secondary">Configurations enable you to manage the Legal Transaction and Marketing Preference categories for which you want to collect user interaction and choice data.</Typography.Text>
      <RegulationListGeneric
        currentUser={currentUser}
        vendorLists={configurationsList.map((config) => config.set("siteIds", config.get("propertyIds")))}
        sites={sites}
        siteGroups={siteGroups}
        vendorListsPending={configurationsListPending}
        usnat={props.route.usnat}
        nonIab={props.route.nonIab}
        readOnly={readOnly}
        calculateAccess={calculateAccess}
        entity={"Configuration"}
        handleCreate={handleCreate}
        handleEdit={editUSPRegulation}
        cloneVendorList={cloneConfiguration}
        deleteVendorList={deleteConfiguration}
        // vendorListError={preferenceConfigurationServerSideError}
        clearVendorListError={clearConfigurationError}
        activateDeactivateVendorList={activateDeactivateConfiguration}
        isAccountPartOfWave1={isAccountPartOfWave1}
        tableStates={tableStates}
        setTableStates={setTableStates}
      />
    </Flex>
  )

  return configurationsListPending ? <Loading/> :  (
    <Flex vertical={true} gap={20}>
    <Typography.Title level={2}>Universal Consent and Preferences</Typography.Title>
    <Tabs
      defaultActiveKey='configurations'
      className="preferences-tabs"
      activeKey={activeKey}
      onTabClick={(key) => setActiveKey(key)}
    >
      <TabPane tab={`Configurations (${configurationsList?.size})`} key="configurations" Loading>
        {/* requirement change. ref - DIA-5039 */}
        {/* {vendorConnections?.size == 0 ? (
          <Flex justify="center" align="center" vertical={true} style={{height: 300}}>
            <Typography.Title level={3}> You first need to setup Connections in Integration Hub</Typography.Title>
            <Button type="primary" icon={<RightSquareOutlined/>} onClick={() => setActiveKey("integrationHub")}>Go to Integration Hub</Button>
          </Flex>
        )
        :  */}
        {configurationsList.size ? regulationList : (
          <Flex justify="center" align="center" vertical={true} style={{height: 300}}>
            <Typography.Title level={3}> Start by creating your first configuration</Typography.Title>
            <Button type="primary" icon={<PlusOutlined/>} onClick={handleCreate}>Add Configuration</Button>
          </Flex>
        )}
      </TabPane>
      {hasMarkertingPreferenceAccess ? (
        <TabPane tab={`Integration Hub (${vendorConnections?.size})`} key="integrationHub">
          <IntegrationsHub
            configurationsList={configurationsList}
          />
        </TabPane>
      ) : null}
      {hasLegalPreferenceAccess ? (
        <TabPane tab={`Legal Document Library (${latestDocs?.length})`} key="legalDocs">
          <LegalDocumentManagementHome/>
        </TabPane>
      ) : null}
    </Tabs>
    </Flex>
  )
}

export default PreferencesHomePage
