import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { Select, Switch, Input, Modal, Alert, message } from "antd";
import SelectSitesAndSiteGroups from "../../common/SelectSitesAndSiteGroups";
import { inviteUser as inviteUserAction } from "../../../actions/account_actions";
import { useActions, usePrevious } from "../../../hooks";
import { validateEmail } from "../../utils";
import { User } from "../../../records/account_records";
import { Site, SiteGroup } from '../../../records/site_records';
import FeatureAccessDropdown from './FeatureAccessDropdown';

const InviteUserModal = (props) => {
  const {
    inviteModalOpen,
    toggleInviteModal,
    currentUser,
    sites,
    siteGroups,
    permissionsList,
    diagnoseProperties,
    invitedUser,
    pendingRequestsMap,
    invitedUserError
  } = props;

  const [inviteUserState, setInviteUserState] = useState({
    inviteeEmail: "",
    inviteeName: "",
    isInviteeAdmin: true,
    inviteeSites: "not set",
    inviteeFeatures: List(["All access"]),
    inviteeDiagnoseSites:List(["All access"]),
    inviteeNameError: "",
    inviteeEmailError: "",
  });

  const inviteUserDipatch = useActions(inviteUserAction);
  const hasDiagnoseDashboardPermission = currentUser.accountFeatures.includes("diagnose_dashboard")

  useEffect(() => {
    if (
      inviteUserState.inviteeSites === "not set" &&
      currentUser &&
      sites.size
    ) {
      if (currentUser.siteAccess) {
        setInviteUserState((inviteUsersData) => ({
          ...inviteUsersData,
          inviteeSites: currentUser.siteAccess.filter((id) =>
            sites.map((s) => s.id).includes(id)
          ),
        }));
      } else {
        setInviteUserState((inviteUsersData) => ({
          ...inviteUsersData,
          inviteeSites: List(["all access"]),
        }));
      }
    }
  }, [inviteUserState.inviteeSites, sites, currentUser]);

  const prevPropsPendingRequestInvitedUser = usePrevious(
    pendingRequestsMap.get("invitedUser")
  );

  useEffect(() => {
    if (
      prevPropsPendingRequestInvitedUser &&
      !pendingRequestsMap.get("invitedUser") &&
      invitedUser?.email?.toLowerCase() ===
        inviteUserState?.inviteeEmail?.toLowerCase()
    ) {
      if (invitedUser.inviteSent === false) {
        message.warning(
          `${invitedUser.email} has already accepted their invite, no invitation sent.`
        );
      } else {
        message.success(
          `An invitation email has been sent to ${invitedUser.email}`
        );
      }

      setInviteUserState((inviteUsersData) => ({
        ...inviteUsersData,
        inviteeEmail: "",
        inviteeName: "",
      }));
      toggleInviteModal();
    }
  });

  useEffect(() => {
    if(invitedUserError?.response?.status === 500) {
      message.error(<>Cannot add user <b>{inviteUserState.inviteeEmail}</b>. Please contact your account management team</>, 7);
    }
  },[invitedUserError]);

  const handleSelectInviteeSites = (siteIds) => {
    setInviteUserState((inviteUsersData) => ({
      ...inviteUsersData,
      inviteeSites: List(siteIds),
    }));
  };

  const handleSelectInviteeFeatures = (features) => {
    setInviteUserState((inviteUsersData) => ({
      ...inviteUsersData,
      inviteeFeatures: List(features),
    }));
  };

  const handleSelectDiagnoseSites = (sites) => {
    setInviteUserState((inviteUsersData) => ({
      ...inviteUsersData,
      inviteeDiagnoseSites: List(sites),
    }));
  };

  const handleInviteUser = () => {
    let errorCount = 0;

    if (!inviteUserState.inviteeEmail.trim().length) {
      setInviteUserState((inviteUsersData) => ({
        ...inviteUsersData,
        inviteeEmailError: "Email field cannot be empty",
      }));
      errorCount++;
    } else if (!validateEmail(inviteUserState.inviteeEmail)) {
      setInviteUserState((inviteUsersData) => ({
        ...inviteUsersData,
        inviteeEmailError: "Email must be valid",
      }));
      errorCount++;
    }

    if (!inviteUserState.inviteeName.trim().length) {
      setInviteUserState((inviteUsersData) => ({
        ...inviteUsersData,
        inviteeNameError: "Name field cannot be empty",
      }));
      errorCount++;
    }

    if (errorCount) return;

    let sites;
    if (
      inviteUserState.inviteeSites.includes("all access") ||
      inviteUserState.isInviteeAdmin
    ) {
      sites = "all access";
    } else {
      sites = inviteUserState.inviteeSites;
    }

    let features;
    if (inviteUserState.inviteeFeatures.includes("All access")) {
      features = "all access";
    } else {
      features = inviteUserState.inviteeFeatures.map((pemissionName) => {
        return permissionsList.find((p) => p.name === pemissionName);
      });
    }

    let diagnoseSites ;
    if (inviteUserState.inviteeDiagnoseSites.includes("All access")) {
      diagnoseSites = "all access";
    } else {
      diagnoseSites = inviteUserState.inviteeDiagnoseSites.map((pemissionName) => {
        return diagnoseProperties.find((p) => p.name === pemissionName)._id;
      });
    }
    try {
      inviteUserDipatch(
        inviteUserState.inviteeName,
        inviteUserState.inviteeEmail,
        inviteUserState.isInviteeAdmin,
        sites,
        features,
        diagnoseSites
      );
    } catch (err) {
      console.log(err)
    }
  
  };

  const handleInviteeEmailChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.trim() && inviteUserState.inviteeEmailError) {
      setInviteUserState((inviteUsersData) => ({
        ...inviteUsersData,
        inviteeEmailError: "",
      }));
    }
    setInviteUserState((inviteUsersData) => ({
      ...inviteUsersData,
      inviteeEmail: inputValue,
    }));
  };

  const handleInviteeNameChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.trim() && inviteUserState.inviteeNameError) {
      setInviteUserState((inviteUsersData) => ({
        ...inviteUsersData,
        inviteeNameError: "",
      }));
    }
    setInviteUserState((inviteUsersData) => ({
      ...inviteUsersData,
      inviteeName: inputValue,
    }));
  };

  const hadleInviteeAdminChange = (checked) => {
    setInviteUserState((inviteUsersData) => ({
      ...inviteUsersData,
      isInviteeAdmin: checked,
    }));
  };
  
  let alert1;
  if (inviteUserState.inviteeNameError) {
    alert1 = (
      <Alert
        message={inviteUserState.inviteeNameError}
        type="error"
        onClose={() =>
          setInviteUserState((inviteUsersData) => ({
            ...inviteUsersData,
            inviteeNameError: "",
          }))
        }
      />
    );
  }

  let alert2;
  if (inviteUserState.inviteeEmailError) {
    alert2 = (
      <Alert
        message={inviteUserState.inviteeEmailError}
        type="error"
        onClose={() =>
          setInviteUserState((inviteUsersData) => ({
            ...inviteUsersData,
            inviteeEmailError: "",
          }))
        }
      />
    );
  }

  const selectSites = (
    <div className="sites-wrapper">
      <label>Domain Access</label>
      <SelectSitesAndSiteGroups
        value={inviteUserState.inviteeSites || List([])}
        sites={sites}
        siteGroups={siteGroups}
        onSelect={handleSelectInviteeSites}
        multiple
        placeholder="Select sites this user will be granted acceess to..."
        hasAccessToAllSitesField
      />
    </div>
  );

  const selectFeatures = (
    <div className="sites-wrapper">
      <label>Permissions</label>
      <FeatureAccessDropdown 
          setSelectedFeatures={handleSelectInviteeFeatures}
          permissionsList={permissionsList}
          editing
          userFeatureAccess={inviteUserState.inviteeFeatures || List([])}
      />
    </div>
  );

  const selectDiagnoseProperty = (
    <div className="sites-wrapper">
      <label>Diagnose Properties</label>
      <Select
        value={
          (inviteUserState.inviteeDiagnoseSites &&
            inviteUserState.inviteeDiagnoseSites.toJS()) ||
          []
        }
        onChange={handleSelectDiagnoseSites}
        mode="multiple"
        tokenSeparators={[","]}
        placeholder="Select features this user will be granted acceess to..."
        dropdownClassName="select-user-feature"
      >
        <Select.Option key="All access">All access</Select.Option>
        {diagnoseProperties && diagnoseProperties.map((p) => (
          <Select.Option key={p.name}>{p.name}</Select.Option>
        ))}
      </Select>
    </div>
  );

  return (
    <Modal
      title="Invite User"
      open={inviteModalOpen}
      onOk={handleInviteUser}
      onCancel={toggleInviteModal}
      okText="Send invitation"
      className="invite-modal"
    >
      <label>Name</label>
      <Input
        onChange={handleInviteeNameChange}
        value={inviteUserState.inviteeName}
      />
      <label>Company email</label>
      <Input
        onChange={handleInviteeEmailChange}
        value={inviteUserState.inviteeEmail}
      />

      <div className="admin-access-wrapper">
        <div>
          <label>Admin access</label>
          <p className="admin-access-message">
            Admins have full domain Access to all properties in an account
            including,
            <br />
            all campaign/consent functions and user rights management etc..
          </p>
        </div>
        <Switch
          checked={inviteUserState.isInviteeAdmin}
          onChange={hadleInviteeAdminChange}
        />
      </div>
      {selectSites}
      {selectFeatures}
      {hasDiagnoseDashboardPermission && selectDiagnoseProperty}
      {alert1}
      {alert2}
    </Modal>
  );
};

InviteUserModal.propTypes = {
  inviteModalOpen: PropTypes.bool,
  toggleInviteModal:PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(User).isRequired,
  sites: ImmutablePropTypes.listOf(Site).isRequired,
  siteGroups: ImmutablePropTypes.listOf(SiteGroup).isRequired,
  permissionsList: ImmutablePropTypes.listOf({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  invitedUser: PropTypes.instanceOf(User),
  pendingRequestsMap: ImmutablePropTypes.map.isRequired,
};
export default React.memo(InviteUserModal);
