import { Record, List, Map, fromJS } from 'immutable';

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
let ScenarioPreferencesUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
  scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
  scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
  ScenarioUsnatUrl = '';
  ScenarioPreferencesUrl = 'https://cdn.privacy-mgmt.com/preferences';
} else {
  scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
  scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
  ScenarioUsnatUrl = 'https://usp.sp-stage.net';
  ScenarioPreferencesUrl = 'https://preferences.sp-stage.net';
}

export const ContinueRecord = Record({
  continue: true,
});

export const ContinueOnSatisfyRecord = Record({
  continue_on_satisfy: true,
});

export const RegexMatchRecord = Record({
  regex: '',
  continue_on_satisfy: true,
});

export const MessageIdRecord = Record({
  message_id: null,
});

export const ShowMessageFreqCapRecord = Record({
  message_id: null,
  n: 0,
  t: 0,
});

export const IntRangeKVRecord = Record({
  k: '',
  v_start: 0,
  v_end: 0,
  allow_on_satisfy: true,
});

export const StringValueKVRecord = Record({
  k: '',
  v: '',
  type: 'match',
  allow_on_satisfy: true,
});

export const ShowMessageNTimesRecord = Record({
  message_id: null,
  n: 0,
});

export const GeoGateRecord = Record({
  continue: true,
  geo_list: List([]),
});

export const USStateGateRecord = Record({
  continue: true,
  state_list: List([]),
});

export const IPGateRecord = Record({
  continue: true,
  ip_list: List([]),
});

export const PageviewsRecord = Record({
  num_pv: 0,
});

export const TimeDelayRecord = Record({
  delay_secs: 0,
});

export const SessionMessageNLimit = Record({
  limit: 1,
});

export const ConsentData = Record({
  status: 0,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  version: 1,
});

export const ConsentDataV2 = Record({
  status: 0,
  consent_origin: scenarioConsentUrl,
  version: 2,
});

export const ConsentDataCCPA = Record({
  status: 0,
  consent_origin: scenarioCcpaUrl,
  version: 'ccpa',
});

export const ConsentDataUSNAT = Record({
  status: 0,
  consent_origin: ScenarioUsnatUrl,
  version: 'usnat',
});

export const ConsentDataPreferences = Record({
  status: 0,
  consent_origin: ScenarioPreferencesUrl,
  version: 'preferences',
});

// statusKey
// 0 = no action/unknown
// 1 = consented
// 2 = rejected

// see ENG-471 for clean up
export const allEEA = 'AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE,GB,NO,IS,LI';
export const allEU = 'AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE,GB';

export class CountriesRecord extends Record({
  data: GeoGateRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
  category: 'condition',
  toBackendStep() {
    const updatedData = Object.assign({}, this.data);
    let updatedList = this.data.geo_list.toString();
    if (updatedList.includes('allEEA')) {
      updatedList = updatedList.replace('allEEA', allEEA);
    }
    if (updatedList.includes('allEU')) {
      updatedList = updatedList.replace('allEU', allEU);
    }
    updatedData.geo_list = updatedList;
    return {
      class: this.className,
      data: updatedData,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      let geoList = updatedObject.data.geo_list;
      if (geoList.includes(allEEA)) {
        geoList = geoList.replace(allEEA,'allEEA');
      }
      if (geoList.includes(allEU)) {
        geoList = geoList.replace(allEU,'allEU');
      }
      const geoListArray = object.data.geo_list === '' ?
        List([]) :
        fromJS(geoList
          .split(',')
          .filter( v => v != '' ));


      updatedObject.data = GeoGateRecord({
        continue: object.data.continue,
        geo_list: geoListArray,
      });
    }

    super(updatedObject);
  }
}

export const allStates = 'AL,AK,AS,AZ,AR,CA,CO,CT,DE,DC,FM,FL,GA,GU,HI,ID,IL,IN,IA,KS,KY,LA,ME,MH,MD,MA,MI,MN,MS,MO,MT,NE,NV,NH,NJ,NM,NY,NC,ND,MP,OH,OK,OR,PW,PA,PR,RI,SC,SD,TN,TX,UT,VT,VI,VA,WA,WV,WI,WY';

export class USStatesRecord extends Record({
  data: USStateGateRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.USStateGate',
  category: 'condition',
  toBackendStep() {
    const updatedData = Object.assign({}, this.data);
    let updatedList = this.data.state_list.toString();
    if (updatedList.includes('All')) {
      updatedList = allStates;
    }
    updatedData.state_list = updatedList;
    return {
      class: this.className,
      data: updatedData,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      let stateList = updatedObject.data.state_list;
      if (stateList.includes('All') || stateList === allStates) {
        stateList = 'All';
      }
      const stateListArray = object.data.state_list === '' ?
        List([]) :
        fromJS(stateList
          .split(',')
          .filter( v => v != '' ));


      updatedObject.data = USStateGateRecord({
        continue: object.data.continue,
        state_list: stateListArray,
      });
    }

    super(updatedObject);
  }
}

export class ClientIPGateRecord extends Record({
  data: IPGateRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate',
  category: 'condition',
  toBackendStep() {
    const updatedData = Object.assign({}, this.data);
    const updatedList = this.data.ip_list.toString();
    updatedData.ip_list = updatedList;
    return {
      class: this.className,
      data: updatedData,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      const IPListArray = object.data.ip_list === '' ? List([]) : fromJS(object.data.ip_list.split(','));

      updatedObject.data = IPGateRecord({
        continue: object.data.continue,
        ip_list: IPListArray,
      });
    }

    super(updatedObject);
  }
}

export class IntRangeKVGateRecord extends Record({
  data: IntRangeKVRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = IntRangeKVRecord(object.data);
    }

    super(updatedObject);
  }
}

export const matchStringValueRecordDefaults = {
  data: StringValueKVRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate', // keep this hardcoded, we will manipulate this.className
      data: this.data,
    };
  },
};

export const matchStringValueRecordClassFactory = (recordDefaults) => {
  return class MatchStringValueRecord extends Record(recordDefaults) {
    constructor(object) {
      let updatedObject;
  
      if (object) {
        updatedObject = Object.assign({}, object);
        updatedObject.data = StringValueKVRecord(object.data);
      }
  
      super(updatedObject);
    }
  }
}

export class ConsentGateRecord extends Record({
  data: ConsentData(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentData(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordV2 extends Record({
  data: ConsentDataV2(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataV2(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordCCPA extends Record({
  data: ConsentDataCCPA(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataCCPA(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordUSNAT extends Record({
  data: ConsentDataUSNAT(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataUSNAT(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordPreferences extends Record({
  data: ConsentDataPreferences(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataPreferences(object.data);
    }

    super(updatedObject);
  }
}

export class RegexMatchGateRecord extends Record({
  data: RegexMatchRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = RegexMatchRecord(object.data);
    }

    super(updatedObject);
  }
}

export class AdblockGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class DesktopGateRecord extends Record({
  data: ContinueOnSatisfyRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueOnSatisfyRecord(object.data);
    }

    super(updatedObject);
  }
}

export class AltpayGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SambaGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SambaCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class WelectGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.WelectCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SessionSearchReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SessionSocialReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SearchReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SocialReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class CustomCompGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.CustomCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class OptInRecoveryGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class ShowMessageAlwaysRecord extends Record({
  data: MessageIdRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = MessageIdRecord(object.data);
    }

    super(updatedObject);
  }
}

export class ShowMessageOnceRecord extends Record({
  data: MessageIdRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = MessageIdRecord(object.data);
    }

    super(updatedObject);
  }
}

export class MessageFreqCapRecord extends Record({
  data: ShowMessageFreqCapRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ShowMessageFreqCapRecord(object.data);
    }

    super(updatedObject);
  }
}

export class MessageShowNumTimesRecord extends Record({
  data: ShowMessageNTimesRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ShowMessageNTimesRecord(object.data);
    }

    super(updatedObject);
  }
}

export class MessageSessionNumLimitRecord extends Record({
  data: SessionMessageNLimit(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = SessionMessageNLimit(object.data);
    }

    super(updatedObject);
  }
}

export class DelayNumPageviewsRecord extends Record({
  data: PageviewsRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews',
  category: 'delay',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = PageviewsRecord(object.data);
    }

    super(updatedObject);
  }
}

export class DelayCircularRepeatRecord extends Record({
  data: PageviewsRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ModNDelay',
  category: 'delay',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = PageviewsRecord(object.data);
    }

    super(updatedObject);
  }
}

export class DelaySecondsRecord extends Record({
  data: TimeDelayRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay',
  category: 'delay',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = TimeDelayRecord(object.data);
    }

    super(updatedObject);
  }
}

// Enumerate our system key vals
// Add to this list to create a gate with system defined key, and system limited options
export const systemKeyVals = {
  '_sp_idfa_status' : { 
    key: '_sp_idfa_status',
    label: 'IDFA Gate', 
    options: [
      { label: 'Unset (No response registered)', value: 'unknown' },
      { label: 'Yes (Tracking allowed)', value: 'accepted' },
      { label: 'No (Tracking not allowed)', value: 'denied' }
    ]
  },
  '_sp_gpc_enabled' : {
    key: '_sp_gpc_enabled', 
    label: 'GPC Gate', 
    options: [
      { label: 'enabled', value: 'true' },
      { label: 'disabled', value: 'true', exclude: true }
    ]
  },
  'sp_lt_':{
    key: '_sp_lt_', 
    label: 'Legal Doc Update', 
  }
}

// List of each step with applicable feature flags
// List steps once for each feature flag
export const systemKeyValList = [
  { 
    ...systemKeyVals['_sp_idfa_status'],
    featureFlag: 'apple_messaging',
  },
  { 
    ...systemKeyVals['_sp_gpc_enabled'],
    featureFlag: 'ccpa',
  },
  { 
    ...systemKeyVals['_sp_gpc_enabled'],
    featureFlag: 'usnat',
  },
  { 
    ...systemKeyVals['_sp_gpc_enabled'],
    featureFlag: 'marketing_preferences',
  },
  { 
    ...systemKeyVals['_sp_gpc_enabled'],
    featureFlag: 'legal_preferences',
  }
]