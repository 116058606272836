import { CheckCircleFilled, InboxOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Flex, Form, Input, message, Modal, Row, Space, Spin, theme, Typography, Upload } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { isNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { nameFieldValidator } from "../helper";
import { renderInfoTip } from "../../us_privacy_regulation/helper";

const LegalDocumentManagementModal = (props) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [showDocNameAlert, setShowDocNameAlert] = useState(false);
  dayjs.extend(utc);

  useEffect(() => {
    if(props.docTypeGroup) {
      form.setFieldValue("documentName", props.docTypeGroup.documentName);
    }
    // const utcTime = dayjs().unix() - (dayjs().utcOffset() * 60)
    form.setFieldValue("goLiveDate", dayjs().startOf('D').unix());
  },[props.docTypeGroup])

  const onValuesChange = async (changedValues, allValues) => {
    if(changedValues.documentName) {
      try {
        await form.validateFields(["documentName"])
      } catch(err) {
        if(err.errorFields?.length && err.errorFields.some((obj) => obj.errors?.some((msg) => msg?.includes("already exists")))) {
          setShowDocNameAlert(true);
        } else {
          setShowDocNameAlert(false)
        }
      }
    }
  }

  const onFinish = (arg) => {
    const document = {
      documentName: arg.documentName,
      file: file,
      versionName: arg.versionName,
      goLiveDate: arg.goLiveDate,
      webPageLink: arg.webPageLink
    }
    // const formData = new FormData(form);
    props.handleSaveDocument(document, !props.docTypeGroup)
  }

  const onFinishFailed = (arg) => {
    //validations
  }

  const disabledDate = (current) => {
    // Can not select days after today
    return current > dayjs().endOf('day');
  };

  const { token } = theme.useToken();

  return (
    <Modal
      title={props.docTypeGroup ? `Upload New Version - ${props.docTypeGroup.documentName}` : "Add New Document"}
      onOk={form.submit}
      onCancel={props.onCancel}
      okText="Save"
      open={props.open}
      destroyOnClose={true}
      confirmLoading={props.loading}
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex gap={token.margin} justify="flex-end" align="center">
          {showDocNameAlert ? <Alert type="warning" showIcon message="To upload a new version under an existing document go to the homepage and use the upload button next to the relevant document name."/> : null}
          <Space>
            <CancelBtn />
            <OkBtn />
          </Space>
        </Flex>
      )}
      width={720}
    >
      <Spin spinning={props.loading}>
      <Form
        form={form}
        name="ldms"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={"optional"}
        // validateTrigger={"onSubmit"}
      >
        <Row gutter={[20, 16]}>
          <Col span={24}>
            <Form.Item
              name={"file"}
              label="Upload file"
              rules={[
                {
                  required: true,
                  message: "Please upload a document"
                },
              ]}
              getValueProps={(value) => {
                if(typeof value ===  'Blob') {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(value)
                  reader.onload = e => {
                    return ({
                      value: e.target.value,
                    })
                  }
                }
                return ({
                  value: value,
                })
              }}
            >
                <Upload.Dragger
                  name="file"
                  accept=".jpg,.jpeg,.pdf,.doc,.docx,.xml,.xps,.txt,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,text/plain"
                  multiple={false}
                  beforeUpload={(file) => {
                    // Prevent automatic upload
                    return false;
                  }}
                  onChange={(info) => {
                    const acceptedTypes = [
                      'image/jpeg',
                      'application/pdf',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'text/plain',
                    ];
                    const fileSizeInMB = parseFloat((info?.file?.size / (1024 * 1024)).toFixed(2));
                    if (fileSizeInMB > 1) { 
                      message.error('File size exceeds the 5MB limit');
                      return;
                    }
                    const isAcceptedFileType = acceptedTypes.includes(info?.file?.type);
                    if (!isAcceptedFileType) {
                      message.error('Upload a supported file type: PDF, TXT, DOCX or JPG');
                      form.setFieldValue('file', null); // Reset the form field
                    } else {
                      setFile(info.file);
                      form.setFieldValue('versionName', info.file.name); // Set the filename in the form
                    }
                  }}
                  showUploadList={false}
                >
                <p className="ant-upload-drag-icon">
                  {file ? <CheckCircleFilled style={{color: token.colorSuccess}}/> : <InboxOutlined />} 
                </p>
                <p className="ant-upload-text">
                  {file ? <><PaperClipOutlined/> {file.name}</> : "Click or drag file to this area to upload"} 
                </p>
                <p className="ant-upload-hint">
                  {file ? <Button type="default">Re-upload</Button>: "Supports single file upload only. Supported file types: PDF, TXT, DOCX, JPG."}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={props.docTypeGroup ? 0 : 12}>
            <Form.Item label={<>Document name {renderInfoTip("The name of the new document category you're creating.")}</>} required>
              <Space direction="vertical" size={token.marginXXS}>
                <Form.Item
                  name={"documentName"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Document Name"
                    },
                    {
                      validator: (_,value) => nameFieldValidator(value, props.documentNames, "Document name")
                    }
                  ]}
                  hidden={props.docTypeGroup}
                  required={true}
                >
                  <Input maxLength={255} disabled={!Boolean(form.getFieldValue("file"))} showCount={true}/>
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<>Version name{renderInfoTip("Defaults to the file name but can be edited. Helps track document versions.")}</>}
              name={"versionName"}
              rules={[
                {
                  required: true,
                  message: "Please enter Version Name"
                },
                {
                  validator:(_,value) => nameFieldValidator(value, props.docTypeGroup?.documents?.map((doc) => doc.versionName), "Version name")
                }
              ]}
              required={true}
            >
              <Input maxLength={255}  disabled={!Boolean(form.getFieldValue("file"))} showCount={true}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<Typography.Text>Go Live Date <Typography.Text type="secondary">(in UTC)</Typography.Text> {renderInfoTip("The date the file goes live. Cannot be identical with the live date of an existing document.")}</Typography.Text>}
              name={"goLiveDate"}
              getValueProps={(value) => ({
                value: isNumber(value) ? dayjs.unix(value) : value,
              })}
              normalize={(value) => {
                return value && dayjs(value).unix()
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter Go live date"
                },
                {
                  validator: (_, value) => {

                    const docGoLiveDates = props.docTypeGroup?.documents?.map((doc) => dayjs(doc.goLiveDate).unix())
                    if(docGoLiveDates && docGoLiveDates.includes(value)) {
                      return Promise.reject(new Error("Duplicate Go Live Date"))
                    }
                  
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={true}
                disabled={!Boolean(form.getFieldValue("file"))}
                disabledDate={disabledDate}
                popupClassName="ldms-go-live-date"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<>Webpage Link{renderInfoTip(" The link to the document that users will see.")}</>}
              required={false}
              name={"webPageLink"}
              rules={[
                {
                  type: "url"
                }
              ]}
            >
              <Input disabled={!Boolean(form.getFieldValue("file"))}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Spin>
    </Modal>
  );
}

export default LegalDocumentManagementModal;