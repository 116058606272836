import 'whatwg-fetch';
import { checkStatus, checkStatusAndGetMultErrors, toFormData, CSRF_TOKEN, isFetched, addToFetched, buildUrlWithVersion,DIAGNOSE_SDK_BASEURL } from './helper.js';
import { User, Domain, UserCreadentials, Account } from '../records/account_records';
import { List } from 'immutable';
import { FETCHED } from '../constants';
import { updateAccount } from '../api/admin_consent';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

export function getUserDetailsAjax() {
    const url = `${permissionsSvcBaseUrl}/users/details`;
    // if (isFetched(url)) {
    //   return Promise.resolve(FETCHED);
    // }
    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          addToFetched(url);
          return new User(resp)
        });
}

export function getAccountDomainsAjax() {
  const url = `${permissionsSvcBaseUrl}/accounts/domains`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
  }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new List([resp]);
      });
}

export function getAccountOwnersAjax() {
  const url = `${permissionsSvcBaseUrl}/accounts/`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
  }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new List(resp.map(a => new Account(a)));
      });
}

export function getALLSDKKeysAjax(accountId) {
   const url = `${DIAGNOSE_SDK_BASEURL}/api/getAPIKey/`
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }

  const body = {}
// return Promise.resolve(keysResponse.data)
  return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
      
  }).then(resp => resp.json()) 
      .catch((err) => {
        console.log(err);
          alert(err.response);
  });
 }


export function generateSDKKeyAjax(account_id){
  const url = `${DIAGNOSE_SDK_BASEURL}/api/generateAPIKey/`
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  const body = {
    account_id: account_id
  }
  //return Promise.resolve(keyAdded.data)
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
})
.then(checkStatus)
.then(resp => resp.json())
.catch((err) => {
    console.log(err);
    alert(err.response);
  });
}


// export const getAllAccountsWithoutUserAjax = () => {
//   const url = `${permissionsSvcBaseUrl}/accounts/`;
//   return fetch(url, {
//     credentials: 'include',
//   }).then(checkStatus)
//     .then(resp => resp.json())
//     .then(resp => {
//       return new List(resp.map(user => { return { accountId: user.account_id, accountName: user.account_name }}));
//     });
// };

export function getAccountPermissionsAjax() {
  // get all users with permissions
  const url = `${permissionsSvcBaseUrl}/accounts/permissions/list`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
  }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new List(resp.map(user => new User(user)));
      });
}

export function getPermissionsListAjax() {
  const url = `${permissionsSvcBaseUrl}/entityPermissions`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
  }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new List(resp.filter(p => p.name !== 'Privacy Lens').map(p => { 
          return {
            _id: p._id,
            name: p.name,
            description: p.description,
          }
        }));
      });
}

export function getDiagnosePropertiesAjax() {
  // list of diagnose properties
  const url = buildUrlWithVersion(`${permissionsSvcBaseUrl}/diagnose-dashboard/prod/websites/`, { allSites: true }, 'summary');
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
  }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new List(resp.map(p => { 
          return {
            _id: p.value,
            name: p.title,
          }
        }));
      });
}

export function updateAccountPermissionsAjax(permissions) {
  const url = `${permissionsSvcBaseUrl}/accounts/permissions/list`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  }
  return fetch(url, {
      method: 'put',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: permissions,
  }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new User(resp);
      });
}

export function changeUserAccessAjax(user, currentUserId) {
  const updatedPermissions = {
    userId: user.id,
    email: user.email,
    name: user.name,
    adminUserId: currentUserId,
    isAdmin: user.isAdmin,
    siteIds: [],
    entityAccessPermissions: [],
    accountIds: [],
    allSiteAccess: false,
    allFeatureAccess: false,
    allAccountAccess: false,
    allDiagnoseSiteAccess : false,
    diagnoseSiteIds : [],
  };

  (user.siteAccess === 'Access to all properties' || user.siteAccess === 'all access') ? updatedPermissions.allSiteAccess = true : updatedPermissions.siteIds = user.siteAccess;
  user.featureAccess === 'all access' ? updatedPermissions.allFeatureAccess = true : updatedPermissions.entityAccessPermissions = user.featureAccess;
  user.accountAccess === 'all access' ? updatedPermissions.allAccountAccess = true : updatedPermissions.accountIds = user.accountAccess.map(id => parseInt(id));
  user.diagnoseAccess === 'all access'? updatedPermissions.allDiagnoseSiteAccess = true : updatedPermissions.diagnoseSiteIds = user.diagnoseAccess;

  const url = `${permissionsSvcBaseUrl}/accounts/permissions`

  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedPermissions),
  }).then(checkStatus)
    .then(resp => resp.json())
    .then((user) => {
      return new User(user);
    });
}

export function inviteUserAjax(name, email, isAdmin, siteAccess, features,diagnoseSites) {

  const data = {
    email,
    name,
    adminAccess: isAdmin,
    'siteIds': [],
    'entityAccessPermissions': [],
    'allSiteAccess': false,
    'allAccountAccess': false,
    'allFeatureAccess': false,
    'allDiagnoseSiteAccess':false,
    'diagnoseSiteIds':[],
  }

  siteAccess === 'all access' ? data.allSiteAccess = true : data.siteIds = siteAccess;
  features === 'all access' ? data.allFeatureAccess = true : data.entityAccessPermissions = features;
  diagnoseSites === 'all access' ? data.allDiagnoseSiteAccess = true : data.diagnoseSiteIds = diagnoseSites;
    return fetch(`${permissionsSvcBaseUrl}/accounts/invite-user`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(checkStatus)
      .then(async resp => {
        const json = await resp.json();
        const status = resp.status;
        return new User({ ...json, inviteSent: status !== 202})
      })
}

export function deleteUserAjax(userId) {
    return fetch(`${permissionsSvcBaseUrl}/accounts/user`, {
        method: 'delete',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
        })
    }).then(checkStatus)
        .then(() => userId)
}

export function createAccountAjax(obj) {
    const data = {
      accountName: obj.accountName,
      name: obj.name,
      email: obj.email,
      clientIdentifier: obj.name,
    }

    return fetch(`${permissionsSvcBaseUrl}/accounts`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(checkStatusAndGetMultErrors)
        .then(resp => resp.json());
}

export function updateUserAjax(obj) {
  const data = {
    currentPassword: obj.currentPassword
  };
  if (obj.password) data.password = obj.password;
  if (obj.name) data.name = obj.name;

  return fetch(`${permissionsSvcBaseUrl}/users`, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(checkStatusAndGetMultErrors)
    .then(resp => resp.json())
    .then((user) => new User(user))
}

export function getUserDomainsAjax() {  
  return fetch(`${permissionsSvcBaseUrl}/accounts/domains`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    }).then(checkStatus)
      .then(resp => resp.json())
      .then((domains) => {
          return List(domains).map(d => new Domain(d));
      })
}

export function deleteDomainAjax(id) {
  const url = `${permissionsSvcBaseUrl}/accounts/domains`;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id })
    }).then(checkStatus)
        .then(() => id)
}


export function addDomainAjax(domain) {
  
  const url = `${permissionsSvcBaseUrl}/accounts/domains`;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ domain }),
    }).then(checkStatus)
        .then(resp => resp.json())
        .then((d) => new Domain(d));
}

export const getAllAccountsAjax = () => {
  const url = `${permissionsSvcBaseUrl}/accounts/owners`;
  return fetch(url, {
    credentials: 'include',
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new List(resp.map(user => { return { accountId: user.account_id, accountName: user.account_name }}));
    });
};

//for Google Ad Manager free scan form
export function freeScanDetailsAjax(
  data
  ) {
    const url = `https://4iju5q9ju5.execute-api.eu-west-2.amazonaws.com/prod/submit`;
  
    return fetch(url, {
      method: "POST",
      credentials: "omit",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((resp) => resp.json())
      .then((result) => {
        return result;
      })
      .catch((err) => {
        return err;
      });
  }
  //End 
// export const getAllAccountsWithoutUserAjax = () => {
//   const url = `${permissionsSvcBaseUrl}/accounts/`;
//   return fetch(url, {
//     credentials: 'include',
//   }).then(checkStatus)
//     .then(resp => resp.json())
//     .then(resp => {
//       return new List(resp.map(user => { return { accountId: user.account_id, accountName: user.account_name }}));
//     });
// };


