import { checkStatus, DIAGNOSE_SDK_BASEURL } from '../helper.js';

const getBaseUrl = () => {
    return `${DIAGNOSE_SDK_BASEURL}/api/`;
};

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

const sampleData = {
    transactions: [
        {
            transactionId: 123456,
            type: 'UCPM',
            system: 'SP',
            messageId: 1001,
            property: 'Property1',
            vendorListId: 'VendorList01',
            timestamp: new Date('2024-12-01T10:30:00Z'),
            detailsJSON: {
                categories: [
                    {
                        id: 'cat01',
                        name: 'Marketing Preferences',
                        changed: true,
                        type: 'MARKETING',
                        subType: 'PRIVACY-POLICY',
                        iabId: 101,
                        systemId: 2001,
                        legalDoc: {
                            id: 'legal01',
                            name: 'Privacy Policy',
                            goLiveDate: new Date('2024-11-01'),
                        },
                    },
                    {
                        id: 'cat02',
                        name: 'Category 2',
                        changed: true,
                        type: 'MARKETING',
                        subType: 'PRIVACY-POLICY',
                        iabId: 101,
                        systemId: 2001,
                        legalDoc: {
                            id: 'legal01',
                            name: 'Privacy Policy',
                            goLiveDate: new Date('2024-11-01'),
                        },
                    },
                ],
                vendors: [
                    {
                        id: 'vendor01',
                        name: 'Google',
                        type: 'IAB',
                        googleId: 'GOOG123',
                    },
                ],
            },
        },
        {
            transactionId: 123457,
            type: 'GDPR',
            system: 'BRAZE',
            messageId: 1002,
            property: 'Property2',
            vendorListId: 'VendorList02',
            timestamp: new Date('2024-12-02T11:00:00Z'),
            detailsJSON: {
                categories: [
                    {
                        id: 'cat02',
                        name: 'Legal Compliance',
                        changed: false,
                        type: 'LEGAL-TRANSACTION',
                        subType: 'TERMS AND CONDITIONS',
                        iabId: 102,
                        systemId: 2002,
                        legalDoc: {
                            id: 'legal02',
                            name: 'Terms and Conditions',
                            goLiveDate: new Date('2024-11-15'),
                        },
                    },
                ],
            },
        },
        {
            transactionId: 123458,
            type: 'USP',
            system: 'HUBSPOT',
            messageId: 1003,
            property: 'Property3',
            vendorListId: 'VendorList03',
            timestamp: new Date('2024-12-03T09:45:00Z'),
            detailsJSON: {
                categories: [
                    {
                        id: 'cat03',
                        name: 'Special Features',
                        changed: true,
                        type: 'SPECIAL-FEATURES',
                        subType: 'AI-POLCY',
                        iabId: 102,
                        systemId: 2002,
                    },
                ],
                vendors: [
                    {
                        id: 'vendor02',
                        name: 'Facebook',
                        type: 'CUSTOM',
                        googleId: 'FB123',
                    },
                ],
            },
        },
        {
            transactionId: 123459,
            type: 'UCPM',
            system: 'MAILCHIMP',
            messageId: 1004,
            property: 'Property4',
            vendorListId: 'VendorList04',
            timestamp: new Date('2024-12-04T14:20:00Z'),
            detailsJSON: {
                categories: [
                    {
                        id: 'cat04',
                        name: 'Marketing Analytics',
                        changed: false,
                        type: 'MARKETING',
                    },
                ],
            },
        },
        {
            transactionId: 123460,
            type: 'GDPR',
            system: 'SP',
            messageId: 1005,
            property: 'Property5',
            vendorListId: 'VendorList05',
            timestamp: new Date('2024-12-05T16:50:00Z'),
            detailsJSON: {
                categories: [
                    {
                        id: 'cat05',
                        name: 'Consent Updates',
                        changed: true,
                        type: 'LEGAL-TRANSACTION',
                    },
                ],
                vendors: [
                    {
                        id: 'vendor03',
                        name: 'Amazon',
                        type: 'IAB',
                        googleId: 'AMZ123',
                    },
                ],
            },
        },
        {
            transactionId: 1234510,
            type: 'USP',
            system: 'BRAZE',
            messageId: 1006,
            property: 'Property6',
            vendorListId: 'VendorList06',
            timestamp: new Date('2024-12-06T08:15:00Z'),
            detailsJSON: {
                categories: [
                    {
                        id: 'cat06',
                        name: 'Policy Changes',
                        changed: false,
                        type: 'SPECIAL-FEATURES',
                        subType: 'PRIVACY-POLICY',
                    },
                ],
            },
        },
    ],
};





export function getTransactionHistoryAjax(searchBy,authId) {
    const url = searchBy === 'client-id'?`/users/history?clientId=`: '/users/history?uuid=';
    //return Promise.resolve(sampleData.transactions)
    return fetch(permissionsSvsBase + url+authId,
        {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp?.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on create Get transaction lists data: status ${err.response.status}` : err);
        });
}