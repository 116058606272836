import { Map } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
    transactionHistory: new StoreRecord([]),
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_TRANSACTION_HISTORY_PENDING:
            return setPending(state, ['transactionHistory']);

        case types.GET_ALL_TRANSACTION_HISTORY_LOADED:
            return setValue(state, ['transactionHistory'], action.transactionList);

        case types.GET_ALL_TRANSACTION_HISTORY_ERROR:
            return setError(state, ['transactionHistory'], action.errorMessage);
    }
    return state;
};