import React from 'react';
import { Select } from '../../styleguide';
import _ from 'lodash';
import { CAMPAIGNS_MAP as CAMPAIGNS } from '../../constants';
import { hasFeature } from '../utils';
const { Option } = Select;

function CampaignTypeDropdown({ hideLegacyPlaceholder, currentUser, propertyType, updateCampaignType }) {
  const getCampaignDropDownOptions = () => {
    let campaignsTypes = _.omit(CAMPAIGNS, 0);

    const hasTcfv2 = hasFeature(currentUser, 'tcf_v2');
    const hasCcpa = hasFeature(currentUser, 'ccpa');
    const hasUsnat = hasFeature(currentUser, 'usnat');
    const hasPreferences = hasFeature(currentUser, 'marketing_preferences') || hasFeature(currentUser, 'legal_preferences');
    const hasAdblock = hasFeature(currentUser, 'adblock_v2');
    const hasAppleMessaging = hasFeature(currentUser, 'apple_messaging');
    const hasCustomMessaging = hasFeature(currentUser, 'custom_messages');

    if (!hasTcfv2) {
      delete campaignsTypes[1];
    }
    if (!hasCcpa) {
      delete campaignsTypes[2];
    }
    if (!hasUsnat) {
      delete campaignsTypes[6];
    }
    if (!hasPreferences) {
      delete campaignsTypes[7];
    }
    if (!hasAdblock || propertyType === 'ott' || propertyType === 'app') {
      delete campaignsTypes[3];
    }
    if (!hasAppleMessaging || propertyType === 'ott' || propertyType === 'web') {
      delete campaignsTypes[4];
    }
    if (!hasCustomMessaging) {
      delete campaignsTypes[5];
    }

    return campaignsTypes;
  };

  const campaignsTypes = getCampaignDropDownOptions();

  return (
    <Select 
      placeholder="Select"
      className="campaign-types-select-dropdown"
      defaultValue={hideLegacyPlaceholder ? '' : 'No Type'} 
      onChange={(value) => updateCampaignType(value)}>
      {Object.keys(campaignsTypes).map((key) => (
        <Option key={key} value={Number(key)}>
          {CAMPAIGNS[key]}
        </Option>
      ))}
    </Select>
  );
}

export default CampaignTypeDropdown;
