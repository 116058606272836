/* eslint-disable camelcase */
/* eslint-disable no-alert */
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, { retries: 2, retryDelay: 500 });
import { message } from "antd";
import { checkStatus, buildUrlWithVersion, DIAGNOSE_DASHBOARD_BASEURL } from '../helper.js';

const GET_CLIENT_WEBSITES = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/websites/`;
const GET_CLIENT_PAGES = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/pages/`;
const GET_PROXY_TOOLS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/proxy-tools/`;
const GET_CONSENT_CATEGORIES = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/consent-categories/`;
const GET_POST_CLIENT_SCANS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scans/`;
const GET_POST_CLIENT_SCAN_DETAILS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scan/`;
const TOGGLE_CLIENT_SCAN = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scan/toggle/`;
const DELETE_CLIENT_SCAN = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scan/delete/`;
const GET_POST_CLIENT_BULK_SCANS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/bulk_scans/`;
const GET_BULK_SCANS_FILTERS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/bulk_scans/filters/`;
const GET_POST_CLIENT_BULK_SCAN_DETAILS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/bulk_scans/website/`;
const GET_BULK_SCANS_DETAILS_FILTERS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/bulk_scans/website/filters/`;
const GET_BULK_UPLOAD_SITEMAP_CSV = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/generate-bulk-template/url/`;
const UPLOAD_BULK_SCAN = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/upload/`;
const UPLOAD_SITEMAP_CSV = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/bulk/generate-bulk-template/`;   

function fetchWrapper(url, queryParams = {}, method = "get", body = undefined, response = "json") {
  return fetch(buildUrlWithVersion(url, queryParams, "scan-setup"), {
    method,
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  })
    .then(checkStatus)
    .then((resp) =>{
      if(response === "text"){
        return resp.text();
      }
      else{
        return  resp.json();
      }
    })
    .catch((err) => {
      console.log(err);
      const errorMessage = err.response
        ? `Error on ${method} ${{
          [GET_CLIENT_WEBSITES]: "Scan websites",
          [GET_CLIENT_PAGES]: "Scan website pages",
          [GET_PROXY_TOOLS]: "Scan proxy tools",
          [GET_CONSENT_CATEGORIES]: "Scan consent categories",
          [GET_POST_CLIENT_SCANS]: "Scans",
          [GET_POST_CLIENT_SCAN_DETAILS]: "Scan details",
          [TOGGLE_CLIENT_SCAN]: "Play/Pause scan",
          [DELETE_CLIENT_SCAN]: "Delete scan",
          [GET_POST_CLIENT_BULK_SCANS]: "Bulk Scans",
          [GET_BULK_SCANS_FILTERS]: "Bulk Scans Filters",
          [GET_POST_CLIENT_BULK_SCAN_DETAILS]: "Bulk Scan Details",
          [GET_BULK_SCANS_DETAILS_FILTERS]: "Bulk Scan Details Filters",
          [GET_BULK_UPLOAD_SITEMAP_CSV]: "Generate Bulk Upload Sitemap Url"
        }[url]
        } data: status ${err.response.status}`
        : err.message;
      message.error(errorMessage, 5);
      // eslint-disable-next-line no-throw-literal
      throw new Error("Unable to get scan data for selected account");
    });
}

export function getAccountScans(queryParams = {}, body) {
  return fetchWrapper(GET_POST_CLIENT_SCANS, queryParams, "post", body);
}

export function getAccountScanDetails(queryParams) {
  return fetchWrapper(GET_POST_CLIENT_SCAN_DETAILS, queryParams);
}

export function getAccountWebsites(queryParams) {
  return fetchWrapper(GET_CLIENT_WEBSITES, queryParams);
}

export function getAccountWebsitePages(queryParams) {
  return fetchWrapper(GET_CLIENT_PAGES, queryParams);
}

export function getConsentCategories(queryParams) {
  return fetchWrapper(GET_CONSENT_CATEGORIES, queryParams);
}

export function getProxyTools(queryParams) {
  return fetchWrapper(GET_PROXY_TOOLS, queryParams);
}

export function createAccountScan(queryParams = {}, body) {
  return fetchWrapper(GET_POST_CLIENT_SCAN_DETAILS, queryParams, "post", body);
}

export function updateAccountScan(queryParams = {}, body) {
  return fetchWrapper(GET_POST_CLIENT_SCAN_DETAILS, queryParams, "PUT", body);
}

export function toggleAccountScan(queryParams = {}, body) {
  return fetchWrapper(TOGGLE_CLIENT_SCAN, queryParams, "post", body);
}

export function deleteAccountScan(queryParams = {}) {
  return fetchWrapper(DELETE_CLIENT_SCAN, queryParams, "post");
}

export function getBulkScanListData(queryParams = {}, body){
  return fetchWrapper(GET_POST_CLIENT_BULK_SCANS, queryParams, "post", body);
}

export function getBulkScansFilters(queryParams = {}){
  return fetchWrapper(GET_BULK_SCANS_FILTERS, queryParams, "post");
}

export function getAccountBulkScanDetails(queryParams = {}, body) {
  return fetchWrapper(GET_POST_CLIENT_BULK_SCAN_DETAILS, queryParams, "post", body);
}

export function getAccountBulkScansDetailFilters(queryParams = {}, body){
  return fetchWrapper(GET_BULK_SCANS_DETAILS_FILTERS, queryParams, "post", body);
}

export function getSitemapTemplateCsv(queryParams = {}) {
  return fetchWrapper(GET_BULK_UPLOAD_SITEMAP_CSV, queryParams, "post", undefined, "text");
}


export function uploadBulkScanCsv(file){
  
  if(!file){
    throw new Error("No file provided for upload.");
  }

  const formData = new FormData();
  formData.append("file",file);

  return fetch(buildUrlWithVersion(UPLOAD_BULK_SCAN,{},"scan-setup"),{
    method: "POST",
    credentials: "include",
    mode: "cors",
    body: formData
  })
  .then(checkStatus)
  .then((res) => res.json())
  .catch((err) => {
    console.error(err);
    const errorMessage = err.response
      ? `Error on POST Upload Bulk Scan: status ${err.response.status}`
      : err.message;
    message.error(errorMessage, 5);
    // eslint-disable-next-line no-throw-literal
    throw new Error("Unable to upload the bulk scan file.");
  });
}


export const uploadSitemap = async (file) => {
  if (!file) throw new Error("No file provided for upload.");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(buildUrlWithVersion(UPLOAD_SITEMAP_CSV, {}, "scan-setup"), {
    method: "POST",
    credentials: "include",
    mode: "cors",
    body: formData,
  })
  .then(checkStatus)
  .then((res) => res.text())
  .catch((err) => {
    console.error(err);
    const errorMessage = err.response
      ? `Error on POST Upload Sitemap Template: status ${err.response.status}`
      : err.message;
    message.error(errorMessage, 5);
    // eslint-disable-next-line no-throw-literal
    throw new Error("Unable to upload the sitemap file.");
  });

};
