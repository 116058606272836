import {
  Alert,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getSitemapTemplateCsv,
  uploadSitemap,
} from "../../../api/diagnose_dashboard/scan_setup.js";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Text } = Typography;



function UploadSiteMapModal({
  accountId,
  uploadCsvModalType,
  onClose,
  refreshBulkAccountScans,
}) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ type: "", message: "" });

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const beforeUploadXml = async (file) => {
    setUploadedFile(file); 
    return false;
  };

  const uploadProps = {
    name: "file",
    accept: ".xml",
    maxCount: 1,
    beforeUpload: beforeUploadXml,
  };

  const handleSave = async () => {
    if (!uploadedFile) {
      message.error("Please upload an XML file before saving.");
      return;
    }
  
    // if (errors.length > 0) {
    //   message.error("Please fix the errors in your file and re-upload.");
    //   return;
    // }
  
    try {
      setIsUploading(true);
      const response = await uploadSitemap(uploadedFile);
      message.success("File uploaded successfully!");
      refreshBulkAccountScans();
      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Failed to upload the file.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values) => {
    const url = values.url;
    try {
      const response = await getSitemapTemplateCsv({ accountId, url });

      if (response) {
        const blob = new Blob([response], { type: "text/csv" });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = "sitemap_template.csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setStatusMessage({
          type: "success",
          message:
            "The sitemap_template.csv file has been successfully downloaded.",
        });
        // setTimeout(() => {
        //   setStatusMessage({ type: "", message: "" });
        // }, 5000);
      }
    } catch (error) {
      console.error("Failed to load data:", error);
      setStatusMessage({
        type: "error",
        message: "Failed to get data for the required sitemap.",
      });
    }
  };

  return (
    <Modal
      title="Upload Site Map"
      open={!!uploadCsvModalType}
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={isUploading}
      okButtonProps={{
        disabled:  !uploadedFile,
      }}
      cancelButtonProps={{
        type: "link",
      }}
      width={720}
    >
      <div className="bulk-scan-sitemap">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <span>Link</span>
          </Col>
          <Form
            name="upload-sitemap-url"
            onFinish={handleSubmit}
            form={form}
            onFieldsChange={() => {
              if (statusMessage.message)
                setStatusMessage({ type: "", message: "" });
            }}
          >
            <Flex align="center" justify="space-between" gap={12}>
              <Form.Item
                name="url"
                rules={[
                  {
                    validator: (_, value) => {
                      const xmlUrlPattern =
                        /^(https?:\/\/)[\w.-]+(\.[\w.-]+)+\/?.*\.xml$/i;
                      if (!xmlUrlPattern.test(value)) {
                        return Promise.reject(
                          new Error("Please enter a valid .xml URL!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                help={statusMessage.message || undefined}
                validateStatus={statusMessage.type || undefined}
              >
                <Input
                  placeholder="Paste sitemap link"
                  style={{ width: "465px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="submit"
                  disabled={!submittable}
                >
                  <DownloadOutlined />
                  Download CSV File
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </Row>
        <Divider />
        <Row>
          <span>Import File</span>
        </Row>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file(s) to this area to upload
          </p>
        </Dragger>
        {/* {errors.length > 0 && (
          <div>
            <Alert
              message={
                <Row>
                  <Col span={24}>
                    <strong>
                      {uploadedFile?.name}:Maximum URL limit exceeded
                    </strong>
                  </Col>
                  <Col span={24}>
                    <Text>{errors}</Text>
                  </Col>
                </Row>
              }
              type="error"
              showIcon
              closable
            />
          </div>
        )} */}
      </div>
    </Modal>
  );
}

export default UploadSiteMapModal;
