import React, { useState, useEffect, useMemo } from 'react';
import { Input, Select } from 'antd';
import { COUNTRY_LANGUAGES, defaultDisclosureText } from '../../../../../../../constants';

const { Option } = Select;

const InputLanguage = ({
  updateSetting,
  value,
  defaultLanguage,
  settingsTitle,
  settingKey,
  messageSubcategory,
  messageCategory,
  allSettings,
  sites,
  siteId,
  preferencesLists
}) => {
  const { languages = {}, categories = { ids: [] }, } = allSettings;
  const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
  const [valueModified, setValueModified] = useState({});
  const [selectedCat, setSelectedCat] = useState(null);
  const [prevCategoryIds, setPrevCategoryIds] = useState(categories?.ids);

  const configuration = useMemo(() => {
    if (settingKey !== 'categoryName' && settingKey !== 'categoryDescription') return null;

    const sitesJS = sites?.toJS ? sites.toJS() : sites;
    const siteIds = sitesJS?.length ? sitesJS : [Number(siteId)];

    return preferencesLists.find(({ propertyIds, isActive }) =>
      propertyIds.some(id => siteIds.includes(id)) && isActive
    );
  }, [preferencesLists, sites, siteId]);

  useEffect(() => {
    if (Object.keys(languages).length === 0) {
      updateText({ target: { value } });
    }
  }, [languages, value]);

  useEffect(() => {
    if (JSON.stringify(prevCategoryIds) !== JSON.stringify(categories?.ids)) {
      removePreviousValues();
      setPrevCategoryIds(categories?.ids);
    }
  }, [categories?.ids]);

  const updateText = ({ target: { value } }) => {
    const languagesClone = { ...languages };
    if (!languagesClone[selectedLanguage]) {
      languagesClone[selectedLanguage] = {};
    }
    languagesClone[selectedLanguage][settingKey] = value;

    if (messageSubcategory !== 'native-ott' || (messageSubcategory === 'native-ott' && defaultLanguage !== selectedLanguage)) {
      updateSetting('languages', languagesClone);
    } else {
      updateSetting(settingKey, value, { languages: languagesClone });
    }

    if (["consentDisclosure", "categoryName", "categoryDescription"].includes(settingKey) && value) {
      setValueModified(prev => ({
        ...prev,
        [selectedLanguage]: true
      }));
    }
  };

  const removePreviousValues = () => {
    const languagesClone = { ...languages };
    Object.keys(languagesClone).forEach((lang) => {
      delete languagesClone[lang][settingKey];
    });
    updateSetting('languages', languagesClone);
    setValueModified({});
    if (categories.ids.length === 1) {
      const selectedCatSetting = configuration?.categories?.find(({ categoryId }) => parseInt(categoryId) === categories.ids[0]);
      setSelectedCat(selectedCatSetting);
    } else {
      setSelectedCat(null);
    }
  }

  const langValue = languages[selectedLanguage]?.[settingKey];
  const currentValue = langValue ?? (defaultLanguage === selectedLanguage ? value : '');

  const defaultConsentDisclosure = defaultDisclosureText[selectedLanguage.toLowerCase()] || '';

  const getTranslation = (kind) => {
    return selectedCat?.translations?.[kind][defaultLanguage === selectedLanguage ? 'default' : selectedLanguage.toLowerCase()];
  }

  const getInputField = () => {
    if (settingKey === "consentDisclosure") {
      return (
        <Input.TextArea
          value={valueModified[selectedLanguage] ? currentValue : currentValue || defaultConsentDisclosure}
          onChange={updateText}
          autoSize
        />
      );
    }

    if (messageCategory === 'preferences' && selectedCat) {
      if (settingKey === 'categoryName') {
        return <Input value={valueModified[selectedLanguage] ? currentValue : currentValue || getTranslation('names')} onChange={updateText} />;
      }
      if (settingKey === 'categoryDescription') {
        return <Input value={valueModified[selectedLanguage] ? currentValue : getTranslation('descriptions')} onChange={updateText} />;
      }
    }

    return <Input value={currentValue} onChange={updateText} />;
  };

  return (
    <div className="input-language-container">
      <div className="set-input set-languages">
        <h4>{settingsTitle}</h4>
        {getInputField()}
      </div>
      <div className="four-options">
        <div className="text-language">
          <h4>Language</h4>
          <Select
            value={selectedLanguage}
            onChange={(language) => updateSetting('selectedLanguage', language)}
          >
            {COUNTRY_LANGUAGES.map(({ code, language }) => <Option key={code}>{language}</Option>)}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default InputLanguage;
