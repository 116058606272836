import React, {useState} from 'react';
import { Input, Modal, Typography, Row, Col, Button, Space, Flex, Alert, theme, Tag } from 'antd';
import { RenderLimitedChips } from "../../../../../../styleguide"
const { Text } = Typography;

const AddCustomChoicesModal = (props) => {

  const {
    openCustomModal,
    handleCancel,
    addCategory,
    currentRecord,
    editCustomChoice
  } = props;

  const { token } = theme.useToken()
  const [categoryNameError, setCategoryNameError] = useState(null);
  const [customChoice, setCustomChoice] = useState(currentRecord?.privacyChoice ?? "")
  const customChoicesTags = ['Strictly Necessary', 'Performance or analytics', 'Functionality or personalization', 'Targeted Advertising']
  
  const handleAddCustomChoices = () => {
    const isChoiceEmpty = customChoice?.trim()?.length === 0;
    const isChioceDuplicate = props.regulationCategories.filter(cat => cat.privacyChoice !== currentRecord?.privacyChoice)?.some(al => al.privacyChoice === customChoice.trim());
    if(isChoiceEmpty || isChioceDuplicate) {
      setCategoryNameError(
        isChoiceEmpty
          ? "Please enter Choice Name"
          : isChioceDuplicate
          ? <>Choice with name <b>{customChoice}</b> already exists</>
          : null
      );
      return null;
    }
    currentRecord ? editCustomChoice(customChoice, currentRecord.id) : addCategory(customChoice)
  }

  return (
    <Modal
      title={ currentRecord ? "Edit Custom Choice" : "Add Custom Choice"}
      open={openCustomModal}
      width={"720px"}
      closable={true}
      destroyOnClose={true}
      onCancel={() => handleCancel('custom')}
      onOk={handleAddCustomChoices}
      okText={currentRecord ? "Save" : "Add"}
      cancelButtonProps={{type: "link"}}
    >
      <Row gutter={[0, 16]} className='row-gutter'>
        <Col span={24}>
          <Space direction="vertical" size={token.marginXXS}>
            <Text>Choice Name</Text>
            <Input
              value={customChoice}
              placeholder="Enter Choice Name"
              style={{ padding: `0px,${token.padding}px`, width: "334px" }}
              onChange={(e) => setCustomChoice(e.target.value)}
              maxLength={255}
              showCount={true}
            />
            <Text type='danger'>{categoryNameError}</Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space size={[0, token.paddingSM]} wrap>
          {customChoicesTags.map(choice => {
            return <Tag style={{ cursor: 'pointer'}} onClick={() => setCustomChoice(choice)}>{choice}</Tag>
          })}
          </Space>
        </Col>
      </Row>
    </Modal>
  )

}

export default AddCustomChoicesModal
