import React, { useState,useEffect } from 'react';
import { Table, Checkbox, Input, Select, Flex, Button } from 'antd';
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { COUNTRY_LANGUAGES } from '../../../../../../../constants';

const { Option } = Select;

const ErrorInfoTable = (props) => {
  const { supportedLanguages, components,textComponentsWithTranslations, data,handleDataUpdate,fileData } = props
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState(data)
  const [hasErrors, setHasErrors] = useState(false);

  console.log(fileData)

  const checkDuplicate = (row, data) => {
    return data.filter(item => item.component?.split("-")[0] === row.component?.split("-")[0] && item.language === row.language).length > 1;
  };

  const getErrorStatus = (row, data) => {
    if (row?.componentError || row?.languageError) {
      return "Invalid Data"
    } else if(row?.translationError) {
      return "Missing Data"
    } else if (checkDuplicate(row, data)) {
      return "Duplicate Data"
    } else {
      return "Resolved"
    }
  }


  const updateTableData = (updatedData) => {
    const newData = updatedData.map(row => {
      const isDuplicate = checkDuplicate(row, updatedData);
      const isResolved = !row.componentError && !row.languageError && !row.translationError && !isDuplicate;
      const errorStatus = getErrorStatus(row, updatedData)
      return {
        ...row,
        status: errorStatus,
        duplicateError: isDuplicate,
        translation: row.translation || ""
      };
    });
    setTableData(newData);
    setHasErrors(newData.some(row => row?.status !== 'Resolved'));
    handleDataUpdate(newData);
  };

  useEffect(() => {
    const initialData = data.map((row,index) => ({
      ...row,
      componentError: !validateField('component', row.component),
      languageError: !validateField('language', row.language),
      translationError: !validateField('translation', row.translation),
    }));
    updateTableData(initialData)
  }, [data]);

  const validateField = (field, value) => {
    if (field === 'component') {
      return textComponentsWithTranslations.some(component => component.name === value) || textComponentsWithTranslations.some(component => component.key === value);
    }
    if (field === 'language') {
      return supportedLanguages.some(lang => lang.language === value) || supportedLanguages.some(lang => lang.code === value);
    }
    if (field === 'translation') {
      return value && value?.trim() !== '';
    }
    return true;
  };
  
  const handleSelectChange = (key, field, val) => {
    const updatedData = tableData.map(row => {
      if (row.key === key) {
        let updatedRow = { 
          ...row,  // Creating a new object to avoid modifying the original state
          [field]: val, 
          [`${field}Error`]: !validateField(field, val) 
        };
  
        console.log(`Updating row: ${key}, Field: ${field}, Value: ${val}`);
  
        // **If component is changed, update translation for the existing language**
        if (field === 'component') {
          updatedRow.component = val; // Explicitly set the value
  
          // Check in translationData first
          let componentData = fileData.find(c => c.Component === val);
          let matchingTranslation = componentData ? componentData[row.language] : '';
  
          // If not found, check in textComponentsWithTranslations
          if (!matchingTranslation) {
            componentData = textComponentsWithTranslations.find(c => c.name === val || c.key === val);
            matchingTranslation = componentData ? componentData.value[row.language] : '';
          }
  
          updatedRow.translation = matchingTranslation ? matchingTranslation : '';
          updatedRow.translationError = !validateField('translation', updatedRow.translation);
        }
  
        // **If language is changed, update translation for the existing component**
        if (field === 'language') {
          // Check in translationData first
          let componentData = fileData.find(c => c.Component === row.component);
          let matchingTranslation = componentData ? componentData[val] : '';
  
          // If not found, check in textComponentsWithTranslations
          if (!matchingTranslation) {
            componentData = textComponentsWithTranslations.find(c => c.name === row.component || c.key === row.component);
            matchingTranslation = componentData ? componentData.value[val] : '';
          }
  
          updatedRow.translation = matchingTranslation ? matchingTranslation : '';
          updatedRow.language = COUNTRY_LANGUAGES.find(lang => lang.code === val)?.language;
          updatedRow.translationError = !validateField('translation', updatedRow.translation);
        }
  
        console.log("Updated row:", updatedRow);
        return updatedRow; // Ensure only the correct row is updated
      }
  
      return row; // Return unchanged rows
    });
  
    console.log("Final Updated Data:", updatedData);
    updateTableData(updatedData);
  };
  
  

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => (
        <Flex>
          {value !== 'Resolved' ? (
            <ExclamationCircleOutlined style={{ color: 'red', marginRight: '10px' }} />
          ) : (
            <CheckCircleOutlined style={{ color: 'green', marginRight: '10px' }} />
          )}
          {value}
        </Flex>
      ),
    },
    {
      title: 'Component',
      dataIndex: 'component',
      key: 'component',
      render: (_, record) => (
        <Select
          style={{ width: '250px' }}
          className={record.componentError ||  record.duplicateError? 'error-highlight': ''}
          value={record.component}
          onChange={(value) => handleSelectChange(record.key, 'component', value)}
        >
           {textComponentsWithTranslations.map((component) => (
            <Option key={component.name}>{component.name}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      render: (_, record) => (
        <Select
          style={{ width: '250px'}}
          className={record.languageError || record.duplicateError? 'error-highlight': ''}
          value={record.language}
          onChange={(value) => handleSelectChange(record.key, 'language', value)}
        >
          {supportedLanguages.map(({ code, language }) => (
            <Option key={code}>{language}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      key: 'translation',
      render: (_, record) => (
        <Input
          style={{ width: 250 ,borderColor: record.translationError ? 'red' : undefined }}
          value={record.translation}
          width={"500px"}
          onChange={(e) =>
            handleSelectChange(record.key, 'translation', e.target.value)
          }
        />
      ),
    },
  ];

  const removeSelectedRows = (keys) => {
    const fileteredtranslations = tableData.filter(row => !keys.includes(row.key));
    setTableData(fileteredtranslations)
    handleDataUpdate(fileteredtranslations)
  }

  return (
    <>
      {data.length > 0 && (
          
          <>
            <Flex align='center' justify="space-between">
            {hasErrors ? (
              <Flex>
                <ExclamationCircleOutlined style={{ color: 'red', marginRight: '10px' }} />
                {tableData.filter(row => row.status !== 'Resolved').length} needs to be corrected or removed. If not corrected, erroneous rows will be removed.</Flex>
            ) : (
              <Flex><CheckCircleOutlined style={{ color: 'green', marginRight: '10px' }} />All the errors have been fixed. Click on the Next button to confirm all the data.</Flex>
            )}
              <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => removeSelectedRows(selectedRowKeys)} >Remove Rows</Button>
            </Flex>
          </>)}
    <Table
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys,
        onChange: (keys) => { setSelectedRowKeys(keys) }
      }}
      className="error-info"
      dataSource={tableData}
      columns={columns}
      pagination={false}
      />
    </>
  );
};

export default ErrorInfoTable;
