import { Button, Col, Flex, Input, message, Row, Space, Table, Tag, theme, Typography,Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDebouncedSearch } from "../../../../hooks.js";
import { CloudUploadOutlined, DownloadOutlined, EditFilled, EditOutlined, ExportOutlined } from "@ant-design/icons";
import LegalDocumentManagementModal from "./LegalDocumentManagementModal.jsx";
import { downloadLegalDocument, getAllLegalDocuments, postLegalDocument, updateLegalDocumentName, updateLegalDocumentValues, updateLegalDocumentVersion } from "../../../../api/preferences.js";
import dayjs from "dayjs";
import EditDocumentModal from "./EditDocumentModal.jsx";
import Loading from "../../../common/Loading.js.jsx";
import { useSelector } from "react-redux";

const { Text, Paragraph } = Typography;

// Method to highlight the given substring within the text
const getHighlightedText = (text, highlight) => {
  let parts = []
  if(text) {
    parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  } else {
    debugger;
  }
  
  return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
          <Text key={index} mark>
              {part}
          </Text>
      ) : (
          part
      )
  );
};

const LegalDocumentManagementHome = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [allLegalDocs, setAllLegalDocs] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [openUploadDocumentModal, setOpenUploadDocumentModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [docTypeGroup, setDocTypeGroup] = useState(null);
  const [documentToEdit, setDocumentToEdit] = useState(null);
  const { token } = theme.useToken();
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value']))

  const handleAddNew = (docTypeGroup) => {
    setOpenUploadDocumentModal(true);
    if(docTypeGroup) {
      const docGroup = allLegalDocs.find((docGroup) => docGroup.documentName === docTypeGroup?.documentName);
      setDocTypeGroup((docs) => ({
        ...docTypeGroup,
        documents: docGroup?.documents ?? docs.children
      }));
    } else {
      setDocTypeGroup(null);
    }
  }

  const handleEdit = (record) => {
    const docGroup = allLegalDocs.find((docGroup) => docGroup.documentName === record.documentName);
    setDocTypeGroup(docGroup ?? null)
    setOpenEditModal(true);
    setDocumentToEdit(record);
  }

  const handleDownload = async (record) => {
    const documentId = record._id;
    try {
      setLoading(true);
  
      const blob = await downloadLegalDocument(documentId);
  
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = record.versionName ?? `document-${documentId}`;
      document.body.appendChild(a); 
      a.click();
      a.remove(); 

      URL.revokeObjectURL(url);
  
      setLoading(false);

      message.success(<>Document version <b>{record.versionName}</b> download triggered</>)
    } catch (err) {
      console.error(err); 
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  };

  async function fetchAllDocs() {
    try {
      setLoading(true);
      let docs = await getAllLegalDocuments();
      if(docs) {
        setAllLegalDocs(docs);
      }
      setLoading(false);
    } catch(err) {
      console.log(err)
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllDocs();
  }, []);

  const columns = [
    {
      title: 'Doc Name',
      dataIndex: 'documentName',
      key: 'documentName',
      filters: allLegalDocs.map((doc) => ({text: doc.documentName, value: doc.documentName})),
      filterMultiple: true,
      onFilter: (value, record) => record.documentName === value,
      render: (documentName, record) =>  record.isLatest ? <Paragraph  ellipsis={{tooltip:{title: documentName}, rows: 2}}>{documentName} </Paragraph> : null
    },
    {
      title: 'Version Name',
      dataIndex: 'versionName',
      key: 'versionName',
      render: (versionName, record) => {
       return getHighlightedText(versionName, searchValue.trim());
      }
    },
    {
      title: 'Version ID',
      dataIndex: '_id',
      key: '_id',
      render: (_id, record) => {
        return <>{getHighlightedText(_id, searchValue.trim())} {/*record.isLatest ? <Tag color="blue">Latest</Tag> : null*/}</>;
      }
    },
    {
      title: 'Go Live Date (in UTC)',
      dataIndex: 'goLiveDate',
      key: 'goLiveDate',
      sorter: (a, b) => dayjs(a.goLiveDate).unix() - dayjs(b.goLiveDate).unix(),
      render: (goLiveDate, record) =>  dayjs(goLiveDate).format('MMMM D, YYYY, h:mm A'),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Actions',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 150,
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            {record.isLatest ? <Tooltip title={`Upload new doc in ${record.documentName}`}><Button icon={<CloudUploadOutlined/>} type="link" onClick={() => handleAddNew(record)}/> </Tooltip>: null}
            <Tooltip title="Edit"><Button icon={<EditOutlined/>} onClick={() => handleEdit(record)} type="link"/></Tooltip>
            <Tooltip title="Download"><Button icon={<DownloadOutlined/>} type="link" onClick={() => handleDownload(record)}/> </Tooltip>
          </Space>
        )
      }
    },
  ];

  useEffect(() => {
    let rowKeys = []
    let ds = allLegalDocs?.map((docsCollectionObj) => {
      let children = docsCollectionObj.documents.filter(obj => obj.goLiveDate !== docsCollectionObj.latestVersion).map(obj => ({...obj, documentName: docsCollectionObj.documentName})).sort((a, b) => dayjs(a.goLiveDate).unix() - dayjs(b.goLiveDate).unix());
      
      if (searchValue.trim().length) {
        children = children?.filter((doc) => {
          const flag =
            doc._id?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !==
              -1 ||
            doc.versionName
              .toLowerCase()
              .indexOf(searchValue.trim().toLowerCase()) !== -1;
          if (flag) {
            rowKeys.push(docsCollectionObj.documentName);
          }
          return flag;
        });
      }
      let latestDoc = docsCollectionObj.documents.find(obj => obj.goLiveDate === docsCollectionObj.latestVersion);
      
      if(latestDoc) {
        return ({
          ...latestDoc,
          isLatest: true,
          documentName: docsCollectionObj.documentName,
          key: docsCollectionObj.documentName,
          children: children?.length ? children : null, 
        })
      } 
  
      return ({
        documentName: docsCollectionObj.documentName,
        key: docsCollectionObj.documentName,
        children: children?.length ? children : null, 
      })
    }).filter((docsCollectionObj) => searchValue.trim().length ? docsCollectionObj.children?.length > 0 || docsCollectionObj._id?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || docsCollectionObj.versionName.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 : true);

    setDataSource(ds);
    if(searchValue.trim().length) {
      setExpandedRowKeys(rowKeys);
    } else {
      setExpandedRowKeys([]);
    }
  },[searchValue, allLegalDocs]);

  const handleSaveDocument = async (formData, isNew) => {
    let data = new FormData()
    Object.entries(formData).forEach(([key, value]) => {
      if (value) data.append(key, value);
    });
    data.append("accountId", currentUser.accountId);
    try {
      setLoading(true);
      if(isNew) {
        const resp = await postLegalDocument(data);
        fetchAllDocs();
        message.success(<>New Document <b>{formData.documentName}</b> saved successfully</>)
      } else {
        const resp = await updateLegalDocumentVersion(data);
        fetchAllDocs();
        message.success(<>New Document Version <b>{formData.versionName}</b> uploaded successfully</>)
      }
      setOpenUploadDocumentModal(false);
      setDocTypeGroup(null);
      setLoading(false);
   
    } catch(err) {
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  }

  const handleEditDocument = async (formData, editDocName) => {
    formData.accountId = currentUser.accountId;
    try {
      setLoading(true);
      if(editDocName && formData.documentName !== documentToEdit.documentName){
        const resp1 = await updateLegalDocumentName({accountId: currentUser.accountId, documentName: formData.documentName}, documentToEdit.documentName);
        const resp2 = await updateLegalDocumentValues(formData, documentToEdit._id);
        fetchAllDocs();

        message.success("doc values updated")
      } else {
        const resp2 = await updateLegalDocumentValues(formData, documentToEdit._id);
        fetchAllDocs();
        message.success("doc values updated")
      }
      setOpenEditModal(false);
      setDocTypeGroup(null);
      setDocumentToEdit(null);
      setLoading(false);
    } catch(err) {
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  };

  return currentUser ? (
    <>
    <Row gutter={[0,token.marginMD]}>
      <Col span={24}>
        <Space direction="vertical" size={token.marginXS}>
          <Typography.Text type="secondary">The Legal Documents Library is where you create a repository of your legal documents to keep a record of what documents have been disclosed to your users.</Typography.Text>
        </Space>
      </Col>
      <Col span={24}>
        <Flex justify="space-between" align="center">
          <Input.Search
            placeholder="Search by version name or ID"
            onChange={({ target: { value } }) => {
              debouncedChangeHandler(value);
            }}
            style={{width: 360}}
          />
          <Button
            type="primary"
            onClick={() => handleAddNew()}
          >
            + Add New Doc
          </Button>
        </Flex>
      </Col>
      <Col span={24}>
      <Table
        columns={columns}
        expandable={{
          expandedRowKeys,
          indentSize: 8,
          onExpand: (expanded, record) => {
            if(expanded) {
              setExpandedRowKeys( [...expandedRowKeys, record.key])
            } else {
              setExpandedRowKeys( expandedRowKeys.filter( key => key !== record.key))
            }
          },
        }}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        scroll={{y: `calc(100vh - 350px)`}}
      />
      </Col>
    </Row>
    {openUploadDocumentModal ? (
      <LegalDocumentManagementModal
        loading={loading}
        open={openUploadDocumentModal}
        onCancel={() => {setOpenUploadDocumentModal(false);setDocTypeGroup(null)}}
        docTypeGroup={docTypeGroup}
        handleSaveDocument={handleSaveDocument}
        documentNames={allLegalDocs.filter((doc) => doc.documentName !== docTypeGroup?.documentName).map((doc) => doc.documentName)}
      />
    ) : null}
    {openEditModal ? (
      <EditDocumentModal
        loading={loading}
        open={openEditModal}
        onCancel={() => {setOpenEditModal(false);setDocTypeGroup(null);setDocumentToEdit(null)}}
        docTypeGroup={docTypeGroup}
        documentToEdit={documentToEdit}
        handleSaveDocument={handleEditDocument}
        documentNames={allLegalDocs.filter((doc) => doc.documentName !== documentToEdit?.documentName).map((doc) => doc.documentName)}
      />
    ) : null}
    </>
  ) : <Loading/>
};

export default LegalDocumentManagementHome;