import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';

import Loading from '../../common/Loading.js.jsx';
import BuilderModal from '../builder/BuilderModal';
import NativeBuilderModal from '../native/NativeBuilderModal';
import NoticeTable from './NoticeTable/NoticeTable';

import { getAllSites, getSiteGroups, getSiteGroup } from '../../../actions/site_actions';
import { getVendorListsAjax as getVendorListsCCPA } from '../../../api/ccpa/vendor_list';
import { getVendorListsAjax as getVendorListsUS } from '../../../api/us/vendor_list';
import { getVendorListsAjax as getVendorListsTCFV2 } from '../../../api/consent_v2/vendor_list';
import { Site, SiteGroup } from '../../../records/site_records';
import { User } from '../../../records/account_records';
import { ChoiceConfigRecord, Dismiss } from '../../../records/choice_config_records';

import {
  STATUS_TYPES as STATUS,
  BACKEND_STATUS_TYPES,
  NOTICE_URL,
  dialogueTypes,
  messageEndpoints,
  newMessageEndpoints,
  subcategoryMap,
  categoryMap
} from '../../../constants';
import { log } from '../../../util';
import PropertiesUnderGroupRedirection from '../../common/PropertiesUnderGroupRedirection';
import { getVariables } from '../../../api/dialogue/messages';
import {
  createMessageLog,
  editMessageLog,
  deleteMessageLog,
  createSiteGroupMessageLog,
  editSiteGroupMessageLog,
  deleteSiteGroupMessageLog
} from '../../../api/change_log';
import { CategoryContext, SitesContext, VendorListsContext, IsApp, } from '../contexts';
import CopyUrlModal from './modals/CopyUrlModal.jsx';
import CopyJsModal from './modals/CopyJsModal.jsx';
import { siteAndGroupLabel, getPageKind } from '../../dialogue/helper';
import SiteGroupRedirect from '../../common/SiteGroupRedirect';
import CustomIcon from '../../common/CustomIcon.js';
import { patchMessage } from '../builder/builderUtils.js';
import PropertyTag from '../../common/PropertyTag';
import Builder from '../builder/Builder/index.js';
import NativeOttBuilder from '../nativeOttBuilder/index.jsx';
import {
  Alert,
} from 'antd';
import {
  Headline,
  Tabs,
  message as antMessage,
  Select,
  Modal,
  Button
} from '../../../styleguide';
import DismissErrorModal from '../../common/DismissErrorModal.jsx';
import { keyByVal } from '../../utils.js';
import { getAllPreferenceConfiguration } from '../../../actions/preferences_actions.js';
import { getAllPreferenceConfigurationAjax } from '../../../api/preferences.js';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

const { Option } = Select;

const headerMap = {
  sar: 'Subject Access Request Forms',
  dsarRetrieval: 'DSAR Forms',
  "privacy-manager": 'TCF v2 Privacy Managers',
  privacy_manager_non_iab: "Non IAB Privacy Managers",
  notice: 'Messages v2',
  "notice-tcf-v2": 'TCF v2',
  notice_non_iab: 'Non IAB Message',
  native: 'Native',
  "privacy-manager-ott": 'OTT Privacy Managers',
  ccpa_ott: 'OTT',
  ios: 'iOS Tracking',
  custom: 'Custom Message',
  ccpa_pm: 'U.S. Privacy Manager (legacy)',
  us_pm: 'U.S. MultiState Privacy Managers',
  usnat_notice: 'U.S. MultiState Messages',
  "privacy-manager-ott": 'OTT Privacy Managers',
  'native-ott': 'OTT',
  'native-ott-ccpa': 'OTT',
  'preferences': 'Universal Consent and Preferences'
};

const subcategoryPmMap = {
  'notice_non_iab': 'privacy_manager_non_iab',
  'notice-tcf-v2': 'privacy-manager',
  'notice': 'ccpa_pm',
  'usnat_notice': 'us_pm'
}

class Messages extends React.Component {
  static propTypes = {
    allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    allSiteGroups: ImmutablePropTypes.listOf(PropTypes.instanceOf(SiteGroup)).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    getAllSites: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    siteId: PropTypes.number.isRequired,
    siteGroupId: PropTypes.string,
    currentUser: PropTypes.instanceOf(User).isRequired,
    route: PropTypes.shape({
      messageCategory: PropTypes.string.isRequired,
      messageSubcategory: PropTypes.string.isRequired,
      messageTabs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    }).isRequired,
  };

  state = {
    allMessages: {},
    builderVisible: false,
    builderMounted: false,
    loading: true,
    loaded: 0,
    editing: null,
    messages: [],
    public: [],
    stage: [],
    publicOld: [],
    publicPMs: [],
    stagePMs: [],
    draftPMs: [],
    siteGroupMessages: {},
    searchText: '',
    sort: 'newest',
    copyUrlModalIsVisible: false,
    copyJsModalIsVisible: false,
    selectedMsgId: '',
    selectedSarType: 'delete',
    selectedConditions: ['draft', 'public', 'stage'],
    showCloseBuilderConfirmation: false,
    vendorLists: [],
    preferencesLists: [],
  }

  componentDidMount = () => {
    if (this.props.location.state != undefined) {
      this.showBuilder(this.props.location.state.message, this.state.isPreviewOnly)
    }

    if (this.props.currentUser) {
      this.props.getAllSites();
      this.props.getSiteGroups(this.props.currentUser.accountId);
      this.getAllMessages();
      this.getVendorLists();
      this.getPreferencesLists()

    }
  }
  async getPreferencesLists() {
    const preferencesLists = await getAllPreferenceConfigurationAjax(this.props.currentUser.toJS().accountId)
    this.setState({ preferencesLists: preferencesLists.toJS() })
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.currentUser && this.props.currentUser) {
      this.props.getAllSites();
      this.props.getSiteGroups(this.props.currentUser.accountId);
      this.getAllMessages();
    }
    const differentParams = (oldProps.location.search !== this.props.location.search);
    const differentPath = (oldProps.location.pathname !== this.props.location.pathname);

    if (differentParams || differentPath) {
      this.getAllMessages();
      this.setState({
        builderVisible: false,
        builderMounted: false,
        loading: true,
        loaded: 0,
        editing: null,
        messages: [],
        public: [],
        stage: [],
        publicOld: [],
        searchText: '',
        sort: 'newest',
        isPreviewOnly: false,
        selectedConditions: ['draft', 'public', 'stage']
      })
    };

    const { messages } = this.state;
    if (messages && messages.length && !this.state.selectedMsgId) {
      this.setState({ selectedMsgId: messages[0].id });
    };
  }

  getAllMessages = () => {
    this.getMessages('draft');
    this.getMessages('public');
    this.getMessages('stage');
  }

  updateSearch = e => {
    this.setState({ searchText: e.target.value });
  };

  getVendorLists = async () => {
    const {
      messageCategory,
      messageSubcategory
    } = this.props.route;

    let vLists;
    if (this.props.currentUser && this.props.allSites.size >= 1) {
      if (messageSubcategory === 'ccpa_pm' || (messageSubcategory === 'native-ott' && messageCategory === 'ccpa') || messageSubcategory === 'ccpa_ott') {
        vLists = await getVendorListsCCPA();
        vLists = vLists.toJS();
      } else if (messageCategory === 'usnat') {
        vLists = await getVendorListsUS();
      } else {
        const onlyCustom = (messageCategory === 'gdpr' && messageSubcategory === 'privacy_manager_non_iab');
        vLists = await getVendorListsTCFV2(onlyCustom);
        vLists = vLists.toJS();
      }
    }
    this.setState({ vendorLists: vLists });
  }

  getMessages = async (type = 'draft', siteGroupSiteId) => {
    try {
      const siteId = siteGroupSiteId || this.props.siteId;
      const { route, siteGroupId } = this.props;
      const {
        messageCategory,
        messageSubcategory
      } = route;
      const url = `${messageEndpoints.MESSAGES}?object_status=${BACKEND_STATUS_TYPES[type]}&site_id=${siteId}`;
      const pmMessages = [];
      let allMessages = (await (await fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }))
        .json()).data.message_list;

      if (siteGroupId && allMessages.length) {
        allMessages = await Promise.all(allMessages.map(async (msg) => ({ ...msg, variables: await getVariables(msg.id) })))
      };
      let messages = allMessages.filter((msg) => {
        const filtered = (
          msg.message_sub_category_id === subcategoryMap[messageSubcategory]
          && msg.message_category_id === categoryMap[messageCategory]
        );
        const pmSubCategory = subcategoryPmMap[messageSubcategory];
        if (pmSubCategory && msg.message_sub_category_id === subcategoryMap[pmSubCategory]) {
          pmMessages.push(msg);
        }
        return filtered;
      });

      messages = messages.map(m => patchMessage(m, false))

      let newState = {
        loading: false,
        loaded: this.state.loaded + 1
      };

      if (siteGroupSiteId) {
        let siteGroupMessages = this.state.siteGroupMessages || {};
        let siteGroupSiteMessages = siteGroupMessages[siteGroupSiteId] || {};
        siteGroupMessages = { ...siteGroupMessages, [siteGroupSiteId]: { ...siteGroupSiteMessages, [type]: messages, [`${type}PMs`]: pmMessages } };
        newState = { ...newState, siteGroupMessages }
      } else {
        newState = { ...newState, allMessages: { ...this.state.allMessages, [type]: allMessages } };
        switch (type) {
          case 'draft':
            newState = { ...newState, messages, draftPMs: pmMessages, selectedMsgId: messages.length && messages[0].id };
            break;
          default:
            newState = { ...newState, [type]: messages, [`${type}PMs`]: pmMessages };
            break;
        }
      }

      return this.setState(newState);
    } catch (err) {
      log(err)
      antMessage.error('Could not load messages, try again');
      this.setState({ loading: false });
    }
  }

  generateChoiceJSONNative = (message, type) => {
    const options = message.actions.map(({ choiceType }) => ({
      choice_option_type: choiceType,
      option_data: {
        consent_origin: window.CONFIG && window.CONFIG.consentOriginV2 || 'https://sourcepoint.mgr.consensu.org/tcfv2',
        consent_language: 'EN',
      },
    }));

    return new ChoiceConfigRecord({
      message_id: type === 'update' ? this.state.editing.id : null,
      option_list: options || [],
      options: {},
    }).toJS();
  }

  generateChoiceJSON = (message, type) => {
    const options = [];
    options.push({
      choice_option_type: 15,
      option_data: new Dismiss({
        button_text: "Dismiss",
        enabled: true,
      }).toJS()
    });
    const getOptions = (component) => {
      const children = component.children || [];
      children.forEach((c) => {
        if (c.type === 'Button' && c.settings.choice_option && c.settings.choice_option.type) {
          const choice = _.cloneDeep(c.settings.choice_option)
          if (choice.type !== 'SE') options.push({ choice_option_type: choice.type, option_data: choice.data });
        } else if (c.type === 'Text' && c.settings.choice_options && c.settings.choice_options.length > 0) {
          _.cloneDeep(c.settings.choice_options).forEach((o) => {
            if (o.type !== 'SE') {
              options.push({ choice_option_type: o.type, option_data: o.data });
            }
          });
        } else if (c.type === 'PMButton' && c.settings.action_type && c.settings.action_type.type === 9) {
          options.push({ choice_option_type: 9, option_data: c.settings.action_type.data })
        }

        if (c.children && c.children.length > 0) getOptions(c);
      });
    };
    getOptions(message);

    return new ChoiceConfigRecord({
      message_id: type === 'update' ? this.state.editing.id : null,
      option_list: options || [],
      options: {},
    }).toJS();
  }

  postTemplate = async (msg, type, template_params) => {
    let msgWithoutPm = this.stripPmFromTemplate(msg);
    msgWithoutPm[0].name = template_params.name || msgWithoutPm[0].name || `template: ${moment().format('YYYY-MM-DD')}`;
    const clonedMessage = _.cloneDeep(msgWithoutPm);
    delete clonedMessage[0].siblings;

    const updatedMsg =
      clonedMessage.length > 1
        ? Object.assign(msgWithoutPm[0], { siblings: _.cloneDeep(clonedMessage) })
        : msgWithoutPm[0];

    const { route } = this.props;
    const { messageCategory, messageSubcategory } = route;

    const description = updatedMsg.name;
    const id = type !== 'create' ? this.state.editing.id : '';
    const category = categoryMap[messageCategory];
    const subCat = subcategoryMap[messageSubcategory];
    const is_system_template = template_params.is_system_template;
    const is_draft = template_params.is_draft;

    let message;
    try {
      const url =
        type === 'create'
          ? `${newMessageEndpoints.CREATE}?name=${encodeURIComponent(description)}&category=${category}&sub_category=${subCat}&is_system=${is_system_template}&is_draft=${is_draft}`
          : `${newMessageEndpoints.UPDATE}?template_id=${id}&name=${encodeURIComponent(description)}&category=${category}&sub_category=${subCat}&is_system=${is_system_template}&is_draft=${is_draft}`;
      message = await (
        await fetch(url, {
          method: 'post',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedMsg)
        })
      ).json();
      if (type === 'create') {
        if (message.status != 'error') {
          antMessage.success(`Template with name '${description}' is created and saved as ${is_draft ? 'draft' : 'public'}`);
          this.closeBuilder();
          this.setState({ loading: false });
        } else {
          const isDuplicateTemplate = message.msg.includes('already exists');
          const redirectToTemplateMgmt = <b className="redirect-system-templates" onClick={() => this.onHandleRedirectToTemplates()}>Manage Templates <CustomIcon type={CustomIcon.types.PREVIEW} /></b>;
          const duplicateMsgWarning = isDuplicateTemplate ? <React.Fragment>A template with name <b>{description}</b> already exists. If you wish to save this template, please select a different name. If you wish to make changes to an existing template, please use the {redirectToTemplateMgmt} area.</React.Fragment> : '';
          antMessage.error(
            isDuplicateTemplate ? duplicateMsgWarning : message.msg,
          );
        }
      } else {
        if (message.status != 'error') {
          antMessage.success('Template is updated and saved as draft')
          this.closeBuilder();
          this.setState({ loading: false, editing: message });
        } else {
          antMessage.error(message.msg);
        }
      }
    } catch (err) {
      log(err);
      antMessage.error('Could not save template, try again');
    }
  };

  postMessage = async (msg, type, variables, isNative) => {
    const clonedMessage = _.cloneDeep(msg);
    delete clonedMessage[0].siblings;

    const updatedMsg = (clonedMessage.length > 1)
      ? Object.assign(msg[0], { siblings: _.cloneDeep(clonedMessage) })
      : msg[0];

    try {
      const {
        route,
        siteId,
        siteGroupId,
        accountState,
        siteState,
        currentUser,
        route: { messageCategory, messageSubcategory }
      } = this.props;

      const { accountId } = currentUser.toJS()
      const options = isNative
        ? this.generateChoiceJSONNative(updatedMsg || {}, type)
        : this.generateChoiceJSON(updatedMsg || {}, type);

      const category = categoryMap[messageCategory];
      const subCat = subcategoryMap[messageSubcategory];
      const description = updatedMsg.name;
      let message;

      if (!this.props.siteGroupId) {
        const url = (type === 'create')
          ? `${messageEndpoints.CREATE}?message_type=2&site_id=${siteId}&category_type=${category}&sub_category_type=${subCat}`
          : `${messageEndpoints.UPDATE}?message_id=${this.state.editing.id}&category_type=${category}&sub_category_type=${subCat}`;
        message = await (await (await fetch(url, {
          method: 'post',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            description,
            message_json: JSON.stringify(updatedMsg),
            message_settings: JSON.stringify(updatedMsg.settings || {}),
            choice_json: JSON.stringify(options),
            compliance_json: JSON.stringify(messageSubcategory === 'native-ott' ? updatedMsg.settings.compliance_list : updatedMsg.compliance_list)
          })
        })).json()).data.message;

        if (type === 'create') {
          createMessageLog({ accountState, siteState, isV2: true }, message);
        } else {
          editMessageLog({ accountState, siteState, isV2: true }, message);
        };
      } else {
        [message] = (await (await fetch(`${permissionsSvsBase}/site-group/v2/message?siteGroupId=${siteGroupId}&category=${category}&subCategory=${subCat}`, {
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          method: type === 'create' ? 'post' : 'put',
          body: JSON.stringify({
            accountId,
            msgBlob: {
              description,
              message_json: JSON.stringify(updatedMsg),
              message_settings: JSON.stringify(updatedMsg.settings),
              choice_json: JSON.stringify(options),
              compliance_json: JSON.stringify(updatedMsg.compliance_list)
            },
            defaultVariables: variables ? variables.defaultVariables : [],
            sitesVariables: variables ? variables.sitesVariables : [],
            ...(this.state.editing ? { messageId: this.state.editing.id } : {})
          })
        })).json()).messageResponseObjs;

        if (type === 'create') {
          createSiteGroupMessageLog({ accountState, siteState, isV2: true }, siteGroupId, this.state.messages);
        } else {
          editSiteGroupMessageLog({ accountState, siteState, isV2: true }, siteGroupId, message);
        };
      }

      if (type === 'create') {
        antMessage.success('Message saved');
        this.closeBuilder();
        this.setState({ loading: false }, this.getAllMessages);
        this.closeBuilder();
      } else {
        antMessage.success('Message updated');
        this.setState({ loading: false, editing: message }, this.getAllMessages);
      }
    } catch (err) {
      log(err);
      antMessage.error('Could not save message, try again');
    }
  }

  cloneMessage = async (messageId, isNative) => {
    try {
      const messages = Object.values(this.state.allMessages).reduce((acc, messages) => { return acc.concat(messages || []) }, []);
      const message = messages.find(msg => msg.id === messageId);

      if (message) {
        await this.postMessage([JSON.parse(message.message_json)], 'create', message.variables, isNative);
      } else {
        throw new Error('Could not find message');
      }

      this.getAllMessages();
    } catch (err) {
      log(err);
      antMessage.error('Could not copy message, try again');
    }
  }

  deleteMessage = async (messageId) => {
    // iterate over the draft, stage, and live messages
    // check if messageId belongs to a PM
    // check if we're using this messageId in any message JSONs
    // if both are true we can't delete
    let isPm = false;
    let messagesUsingPm = [];

    [].concat(this.state.allMessages.draft, this.state.allMessages.public, this.state.allMessages.stage)
      .forEach((m) => {
        if (m.message_sub_category_id !== subcategoryMap['privacy-manager']) {
          // m is not a pm, check if it refers to messageId
          if (m.message_json.indexOf(messageId) > 0) {
            messagesUsingPm.push(m)
          }
        } else if (m.id === messageId) {
          // m is a PM, and it matches our messageId
          isPm = true;
        }
      });

    if (isPm && messagesUsingPm.length) {
      Modal.error({
        content: (
          <div>
            <p>This Privacy Manager is not able to be deleted because it is linked to the following Message(s):</p>
            <ul>
              {messagesUsingPm.map((m) => (<li>{`${m.description} (${m.id})`}</li>))}
            </ul>
          </div>),
        okText: 'Dismiss',
        title: 'Privacy Manager is in use',
      })
      return;
    }

    try {
      const {
        route,
        siteId,
        siteState,
        accountState,
        siteGroupId,
        currentUser,
      } = this.props;
      const { accountId } = currentUser.toJS()
      const { description } = this.state.messages.find(msg => msg.id === messageId);

      if (!siteGroupId) {
        await (await fetch(`${messageEndpoints.DELETE}?message_id=${messageId}&site_id=${siteId}`, {
          method: 'post',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        }));

        deleteMessageLog({ accountState, siteState, isV2: true }, messageId, [siteId, { name: description }]);
      } else {
        const { deletedMessages } = (await (await fetch(`${permissionsSvsBase}/site-group/message?siteGroupId=${siteGroupId}&messageId=${messageId}`, {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accountId })
        })).json());

        if (!deletedMessages) {
          throw Error('failed to delete sitegroup message');
        }

        deleteSiteGroupMessageLog({ accountState, siteState, isV2: true }, siteGroupId, messageId, description);
      }

      antMessage.success('Message deleted');
      this.getAllMessages();
    } catch (err) {
      log(err);
      antMessage.error('Could not remove message, try again');
    };
  }

  showBuilder = (message, isPreviewOnly) => this.setState({
    builderVisible: true,
    builderMounted: true,
    editing: message || null,
    isPreviewOnly
  })

  closeBuilder = () => this.setState({ builderVisible: false })

  saveMessage = async (messages, variables, isNative = false) => {
    if (messages.some(message => message?.name?.trim() === "")) {
      antMessage.error("Name field can not be empty")
      return;
    }
    const { renderActiveCampaignWarning } = this.props;
    if (messages && this.state.editing) {
      await this.postMessage(messages, 'update', variables, isNative);
      renderActiveCampaignWarning(this.state.editing.id, dialogueTypes.MESSAGE);
    } else if (messages && !this.state.editing) {
      this.postMessage(messages, 'create', variables, isNative);
    };
  }

  stripPmFromTemplate = (message) => {
    const clonedMessage = _.cloneDeep(message);
    strip(clonedMessage[0]);

    return clonedMessage

    function strip(child) {
      if (child.settings
        && child.settings.choice_option
        && child.settings.choice_option.data
        && child.settings.choice_option.data.privacy_manager_iframe_url
      ) delete child.settings.choice_option.data.privacy_manager_iframe_url;
      if (child.children) child.children.forEach(strip)
    }
  }

  saveTemplate = async (messages, variables, template_params) => {
    this.postTemplate(messages, 'create', template_params);
  }

  filterMessages = message => {
    const messageName = message.description.toLowerCase();
    const message_id = message.id.toString();
    return this.state.searchText.length > 0
      ? messageName.includes(this.state.searchText.toLowerCase()) ||
      message_id.includes(this.state.searchText.toLocaleLowerCase())
      : true;
  };

  sortMessages = (a, b) => (
    this.state.sort === 'newest'
      ? a.id > b.id ? -1 : 1
      : a.id > b.id ? 1 : -1
  )

  filterMessagesCondition = templates => {

    let filteredTemplates = []
    let conditions = this.state.selectedConditions;
    conditions.map((condition) => {
      if (condition === 'all') {
        filteredTemplates.push(templates.filter((template) => template));
      }
      if (condition === 'draft') {
        filteredTemplates.push(templates.filter((template) => template.status === 1 && !template.statusSecondLayer?.length));
      }
      if (condition === 'public') {
        filteredTemplates.push(templates.filter((template) => template.status === 2 || template.statusSecondLayer?.includes('public')))
      }
      if (condition === 'stage') {
        filteredTemplates.push(templates.filter((template) => template.status === 4 || template.statusSecondLayer?.includes('stage')))
      }
      if (condition === 'publicOld') {
        filteredTemplates.push(templates.filter((template) => template.status === 3))
      }
    })

    templates = [...new Set(filteredTemplates.reduce((a, b) => [...a, ...b], []))];
    return templates;

  };

  filterCondition = (selectedConditions) => {
    if (selectedConditions.includes('publicOld')) {
      this.getMessages('publicOld')
    }
    this.setState({ selectedConditions })
  }

  copyUrlToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.createUrl();
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    antMessage.success('Copied to clipboard');
  }

  resetCopyUrlOpts = () => {
    this.setState({
      selectedMsgId: this.state.messages[0].id,
      selectedSarType: 'delete'
    })
  }

  createUrl = () => {
    const { messageSubcategory } = this.props.route;
    const accountId = this.props.currentUser.accountId;
    return `${NOTICE_URL}/${this.props.route.messageSubcategory}/index.html?`
      + `message_id=${this.state.selectedMsgId}`
      + `&account_id=${accountId}`
      + (messageSubcategory === 'sar' ? `&ccpa_type=${this.state.selectedSarType}` : '');
  }

  redirectToGroup = () => {
    browserHistory.push(`${this.props.location.pathname}?site_id=${this.props.siteGroup.siteId}&site_group_id=${this.props.siteGroup.id}`);
  }

  viewTemplates = () => {
    browserHistory.push(`/dialogue/messages/system_templates?site_id=` + this.props.siteId);
  };

  onTabChange = (url) => {
    url += `?site_id=${this.props.siteId}`;
    if (this.props.location.query.sitegroup_id) {
      url += `&sitegroup_id=${this.props.location.query.sitegroup_id}`
    } else if (this.props.siteGroupId) {
      url += `&site_group_id=${this.props.siteGroupId}`;
    }
    this.props.router.push(url);
  }

  onHandleRedirectToTemplates = () => {
    const linkUrl = '/admin/system_templates';
    browserHistory.push(linkUrl);
  }

  toggleShowBuilderConfirmation = (value) => {
    this.setState({ showCloseBuilderConfirmation: value })
  }

  /*
    to handle show confirmation pop-up on message updates
  */
  handleBuilderClose = (updatedMessage) => {
    if (JSON.stringify(updatedMessage) === this.state.editing?.message_json) {
      this.closeBuilder();
    }
    else {
      this.toggleShowBuilderConfirmation(true);
    }
  }

  getActivePMIds = (status) => {
    const activePMIdsSet = new Set();
    this.state.allMessages[status]?.forEach((msg) => {
      if (
        msg.message_sub_category_id === subcategoryMap[keyByVal(subcategoryPmMap, this.props.route.messageSubcategory)]
      ) {
        let PM_url = (JSON.parse(msg.message_settings)?.selected_privacy_manager)?.data?.privacy_manager_iframe_url;
        let id = PM_url?.slice(PM_url?.indexOf("=") + 1);
        activePMIdsSet.add(Number(id));
      }
    })
    return activePMIdsSet;
  }

  render() {
    if (!this.props.currentUser) return null;

    const {
      route: {
        messageCategory,
        messageSubcategory,
        messageTabs,
      },
    } = this.props;

    const { currentUser } = this.props;

    const { accountId } = currentUser.toJS()
    const loading = this.state.loading;
    const messages = this.state.messages.filter(this.filterMessages).sort(this.sortMessages);
    const publicMessages = this.state.public.filter(this.filterMessages).sort(this.sortMessages);
    const stageMessages = this.state.stage.filter(this.filterMessages).sort(this.sortMessages);
    const publicOldMessages = this.state.publicOld.filter(this.filterMessages).sort(this.sortMessages);
    const { site_id: siteId } = this.props.location.query;
    let domain;
    let siteIds;
    let sites = [];
    let propertyType;
    if (this.props.siteGroupId && this.props.allSiteGroups.size && this.props.allSites.size) {
      let siteGroup = this.props.allSiteGroups.find(s => s.siteId === parseInt(siteId));
      if (!siteGroup) {
        this.props.getSiteGroup(this.props.siteGroupId);
      }
      ({ name: domain, siteIds } = siteGroup ? siteGroup : { name: this.props.siteGroup && this.props.siteGroup.name, siteIds: List([]) });
      sites = this.props.allSites.filter(({ id }) => siteIds.find(sid => sid === id)).sortBy(s => s.get('domain'));
    } else if (!this.props.siteGroupId && this.props.allSites.size) {
      const site = this.props.allSites.find(s => s.id === parseInt(siteId));
      siteIds = List([siteId]);
      domain = site ? site.domain : '';
      propertyType = site ? site.type : '';
    };

    let readOnly = false;
    if (
      currentUser.featureAccess
      && !currentUser.featureAccess.includes('Campaign Entities')
    ) {
      readOnly = true;
    }

    const pmMessages = [...this.state.draftPMs];
    let allMessages = [...messages, ...publicMessages, ...stageMessages, ...publicOldMessages]
    allMessages = this.filterMessagesCondition(allMessages)

    let modalButtons;

    if (['sar', 'dsarRetrieval'].includes(messageSubcategory)) {
      modalButtons = (
        <Button
          style={{
            marginLeft: 20,
            backgroundColor: 'rgb(63, 204, 151)',
            borderColor: 'rgb(63, 204, 151)',
            color: !this.state.messages.length && 'white',
            opacity: !this.state.messages.length && 'white' && .5,
          }}
          type="primary"
          disabled={!this.state.messages.length}
          onClick={() => this.setState({ copyUrlModalIsVisible: true })}
        >
          Copy URL
        </Button>
      );
    } else if (['privacy-manager', 'privacy_manager_non_iab', 'us_pm', 'preferences'].includes(messageSubcategory)) {
      modalButtons = (
        <Button
          style={{
            marginLeft: 20,
            backgroundColor: 'rgb(63, 204, 151)',
            borderColor: 'rgb(63, 204, 151)',
            color: !this.state.messages.length && 'white',
            opacity: !this.state.messages.length && 'white' && .5,
          }}
          type="primary"
          disabled={!this.state.messages.length}
          onClick={() => this.setState({ copyJsModalIsVisible: true })}
        >
          Copy JS Code
        </Button>
      )
    }

    let backToSitesGroup;
    if (this.props.location.query.sitegroup_id) {
      backToSitesGroup = <SiteGroupRedirect redirectToGroup={this.redirectToGroup} siteGroup={this.props.siteGroup} />;
    }

    let disableNewMessage = false;
    let messageAlerts;
    if (messageCategory === "gdpr"
      && messageSubcategory === "notice-tcf-v2") {
      if (!pmMessages.length) {
        let linkUrl = `/dialogue/privacy-manager/tcfv2?site_id=${siteId}`;
        if (this.props.siteGroupId) {
          linkUrl += `&site_group_id=${this.props.siteGroupId}`;
        }
        messageAlerts = <Alert showIcon type="warning" message="No Privacy Managers created"
          description={['First layer messages must link to a privacy manager, please ',
            ' to create one before proceeding.']} />;
        disableNewMessage = true;
      }
    }

    let hideCreateNew = false;
    const activePreferencesLists = this.state.preferencesLists && this.state.preferencesLists.filter(vl => vl.propertyIds.some(id => siteIds.toJS().map(Number).includes(id) && vl.isActive));
    if (messageCategory === "preferences" && activePreferencesLists.length === 0) {
        hideCreateNew = true;
        messageAlerts = <Alert showIcon type="error" message="You need to have an active configuration setup in order to create a universal consent and preference message" />;
    }

    let messageTables;
    if (
      [
        "dsarRetrieval",
        "sar",
        "privacy-manager",
        "privacy_manager_non_iab",
        "ccpa_ott",
        "ccpa_pm",
        "us_pm",
        "usnat_notice"
      ].includes(messageSubcategory)
    ) {
      const activePublicIds = this.getActivePMIds('public');
      const activeStageIds = this.getActivePMIds('stage');

      allMessages.map((msg) => {
        msg.statusSecondLayer = []
        if (activePublicIds.has(msg.id)) {
          msg.statusSecondLayer.push('public')
        }
        if (activeStageIds.has(msg.id)) {
          msg.statusSecondLayer.push('stage')
        }
      })

      messageTables = (
        <React.Fragment>
          <NoticeTable
            showBuilder={this.showBuilder}
            siteId={siteId}
            siteGroupId={this.props.siteGroupId}
            messages={allMessages}
            searchText={this.state.searchText}
            updateSearch={this.updateSearch}
            cloneMessage={this.cloneMessage}
            deleteMessage={this.deleteMessage}
            messageCategory={messageCategory}
            messageSubcategory={messageSubcategory}
            filterMessages={this.filterMessages}
            filterCondition={this.filterCondition}
            currentUser={currentUser}
          />
        </React.Fragment>
      );
    } else {
      messageTables = (
        <React.Fragment>
          <NoticeTable
            showBuilder={this.showBuilder}
            siteId={siteId}
            siteGroupId={this.props.siteGroupId}
            messages={allMessages}
            updateSearch={this.updateSearch}
            searchText={this.state.searchText}
            cloneMessage={this.cloneMessage}
            deleteMessage={this.deleteMessage}
            messageSubcategory={messageSubcategory}
            messageCategory={messageCategory}
            filterMessages={this.filterMessages}
            filterCondition={this.filterCondition}
            isNative={messageSubcategory === 'native'}
            currentUser={currentUser}
          />
        </React.Fragment>
      );
    }

    let builder;
    switch (messageSubcategory) {
      case 'native':
        builder =
          (
            <NativeBuilderModal
              siteId={siteId}
              accountId={accountId}
              message={this.state.editing}
              close={this.handleBuilderClose}
              saveMessage={this.saveMessage}
            />
          )
        break
      case 'native-ott':
        builder = (
          <BuilderModal
            accountId={accountId}
            messageCategory={messageCategory}
            messageSubcategory={messageSubcategory}
          >
            {({
              createTemplate,
              deleteTemplate,
              sites,
              previewSites,
              templates,
              messageCategory,
            }) => (
              <NativeOttBuilder
                siteId={siteId}
                sitesUnderSg={sites}
                accountId={accountId}
                messageToEdit={this.state.editing}
                closeBuilder={this.handleBuilderClose}
                saveMessage={this.saveMessage}
                saveTemplate={this.saveTemplate}
                messageCategory={messageCategory}
                templates={templates}
                createTemplate={createTemplate}
                deleteTemplate={deleteTemplate}
              />
            )}
          </BuilderModal>
        )
        break
      default:
        builder = (
          <BuilderModal
            accountId={accountId}
            messageCategory={messageCategory}
            messageSubcategory={messageSubcategory}
          >
            {({
              createTemplate,
              deleteTemplate,
              sites,
              previewSites,
              templates,
            }) => (
              <Builder
                editing={this.state.editing}
                siteId={siteId}
                accountId={accountId}
                currentUser={currentUser}
                exit={this.handleBuilderClose}
                saveMessage={this.saveMessage}
                saveTemplate={this.saveTemplate}
                isPreviewOnly={this.state.isPreviewOnly}
                messageSubcategory={messageSubcategory}
                messageCategory={messageCategory}
                pmMessages={pmMessages}
                pmListLegacy={this.props.pmListLegacy}
                isTemplate={false}
                createTemplate={createTemplate}
                deleteTemplate={deleteTemplate}
                sitesUnderSg={sites}
                previewSites={previewSites}
                templates={templates}
                isApp={this.props.isApp}
                preferencesLists={this.state.preferencesLists}
              />
            )}
          </BuilderModal>
        )
    }

    messageTables = (
      <React.Fragment>
        <CategoryContext.Provider value={messageCategory}>
          <IsApp.Provider value={this.props.isApp}>
            {messageTables}
            {this.state.builderMounted && !readOnly && this.state.builderVisible && builder}
          </IsApp.Provider>
        </CategoryContext.Provider>
      </React.Fragment>
    )

    const propertyTag = propertyType && <PropertyTag type={propertyType} />;

    const closeBuilderConfirmationMsg = (
      <React.Fragment>
        <b>Are you sure you want to close the message builder?</b>
        <br />
        The message in the message builder has been modified since the last time
        it was saved. Clicking on "Yes" will cancel the changes and close the
        message.
      </React.Fragment>
    );
    const closeBuilderConfirmation = this.state
      .showCloseBuilderConfirmation && (
        <DismissErrorModal
          renderOk
          okText="Yes"
          cancelText="No"
          title="Confirmation"
          error={closeBuilderConfirmationMsg}
          isModalVisible={this.state.showCloseBuilderConfirmation}
          primaryAction="cancel"
          handleOk={() => {
            this.toggleShowBuilderConfirmation(false);
            this.closeBuilder();
          }}
          handleCancel={() => this.toggleShowBuilderConfirmation(false)}
        />
      );
    
    const siteRecord = this.props.allSites?.find(s => s.id === parseInt(siteId));

    return (
      <div id="messages-v2" className="messages-v2">
        {(loading || this.state.loaded < 3)
          ? <Loading />
          : (
            <SitesContext.Provider value={{
              sites,
              isSiteGroup: Boolean(this.props.siteGroupId),
              siteGroupId: this.props.siteGroupId,
              allSites: this.props.allSites,
            }}>
              <VendorListsContext.Provider value={this.state.vendorLists}>
                <Headline>
                  MESSAGES
                  {['dsarRetrieval', 'sar', 'privacy-manager', 'privacy_manager_non_iab', 'ccpa_ott'].includes(messageSubcategory) ? (
                    <span>| {headerMap[messageSubcategory]}</span>
                  ) : (
                    <span>|&nbsp;
                      {messageCategory === 'gdpr' && `${headerMap[messageSubcategory] || ''} ${(messageSubcategory === 'native-ott' || messageSubcategory === 'preferences') ? '' : 'Notices'}`}
                      {messageCategory === 'ccpa' && messageSubcategory === 'ccpa_pm' && `U.S. Privacy Managers (legacy)`}
                      {messageCategory === 'usnat' && messageSubcategory === 'us_pm' && `U.S. MultiState Privacy Managers`}
                      {messageCategory === 'usnat' && messageSubcategory === 'unsnat_notice' && `U.S. MultiState Messages`}
                      {messageCategory === 'ccpa' && messageSubcategory !== 'ccpa_pm' && headerMap[messageSubcategory]}
                      {messageCategory === 'adblock' && 'Adblock Notices'}
                      {messageCategory === 'ios14' && 'iOS Tracking Notices'}
                      {messageCategory === 'custom' && 'Custom Notices'}
                      {messageCategory === 'preferences' && headerMap[messageSubcategory]}
                    </span>
                  )}
                </Headline>
                {backToSitesGroup}
                <div className="nt-header">
                  <div className="nt-title">
                    <span className="page-label">{siteAndGroupLabel(this.props.location.query.site_group_id)}&nbsp;
                    </span><span className="page-title">{domain}</span>
                    {propertyTag}
                  </div>
                  <div className="nt-right">
                    {!readOnly && !hideCreateNew && (
                      <Button type="primary" onClick={() => this.showBuilder()} disabled={disableNewMessage}>
                        + New Message
                      </Button>
                    )}
                    {modalButtons}
                  </div>
                </div>
                {this.props.siteGroupId && (
                  <PropertiesUnderGroupRedirection pageKind={getPageKind(this.props.location)} />
                )}
                {messageAlerts}
                {messageTabs && messageTabs.length ?
                  (
                    <Tabs onChange={this.onTabChange} activeKey={this.props.route.path}>
                      {messageTabs.map(([name, url]) => (
                        <Tabs.TabPane tab={name} key={url}>
                          {this.props.route.path == url ? messageTables : ''}
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  )
                  : messageTables
                }

              </VendorListsContext.Provider>
            </SitesContext.Provider>
          )}
        <CopyUrlModal
          visible={this.state.copyUrlModalIsVisible}
          onClose={() => this.setState({ copyUrlModalIsVisible: false })}
          messages={this.state.messages}
          isSar={messageSubcategory === 'sar'}
          createUrl={(selectedMsgId, selectedSarType) => (
            `${NOTICE_URL}/${messageSubcategory}/index.html?`
            + `message_id=${selectedMsgId}`
            + `&account_id=${accountId}`
            + (messageSubcategory === 'sar' ? `&ccpa_type=${selectedSarType}` : '')
          )}
        />
        <CopyJsModal
          visible={this.state.copyJsModalIsVisible}
          onClose={() => this.setState({ copyJsModalIsVisible: false })}
          pms={this.state.messages}
          messageSubcategory={messageSubcategory}
          site={siteRecord}
          userEmail={currentUser?.email}
        />
        {closeBuilderConfirmation}
      </div>
    );
  }
}

const mapStateToProps = ({
  siteState,
  messageState,
  accountState,
  privacyManagerState,
  preferencesState,
},
  ownProps) => {
  const siteId = parseInt(ownProps.location.query.site_id);
  const messages = messageState.getIn(['messages', 'value']).filter(m => m.site_id === siteId);
  const currentSite = siteState.getIn(['sites', 'value']).find(({ id }) => id === siteId)
  return {
    currentUser: accountState.getIn(['userDetails', 'value']),
    draftMessages: messages.filter(m => m.status === STATUS.DRAFT),
    publicMessages: messages.filter(m => m.status === STATUS.PUBLIC && m.live),
    stageMessages: messages.filter(m => m.status === STATUS.STAGE && m.live),
    allSites: siteState.getIn(['sites', 'value']),
    allSiteGroups: siteState.getIn(['siteGroups', 'value']),
    siteId,
    siteGroupId: ownProps.location.query.site_group_id,
    siteGroup: siteState.getIn(['siteGroups', 'value']).find(sg => (sg.id === ownProps.location.query.sitegroup_id || sg.id === ownProps.location.query.site_group_id)),
    pendingRequestsMap: Map({
      messages: messageState.getIn(['messages', 'pending']),
      allSites: siteState.getIn(['sites', 'pending']),
    }),
    siteState,
    accountState,
    isApp: currentSite ? currentSite.type === 'app' : null,
    pmListLegacy: privacyManagerState.getIn(['sitePrivacyManagerList', 'value']),
    preferencesState,
  };
};

export default connect(
  mapStateToProps, {
  getAllSites,
  getSiteGroups,
  getSiteGroup,
  getAllPreferenceConfiguration,
},
)(Messages);


window.branch = 'mr-DIA-3898'