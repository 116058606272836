import React from "react";
import { Table } from "antd";

const DetailTable = (props) => {
    const { data } = props;

    const columns = [
        {
          title: "Component",
          dataIndex: "component",
          key: "component",
          render: (text, row, index) => {
            // Merging cells for rows with the same component
            const sameComponentRows = data.filter((item) => item.component === text).length;
            const firstRowIndex = data.findIndex((item) => item.component === text);
            return index === firstRowIndex ? { children: text?.split("-")[0], props: { rowSpan: sameComponentRows } } : { props: { rowSpan: 0 } };
          },
        },
        {
          title: "Language",
          dataIndex: "language",
          key: "language",
        },
        {
          title: "Translation",
          dataIndex: "translation",
          key: "translation",
        },
      ];

  return <Table
          className="detail-table"
          bordered
          dataSource={data}
          columns={columns}
          pagination={false}
        />;
};

export default DetailTable;
