import React, { Component } from 'react';
import LinkPM from './LinkPM';
import MessageLinkPM from './MessageLinkPM';
import ReactQuill from 'react-quill';
import { PrivacyManager } from '../../../../../../../../records/choice_config_records';

const myRef = React.createRef();
const CustomToolbar = (props) => (
  <div id="toolbar">
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-link" />
    <button className="ql-code-block" />
    { props.messageSubcategory !== 'us_pm' && (
      props.messageSubcategory === 'notice' ? (
        <LinkPM options={props.options} updateActions={props.updateActions} ref={myRef} />
      ) : (
        <MessageLinkPM 
          options={props.pmMessages} 
          messageSubcategory={props.messageSubcategory} 
          updateActions={props.updateActions} 
          ref={myRef} 
        />
      )
    )}
  </div>
);

function linkPrivacyManager() {
  const modal = myRef.current;
  setTimeout(async () => {
    try {
      const result = await modal.show();
      if (result) this.quill.format('link', '$$$$:'+result.button_text);
    } catch (err) {
      // Nothinig
    }
  }, 100);
}

class Text extends Component {
  state = { visible: false }
  modules = {
    toolbar: {
      container: "#toolbar",
      handlers: { linkPrivacyManager: linkPrivacyManager }
    }
  }
  formats = ['italic', 'underline', 'strike', 'list', 'bullet', 'link', 'code-block']

  updateText = (value) => {
    this.props.updateSetting(this.props.settingKey, value);
  }
  updateActions = (pm) => {
    const choiceOptions = [...this.props.allSettings.choice_options] || [];
    const exists = choiceOptions.find(o => o.data.button_text === pm.button_text);
    const data = new PrivacyManager(pm).toJS()
    if (data.hasOwnProperty('enabled')) delete data.enabled;
    if (!exists) choiceOptions.push({ type: 12, data });
    this.props.updateSetting('choice_options', choiceOptions);
  }
  render() {
    const { messageSubcategory, pmMessages, category } = this.props;
    return (
      <div className="text-editor">
        <CustomToolbar 
          options={this.props.pmList} 
          category={category}
          messageSubcategory={messageSubcategory}
          pmMessages={pmMessages}
          updateActions={this.updateActions} 
        />
        <ReactQuill 
          value={this.props.value}
          onChange={this.updateText}
          modules={this.modules}
          formats={this.formats}
        />
      </div>
    );
  }
}

export default Text;
