import React from 'react';
import { message, Modal } from '../../../../styleguide'
import { useSelector } from "react-redux";
import moment from 'moment';
import PartitionDetails from "./PartitionDetails";
import MessageDetails from "./MessageDetails";
import { campaignTypesCodes, redirectToVl,getCampaignNameLatestEnv } from '../helper';
import { Link } from 'react-router';

function CampaignDetails({ 
  campaign, 
  onCancel, 
  scenarios, 
  isMultiCampaignEnabled, 
  priority,
  currentUser,
  propertieIdsUnderPg,
}) {
  const statsData = useSelector(state => state.campaignState.get('statsData'));
 
  const getStatistics = (campaignId, statName) => {
    let value = 0;
    if(statsData && statsData[campaignId] && !statsData[campaignId].pending && statsData[campaignId]['value']) {
      value = statsData[campaignId]['value']['campaign_stats'][statName];
    }
    return value;
  }
  
  const { name, description } = campaign;
  const campaignName = getCampaignNameLatestEnv(name || description);
  const totalPv = getStatistics(campaign.id, 'total_pv');
  const messagedUsers = getStatistics(campaign.id, 'total_msg');
  const env = campaign.environment == 1 ? 'Public' : 'Stage';
  const campiagnMessages = campaign.messages
  const campaignVl = campaign.vl
  return <Modal className="campaign-details-modal" visible={!!campaign} title={campaignName} footer={null} onCancel={() => onCancel()}>
        <div className="campaign-details-header">
          <span>Campaign Summary</span>
          { isMultiCampaignEnabled && <span className="priority-label">Priority {priority}</span> }
        </div>
        <div className="campaign-stats">
          { isMultiCampaignEnabled && <div className="stats-wrapper">
            <span className="campaign-label">TYPE</span>
            <span>{campaignTypesCodes[campaign.campaign_type_id]}</span>
          </div> }
          <div className="stats-wrapper">
            <span className="campaign-label">ENVIRONMENT</span>
            <span>{env}</span>
          </div>
          <div className="stats-wrapper">
            <span className="campaign-label">START DATE & TIME</span>
            <span>{moment(campaign.created_at).format('YYYY-MM-DD HH:mm')}</span>
          </div>
          {/* <div className="stats-wrapper">
            <span className="campaign-label">TOTAL PV</span>
            <span>{totalPv}</span>
          </div>
           <div className="stats-wrapper">
            <span className="campaign-label">MESSAGED USERS</span>
            <span>{messagedUsers}</span>
          </div> */}
        </div>
        <hr/>
        {campaignVl ? (
        <div className="campaign-stats">
          <div className="stats-wrapper">
            <span className="campaign-label">VENDOR LIST</span>
            <span>
            <Link to={redirectToVl(campaignVl)}>{campaignVl.name}</Link>
            </span>
          </div>
        </div>
      ) : null}
        <MessageDetails currentUser={currentUser} messages={campiagnMessages} propertieIdsUnderPg={propertieIdsUnderPg} />
        <PartitionDetails currentUser={currentUser} scenarios={scenarios} campaign={campaign.toJS()}/>
      </Modal>
}

export default CampaignDetails;