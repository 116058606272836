import {
  Alert,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  theme,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { uploadBulkScanCsv } from "../../../api/diagnose_dashboard/scan_setup.js";
import {
  DownloadOutlined,
  FileTextOutlined,
  InboxOutlined,
} from "@ant-design/icons";

const { Dragger } = Upload;
const { Text } = Typography;
const { useToken } = theme;

const exampleData = [
  [
    "Property (Display Name)",
    "Sourcepoint account ID",
    "Scan URL",
    "Region",
    "Consent Types",
    "Frequency(weekly/monthly)",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
];

const requiredFields = [
  "Property (Display Name)",
  "Sourcepoint account ID",
  "Scan URL",
  "Region",
  "Consent Types",
  "Frequency(weekly/monthly)",
];

function UploadCsvFile({
  accountId,
  uploadCsvModalType,
  onClose,
  refreshBulkAccountScans,
}) {
  const [errors, setErrors] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useToken();

  const handleDownloadCSV = () => {
    try {
      const csvContent = exampleData
        .map((row) => row.map((item) => `"${item}"`).join(","))
        .join("\n");
  
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${new Date().toISOString()}_data.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSuccessMessage("sample.csv file has been successfully downloaded.");
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error while generating or downloading the CSV file:", error);
      setErrorMessage("Failed to download the CSV file. Please try again.");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };
  

  const validateCsvContent = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv = e.target.result;
        const rows = csv.split(/\r\n|\n/);
        const header = rows[0].split(",");

        const errors = {
          "Property (Display Name)": 0,
          "Sourcepoint Account ID": 0,
          "Scan URL": 0,
          Region: 0,
          "Consent Types": 0,
          "Frequency(weekly/monthly)": 0,
        };

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(",");

          requiredFields.forEach((field, index) => {
            if (!row[index]) {
              errors[field] += 1;
            }
          });
        }

        const errorMessage = [];
        if (errors["Property/Website Name"] > 0) {
          errorMessage.push(
            `${errors["Property/Website Name"]} Property/Website Name is missing.`
          );
        }
        if (errors["Sourcepoint Account ID"] > 0) {
          errorMessage.push(
            `${errors["Sourcepoint Account ID"]} Sourcepoint Account ID is missing.`
          );
        }
        if (errors["Scan URL"] > 0) {
          errorMessage.push(
            `${errors["Scan URL"]} Scan URLs are missing, or please verify that the URLs are in the correct format.`
          );
        }
        resolve(errorMessage);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUploadCsv = async (file) => {
    const validationErrors = await validateCsvContent(file);
    setErrors(validationErrors);
    setUploadedFile(file);
    return false;
  };

  const handleSave = async () => {
    if (!uploadedFile) {
      message.error("Please upload a CSV file before saving.");
      return;
    }

    if (errors.length > 0) {
      message.error("Please fix the errors in your file and re-upload.");
      return;
    }

    try {
      setIsUploading(true);
      const response = await uploadBulkScanCsv(uploadedFile);
      message.success("File uploaded successfully!");
      refreshBulkAccountScans();
      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Failed to upload the file.");
    } finally {
      setIsUploading(false);
    }
  };

  const uploadProps = {
    name: "file",
    accept: ".csv",
    maxCount: 1,
    beforeUpload: beforeUploadCsv,
  };

  return (
    <Modal
      title="Upload CSV File"
      open={!!uploadCsvModalType}
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={isUploading}
      okButtonProps={{
        disabled: !uploadedFile || errors.length > 0,
      }}
      cancelButtonProps={{
        type: "link",
      }}
      width={720}
    >
      <div className="ant-modal-uploadcsv">
        <Row gutter={32}>
          <Col span={16}>
            <span>
              <FileTextOutlined style={{ marginRight: "8px" }} />
              Download the Sample CSV Template for a format example.
            </span>
          </Col>
          <Col>
            <Button type="default" onClick={handleDownloadCSV}>
              <DownloadOutlined />
              Download CSV File
            </Button>
          </Col>
        </Row>
        {successMessage && (
          <Row>
            <Col>
              <span style={{ color: token.colorSuccess}}>{successMessage}</span>
            </Col>
          </Row>
        )}
        {errorMessage && (
          <Row>
            <Col>
              <span style={{ color: token.colorError}}>{errorMessage}</span>
            </Col>
          </Row>
        )}
        <Divider />
        <Row>
          <Col>
            <span>Import File</span>
          </Col>
        </Row>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file(s) to this area to upload
          </p>
        </Dragger>
        {errors.length > 0 && (
          <div>
            <Alert
              message={
                <Row>
                  <Col span={24}>
                    <strong>{uploadedFile?.name}: Missing Data</strong>
                  </Col>
                  {/* <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul> */}
                  <Col span={24}>
                    <Text>
                      <strong>Note:</strong> Please fill out all missing data
                      and re-upload the file.
                    </Text>
                  </Col>
                </Row>
              }
              type="error"
              showIcon
              closable
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UploadCsvFile;
