import { CopyOutlined } from "@ant-design/icons";
import { Badge, Button, message, Modal, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderBadge } from "./helper";

function ScanUrlModal({ scanUrlData, scanUrlModalOpen, onClose }) {
  const tableData = scanUrlData.scan_urls.map((scanData, index) => ({
    url: scanData.url,
    scan_ids: scanData.id,
    status: scanData.status,
  }));
  const scanTable = [
    {
      title: "Scan ID",
      dataIndex: "scan_ids",
      key: "scan_ids",
      ellipsis: true,
    },
    {
      title: "Scan URL",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
      width: 220,
    },
    {
      title: "Scan Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return renderBadge(status)
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const handleCopy = () => {
          const textToCopy = `URL: ${record.url}\nScan ID: ${record.scan_ids}`;
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              message.success("Copied to clipboard successfully!");
            })
            .catch((err) => {
              message.error("Failed to copy to clipboard: ", err);
            });
        };
        return (
          <Tooltip
            onClick={(evt) => evt.stopPropagation()}
            overlayClassName="diagnose--tooltip"
            title={
              <div onClick={(evt) => evt.stopPropagation()}>
                Copy Scan ID and URL
              </div>
            }
          >
            <Button
              size="small"
              type="link"
              icon={<CopyOutlined />}
              onClick={handleCopy}
            />
          </Tooltip>
        );
      },
      width: 140,
    },
  ];
  return (
    <Modal
      title={`Scan IDs & URLs (${scanUrlData.zone || "Unknown Zone"} - ${
        scanUrlData.region || "Unknown Region"
      })`}
      width={720}
      open={scanUrlModalOpen}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
      closable={true}
      className="scan-url-modal"
      cancelButtonProps={{
        type:'link'
      }}
    >
      <Table
        columns={scanTable}
        dataSource={tableData}
        rowKey={(record, index) => index}
        pagination={false}
        scroll={{ y: 400 }}
      />
    </Modal>
  );
}

export default ScanUrlModal;
