import React from 'react';
import { Table, Button, Typography, Space, Tooltip, Tag, Avatar } from 'antd';
import { ConfirmationWrapper} from "../../../helper";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { categorySubTypesOptions, defaultUserStateOptions, channelOptions } from '../../helper';
import { renderInfoTip } from '../../../us_privacy_regulation/helper';

const CategoriesTable = (props) => {
  const { checkStrictly, selectedRowKeys, setSelectedRowKeys, readOnly } = props;

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  };

  const channelOptionsMap = channelOptions.reduce((map, item) => {
    map[item.value] = item.label;
    return map;
  }, {});

  const defaultStateNameMap = defaultUserStateOptions.reduce((map, obj) => (map[obj.value] = obj.label, map), {})

  const columns = [
    {
      title: 'ID',
      columnKey: 'ID',
      dataIndex: 'categoryId',
      // width: 20,
      sorter: (a, b) =>  Number(a.categoryId) - Number(b.categoryId),
    },
    {
      title: 'Name',
      columnKey: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => {
        return (<Typography.Paragraph ellipsis={{rows: 2, tooltip: {title: name}}}>{name}</Typography.Paragraph>)
      }
    },
    {
      title: <>Scenario Gate Mapping {renderInfoTip("Scenario Gate Mapping connects the selected gates to the scenario builder for targeting. Choose 'None' if targeting is not needed")}</>,
      columnKey: 'Type',
      dataIndex: 'subType',
      key: 'subType',
      filters: props.categorySubTypes?.map((subType) => ({value: subType.type, text: subType.displayName})),
      onFilter: (value, record) => {
        if(value === "none") {
          return  value === record.subType || undefined === record.subType;
        }
        return value === record.subType
      },
      width: 200,
      render: (subType, record) => <Typography.Paragraph ellipsis={{rows: 2, tooltip: {title: props.subTypeNameMap[subType]}}}>{props.subTypeNameMap[subType]}</Typography.Paragraph>
    },
    {
      title: 'Channels',
      columnKey: 'Channels',
      dataIndex: 'channels',
      // width: '30%',
      key: 'channel',
      filters: channelOptions.map((opt) => ({...opt, text: opt.label})),
      onFilter: (value, record) => {
        return  record.channels.includes(value)
      },
      // width: 150,
      render: (channels) => {
        const channelLabels = channels.map(value => channelOptionsMap[value]);
        const first = <Tag className='tooltip-tag' title={channelLabels[0]}>{channelLabels[0]}</Tag>
        if(channelLabels.length > 1) {
          return (
            <Space className='tooltip-tag-container'>
              {first}
              <Tooltip
                title={
                  <div>
                    {channelLabels.filter((obj,i) => i !== 0).map((label) => (<><span>{label}</span><br/></>))}
                  </div>
                }
              >
                <Tag color='processing'>+ {channelLabels.length - 1}</Tag>
              </Tooltip>
            </Space>
          )
        } else {
          return (
            <Space className='tooltip-tag-container just-one'>
              {first}
            </Space>
          )
        }
      }
    },
    {
      title: 'Legal Document',
      columnKey: 'Legal Document',
      dataIndex: 'legalDocRef',
      key: 'legalDocRef',
      render: (legalDocRef, record) => <Typography.Paragraph ellipsis={{rows: 2, tooltip: {title: props.docNameIndexMap[legalDocRef]}}}>{props.docNameIndexMap[legalDocRef]}</Typography.Paragraph>
    },
    {
      title: 'Default User State',
      columnKey: 'Default User State',
      dataIndex: 'defaultState',
      key: 'defaultState',
      // width: 100,
      filters: defaultUserStateOptions.map((opt) => ({...opt, text: opt.label})),
      onFilter: (value, record) => {
        return value === record.defaultState
      },
      ellipsis: true,
      render: (defaultState, record) => <Typography.Paragraph ellipsis={{rows: 2, tooltip: {title: defaultStateNameMap[defaultState]}}}>{defaultStateNameMap[defaultState]}</Typography.Paragraph>
    },
    {
      title: 'Connections',
      columnKey: 'Connections',
      dataIndex: ['vendorConnection', 'id'],
      // width: '30%',
      render: (_, record) => {
        const venConnObjs =  record.vendorConnection.map((venConn, index) => props.vendorConnectionsIndexMap.get(venConn.id)).filter((obj) => Boolean(obj));
        const firstTag = venConnObjs[0] ? <Tag className='tooltip-tag' title={venConnObjs[0]?.name} icon={<Avatar shape="square" style={{verticalAlign: 'sub', marginRight:'4px'}} size={18} src={<img src={props.vendorsHash?.get(venConnObjs[0]?.vendorId)?.iconUrl} alt="vendor icon" />}/>}>{venConnObjs[0]?.name}</Tag> : null
        if(venConnObjs.length > 1) {
          return (
            <Space className='tooltip-tag-container'>
              {firstTag}
              <Tooltip
                title={
                  <div>
                    {venConnObjs.filter((obj,i) => i !== 0).map((obj) => (<><span><Avatar shape="square" style={{verticalAlign: 'sub', marginRight:'4px'}} size={18} src={<img src={props.vendorsHash?.get(obj.vendorId)?.iconUrl} alt="vendor icon" />}/> {obj?.name}</span><br/></>))}
                  </div>
                }
              >
                <Tag color='processing'>+ {venConnObjs.length - 1}</Tag>
              </Tooltip>
            </Space>
          )
        } else {
          return (
            <Space className='tooltip-tag-container just-one'>
              {firstTag}
            </Space>
          )
        }
      }
    },
    {
      title: 'Description',
      columnKey: 'Description',
      dataIndex: 'description',
      // width: '30%',
      key: 'description',
      render: (name, record) => <Typography.Paragraph ellipsis={{rows: 2, tooltip: {title: name}}}>{name}</Typography.Paragraph>
    },
    {
      title: 'Actions',
      columnKey: 'Action',
      width: 150,
      fixed: 'right',
      render: (record) => (
        <>
          <Tooltip title="Edit"><Button icon={<EditOutlined/>} type="link" onClick={() => props.handleShowAddcategory("Edit", record)}/></Tooltip>
          <ConfirmationWrapper
            actionDescription="delete this Category"
            deleteHandler={() => props.deleteCategory(record)}
            deleteBtn={<Button type="link" icon={<Tooltip title="Delete"><DeleteOutlined/></Tooltip>}/>}
          />
        </>
      ),
    }
  ].filter((col) => props.columnsToDisplay.includes(col.columnKey));

  return (
    <>
      <Table
        dataSource={props.dataSource}
        columns={columns}
        className="preferences-category-table"
        rowSelection={!readOnly ?{
          ...rowSelection,
          checkStrictly,
        }: false}
        pagination={{
          position: ['bottomCenter'],
          showTotal: (total) => <div>Total Categories :{' '}{total}</div>,
          size: 'small',
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['7', '10', '15', '20'],
        }}
      />
    </>
  )
}

const mapStateToProps = function (store) {
  const latestDocs = store.preferencesState.getIn(["latestLegalDocs", "value"]) ?? [];
  const docNameIndexMap = latestDocs.reduce((map, obj) => (map[obj.containerId] = obj.documentName, map), {})
  docNameIndexMap[undefined] = "None";
  const categorySubTypes = store.preferencesState.getIn(["campaignTypeList", "value"]) ?? [];
  const subTypeNameMap = categorySubTypes.reduce((map, obj) => (map[obj.type] = obj.displayName, map), {});
  subTypeNameMap[undefined] = "None";

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    vendorConnectionsIndexMap: store.preferencesState.getIn(["vendorConnectionsIndexMap", "value"]),
    vendorsHash: store.preferencesState.getIn(["vendorsHash", "value"]),
    categorySubTypes,
    subTypeNameMap,
    docNameIndexMap,
    pendingRequestsMap: Map({
      latestDocs : store.preferencesState.getIn(["latestLegalDocs", "pending"]),
    }),
  };
};
export default connect(mapStateToProps)(CategoriesTable);
